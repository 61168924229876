import styled from 'styled-components/macro';

export const SettingWrapper = styled.div.attrs(props => ({
  className: `advanced-setting-wrapper ${props.className || ''}`,
}))`
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 50px;

  @media (max-width: 575px) {
    flex-direction: column;
    margin-top: 0;

    .react-calendar {
      border-top: 1px solid #DCE7EF;
      border-radius: 0;
      box-shadow: none;
    }
  }
`;

export const WorkingHoursWrapper = styled.div.attrs(props => ({
  className: `working-hours-wrapper ${props.className || ''}`,
}))`
  margin-left: 50px;

  @media (max-width: 575px) {
    padding: 24px 25px;
    margin-left: 0;
  }

  .working-hours {
    &__title {
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
      color: #1D1D1D;
      padding: 8px 0;
      border-bottom: 1px dashed #CCD6DD;

      @media (max-width: 575px) {
        font-size: 20px;
        line-height: 23px;
        padding: 0 0 15px 0;
      }
    }
  }
`;

export const CalendarDayPeriodWrapper = styled.div.attrs({
  className: 'calendar__period',
})`
  padding: 17px 0 18px 0;
  border-bottom: 1px dashed #CCD4D5;

  .calendar__period {
    &-date {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: #1D1D1D;
      margin-bottom: 10px;

      @media (max-width: 575px) {
        font-size: 18px;
        line-height: 21px;
      }
    }
    &-content {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;

      &-period {
        display: inline-flex;
        align-items: center;
        background-color: #FFFFFF;
        border-radius: 20px;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #04B3C0;
        padding: 3px 8px 3px 5px;
        margin-right: 6px;

        @media (max-width: 575px) {
          font-size: 17px;
          line-height: 20px;
          padding: 5px 11px 4px 7px;
        }

        > i {
          margin-right: 5px;

          @media (max-width: 575px) {
            margin-right: 7px;
          }
        }

        &-disabled {
          background-color: #E7ECF0;
          color: #8A9DA4;
        }
      }
    }
  }
`;

export const CalendarDayTimeWrapper = styled.div.attrs({
  className: 'calendar__day',
})`
  padding: 20px 0 10px 0;

  .calendar__time {
    &-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      @media (max-width: 575px) {
        flex-wrap: wrap;
      }

      &-period {
        font-weight: 600;
        font-size: 13px;
        line-height: 15px;
        min-width: 87px;
        color: #1D1D1D;
        padding: 0 25px 0 0;

        @media (max-width: 575px) {
          flex-basis: 100%;
          font-size: 18px;
          line-height: 21px;
          margin-bottom: 4px;
          padding: 0;
        }
      }

      &-to {
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        color: #1D1D1D;
        padding: 0 12px;
      }
    }
    &-select {
      padding: 8px 10px;
      font-weight: 400;
      font-size: 13px;
      line-height: 15px;
      color: #000000;
      min-width: 104px;
      border-radius: 5px;

      @media (max-width: 575px) {
        width: calc(50% - 20px);
        padding: 12px 17px 10px 17px;
        font-size: 18px;
        line-height: 21px;
        color: #667778;
        border-radius: 12px;
      }

      &-disabled {
        background-color: #E1EBF0;
        border-color: #E1EBF0;
        color: #A1B6C0;
        pointer-events: none;
      }
    }
  }
`;
