import React from 'react';
import { PeriodWrapper } from './style';
import { Checkbox } from 'components';
import MorningIcon from 'assets/image/pages/app/morning.svg';
import AfternoonIcon from 'assets/image/pages/app/afternoon.svg';
import EveningIcon from 'assets/image/pages/app/evening.svg';
import AllDayIcon from 'assets/image/pages/app/all-day-grey.svg';
import NotAvailableIcon from 'assets/image/pages/app/not-available.svg';

const Period = ({ title, ...props }) => {
  const icon = (title) => {
    switch (title) {
      case 'Morning':
        return <MorningIcon />;
      case 'Afternoon':
        return <AfternoonIcon />;
      case 'Evening':
        return <EveningIcon />;
      case 'All Day':
        return <AllDayIcon />;
      case 'Not Available':
        return <NotAvailableIcon />;
      default:
        return null;
    }
  };

  return (
    <PeriodWrapper {...props}>
      <div className="day-period-icon">{icon(title)}</div>
      <div className="day-period-description"><Checkbox /> {title}</div>
    </PeriodWrapper>
  );
};

export default Period;
