import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Form } from 'components';
import { useIsMobile } from 'components/hooks';
import { BillingInfo, PaymentMethod, BillingHistory, Plan, Card } from './components';
import Tabs from './tabs';
import TabContent from './tabContent';
import { BillingWrapper } from './style';

const Billing = ({ tab, setTab }) => {
  const isMobile = useIsMobile();

  return (
    <BillingWrapper className={`billing-${tab}`}>
      {isMobile ? (
        <>
          {tab ? (
            <TabContent tab={tab} />
          ) : (
            <>
              <Plan />
              <Card />
              <Tabs setTab={setTab} />
            </>
          )}
        </>
      ) : (
        <Form>
          <Form.Title>Billing Info</Form.Title>
          <Row>
            <Col xl={8} className="order-sm-2 order-xl-1">
              <BillingInfo />
            </Col>
            <Col xl={4} className="order-sm-1 order-xl-2 mb-sm-5 mb-xl-0">
              <Plan />
            </Col>
          </Row>
          <Form.Title className="mt-5">Payment Method</Form.Title>
          <Row>
            <Col xl={8}>
              <PaymentMethod />
            </Col>
            <Col xl={4}>
              <Card />
            </Col>
          </Row>
          <hr className="mt-6" />
          <Form.Title>Billing History</Form.Title>
          <BillingHistory />
          <hr />
        </Form>
      )}
    </BillingWrapper>
  );
};

export default Billing;
