import React, { useMemo } from 'react';
import moment from 'moment';
import { Interactions } from 'interfaces';
import { getAvatarUrl } from 'utils/helper';
import TranscriptIcon from 'assets/image/icons/app/transcript.svg';
import MessageIcon from 'assets/image/icons/app/chat.svg';
import UserIcon from 'assets/image/icons/app/user.svg';
import VoicioIcon from 'assets/image/icons/app/voicio.svg';

export const useTableColumns = ({ getCall }) => {
  return useMemo(() => {
    const columns = [
      {
        Header: 'DATE',
        accessor: 'date',
        Cell: ({ row }) => {
          const { original } = row;
          const parsedDate = moment(original.date)
            .format('DD/MM/YYYY [at] hh:mm A');
          return original.archived ? parsedDate : (
            <strong>{parsedDate}</strong>
          );
        },
      },
      {
        Header: 'TYPE',
        Cell: ({ row }) => {
          const { original } = row;
          switch (original.type?.id) {
            case Interactions.ETypes.User:
              return <UserIcon />;
            case Interactions.ETypes.Voicio:
              return <VoicioIcon />;
            default:
              return <VoicioIcon />;
          }
        },
        accessor: 'type',
      },
      {
        Header: 'NAME',
        accessor: 'name',
        Cell: ({ row }) => {
          const { original: { client } } = row;
          if (client) {
            const name = `${client.firstName} ${client.lastName}`;
            return (
              <div className="d-flex align-items-center">
                <img src={getAvatarUrl(client.email)} alt="Avatar" className="me-2" />
                {client.archived ? name : (
                  <strong>{name}</strong>
                )}
              </div>
            );
          } else { return null; }
        },
      },
      {
        Header: 'OUTCOME',
        accessor: 'outcome',
        Cell: ({ row }) => {
          const { original } = row;
          const name = original.outcome?.name || '';
          return original.archived ? name : (
            <strong>{name}</strong>
          );
        },
      },
      {
        Header: 'ACTIVITY',
        accessor: 'activity',
        Cell: ({ row }) => {
          const { original } = row;
          return original.archived ? original.activity?.name : (
            <strong>{original.activity?.name}</strong>
          );
        },
      },
      {
        Header: '',
        Cell: ({ data, row }) => {
          const { original } = row;
          return (
            <div onClick={getCall(data[row.index])} style={{ cursor: 'pointer' }}>
              {original.activity?.id === Interactions.EActivities.Call ? (
                <TranscriptIcon />
              ) : (
                <MessageIcon />
              )}
            </div>
          );
        },
        accessor: 'action',
        disableSortBy: true,
      },
    ];
    return columns;
  }, [getCall]);
};
