import React, { useMemo } from 'react';
import classnames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment';
import { useIsMobile } from 'components/hooks';
import NotificationItem from './item';
import { ListWrapper } from 'templates/app/style';
import { DateLabel, SubListWrapper } from './style';

type Props = {
  data: any,
}
const Notifications: React.FC<Props> = ({ data, ...props }) => {
  const isMobile = useIsMobile();

  const result = useMemo(() => {
    return data.reduce((r, obj) => {
      const date = moment(obj.date, 'DD/MM/YYYY HH:mmA').format('LL');
      r[date] = r[date] || [];
      r[date].push(obj);
      return r;
    }, Object.create(null));
  }, [data]);

  return (
    <ListWrapper {...props}>
      <div className="list__content">
        <div className={classnames('list__content-body', { 'h-100': isMobile })}>
          {isMobile ? (
            <Scrollbars universal autoHide>
              {Object.entries(result).map(([key, value]) => (
                <SubListWrapper key={key}>
                  <DateLabel>{key}</DateLabel>
                  {value.map((item, index) => (
                    <NotificationItem item={item} key={index} />
                  ))}
                </SubListWrapper>
              ))}
            </Scrollbars>
          ) : (
            <>
              {Object.entries(result).map(([key, value]) => (
                <SubListWrapper key={key}>
                  <DateLabel>{key}</DateLabel>
                  {value.map((item, index) => (
                    <NotificationItem item={item} key={index} />
                  ))}
                </SubListWrapper>
              ))}
            </>
          )}
        </div>
      </div>
    </ListWrapper>
  );
};

export default Notifications;
