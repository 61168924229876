import React, { useState } from 'react';
import { Tab } from 'react-bootstrap';
import { AppPageWrapper, Title, Button, FloatingButton, Tabs } from 'components';
import { useIsMobile } from 'components/hooks';
import AIVoices from 'templates/app/virtualReceptionist/aiVoices';
import PersonalGreeting from 'templates/app/virtualReceptionist/personalGreeting';
import BusinessHours from 'templates/app/virtualReceptionist/businessHours';
import CallLogs from 'templates/app/virtualReceptionist/callLogs';
import PlusIcon from 'assets/image/icons/app/plus.svg';

const VirtualReceptionist: React.VFC = () => {
  const [tab, setTab] = useState<string>('ai-voices');
  const [shouldShow, setShouldShow] = useState<boolean>(false);
  const isMobile = useIsMobile();

  const handleAddNew = () => setShouldShow(true);

  const handleClose = () => setShouldShow(false);

  const handleTab = (t: string): void => {
    setShouldShow(false);
    setTab(t);
  };

  return (
    <AppPageWrapper title="Virtual Receptionist">
      <div className="main-content-container xs-p-0">
        {!isMobile ? (
          <Title
            title={'Virtual Receptionist'}
            description={'Lorem Ipsum has been the industry`s standard.'}
          >
            {(tab === 'ai-voices' || tab === 'personal-greeting') && (
              <Button round onClick={handleAddNew}>
                <i className="fas fa-plus"></i>&nbsp;&nbsp;Add New
              </Button>
            )}
          </Title>
        ) : (
          <>
            {(tab === 'ai-voices' || tab === 'personal-greeting') && (
              <FloatingButton onClick={handleAddNew}>
                <PlusIcon />
              </FloatingButton>
            )}
          </>
        )}
        <div className="main-content-body">
          <Tabs defaultActiveKey="ai-voices" onSelect={(k) => handleTab(k)}>
            <Tab eventKey="ai-voices" title="A.I. Voices">
              <AIVoices
                showNewModal={shouldShow && tab === 'ai-voices'}
                closeNewModal={handleClose}
                active={tab === 'ai-voices'}
              />
            </Tab>
            <Tab eventKey="personal-greeting" title="Personal Greeting">
              <PersonalGreeting
                showNewModal={shouldShow && tab === 'personal-greeting'}
                closeNewModal={handleClose}
                active={tab === 'personal-greeting'}
              />
            </Tab>
            <Tab eventKey="business-hours" title="Business Hours">
              <BusinessHours
                active={tab === 'business-hours'}
              />
            </Tab>
            <Tab eventKey="call-logs" title="Call Logs">
              <CallLogs
                active={tab === 'call-logs'}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
    </AppPageWrapper>
  );
};

export default VirtualReceptionist;
