import React from 'react';
import classnames from 'classnames';
import { HeaderWrapper } from './style';

type Props = {
  view: string,
  setView: (props: string) => void,
}
const Header: React.FC<Props> = ({ view, setView }) => {
  return (
    <HeaderWrapper>
      <h3 className='calendar-header__title'>Calendar</h3>
      <div
        className={classnames('calendar-header__btn', {
          'calendar-header__btn-active': view === 'timeGridDay',
        })}
        onClick={() => setView('timeGridDay')}
      >
        List
      </div>
      <div
        className={classnames('calendar-header__btn', {
          'calendar-header__btn-active': view === 'dayGridMonth',
        })}
        onClick={() => setView('dayGridMonth')}
      >
        Monthly
      </div>
      <div
        className={classnames('calendar-header__btn', {
          'calendar-header__btn-active': view === 'timeGridWeek',
        })}
        onClick={() => setView('timeGridWeek')}
      >
        Weekly
      </div>
    </HeaderWrapper>
  );
};

export default Header;
