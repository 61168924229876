import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import TimePicker from 'react-bootstrap-time-picker';
import AllDayIcon from 'assets/image/pages/app/all-day.svg';

const convertDataToTime = (hour, minute) => {
  return hour * 3600 + minute * 60;
};

const initialData = (dayKey) => ({
  openHour: 8,
  openMinute: 0,
  closeHour: 17,
  closeMinute: 0,
  day: dayKey,
  timezone: '',
  active: false
});

const DayForm: React.VFC<any> = ({
  dayKey,
  data = initialData(dayKey),
  handleChange
}) => {
  const handleSwitch = () => {
    handleChange({
      ...data,
      active: !data.active
    });
  };

  const handleTimeChange = (type) => (timeInt) => {
    const hour = Math.floor(timeInt / 3600.00);
    const minute = (timeInt % 3600) / 60;
    const newTime = type === 'open' ?
      {
        openHour: hour,
        openMinute: minute
      } : {
        closeHour: hour,
        closeMinute: minute
      };
    handleChange({
      ...data,
      ...newTime
    });
  };

  return (
    <Row>
      <Col sm="12" md="12" lg="7" xl="5">
        <Form.Group as={Row} className="mb-1">
          <Form.Label column xs="8" sm="5" className="business-hours__form-label">
            Set Time
          </Form.Label>
          <Col xs="4" sm="7" className="text-end">
            <Form.Switch
              id="business hours"
              className="business-hours__form-switch"
              checked={!!data.active}
              onChange={handleSwitch}
            />
          </Col>
        </Form.Group>
        <Row>
          <Col xs="6" sm="12">
            <Form.Group as={Row} className="mb-1">
              <Form.Label column sm="5" className="business-hours__form-label">
                Start Time
              </Form.Label>
              <Col sm="7">
                <TimePicker
                  value={convertDataToTime(data.openHour, data.openMinute)}
                  onChange={handleTimeChange('open')}
                  step={30}
                  className="business-hours__form-select"
                />
              </Col>
            </Form.Group>
          </Col>
          <Col xs="6" sm="12">
            <Form.Group as={Row} className="mb-1">
              <Form.Label column sm="5" className="business-hours__form-label">
                End Time
              </Form.Label>
              <Col sm="7">
                <TimePicker
                  value={convertDataToTime(data.closeHour, data.closeMinute)}
                  step={30}
                  className="business-hours__form-select"
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
      </Col>
      <Col lg={{ span: 3, offset: 1 }} className="d-none d-lg-block">
        <AllDayIcon />
      </Col>
    </Row>
  );
};

export default DayForm;
