import styled from 'styled-components/macro';

export const StateWrapper = styled.div.attrs({
  className: 'state__item',
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #FFFFFF;
  height: 100%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  padding: 20px 17px 13px 27px;

  @media (max-width: 575px) {
    min-width: 147px;
    height: 159px;
    background-color: ${props => props.color};
    border-radius: 35px;
    padding: 17px 16px 23px 16px;
    margin-right: 15px;
  }

  .state__item {
    &-content {
      &-title {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.7px;
        color: #04B3C0;
        text-transform: uppercase;
        margin-bottom: 6px;
      }
      &-icon {
        margin-bottom: 28px;
        width: 40px;
        height: 40px;

        rect {
          fill: #FFFFFF;
        }
      }
      &-value {
        display: flex;
        align-items: center;
        font-size: 30px;
        line-height: 35px;
        letter-spacing: 0.5px;
        font-weight: 700;
        color: #1F5673;
        margin: 0;

        @media (max-width: 575px) {
          font-weight: 400;
        }
      }
      &-count {
        background: #FFECEC;
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        color: #AC2625;
        border-radius: 16px;
        margin-left: 8px;
        padding: 2px 6px;

        @media (max-width: 575px) {
          margin-left: 6px;
          padding: 1px 2px;
        }

        &.active {
          background: #DFFAF1;
          color: #316752;
        }
      }
    }
  }
`;
