import React, { useState, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useIsDesktop, useIsMobile } from 'components/hooks';
import { renderDayHeaderContent } from 'templates/app/bookingCalendar/bookingCalendar';
import CalendarAddForm from 'templates/app/bookingCalendar/addForm';
import CalendarEditForm from 'templates/app/bookingCalendar/editForm';
import { emptyForm } from 'data/pages/app/bookingCalendar';
import useConnector from 'components/hooks/useConnector';
import { EQueryKeys } from 'interfaces';
import { CalendarWrapper } from './style';

type Props = {
  view?: string,
}
const Calendar: React.FC<Props> = ({
  view = 'timeGridWeek', ...props
}) => {
  const [isNew, setIsNew] = useState(false);
  const [editingEvent, updateEditingEvent] = useState(null);
  const calendarRef = useRef(null);
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const { data, mutationController } = useConnector(EQueryKeys.Calendar);
  const { data: customerList } = useConnector(EQueryKeys.CustomerClients);

  const handleAddNew = (): void => {
    setIsNew(true);
    updateEditingEvent(emptyForm);
  };

  const handleClose = () => {
    setIsNew(false);
    updateEditingEvent(null);
  };

  const handleSave = (eventDetail): void => {
    mutationController.onCreate(eventDetail);
    updateEditingEvent(null);
    setIsNew(false);
  };

  const handleDelete = (eventDetail): void => {
    mutationController.onDelete(eventDetail);
    updateEditingEvent(null);
    setIsNew(false);
  };

  const renderEventContent = (eventInfo) => (
    <div
      className="fc-event-detail"
      style={{
        border: `1px solid ${eventInfo.borderColor}`,
        backgroundColor: '#FFF'
      }}
    >
      <div className="fc-event-detail__info">
        <div className="fc-event-detail__title">
          {eventInfo.event.extendedProps?.summary}
        </div>
      </div>
    </div>
  );

  return (
    <CalendarWrapper className="mb-4" {...props}>
      <div className="calendar__title">Schedule</div>
      <FullCalendar
        ref={calendarRef}
        plugins={[
          timeGridPlugin,
          interactionPlugin
        ]}
        themeSystem={'bootstrap'}
        customButtons={{
          add: {
            icon: 'plus fc-icon-fontawesome',
            click: () => handleAddNew(),
          }
        }}
        headerToolbar={{
          left: '',
          center: '',
          right: 'prev title next add'
        }}
        dayHeaderFormat={{
          weekday: isDesktop ? (isMobile ? 'narrow' : 'short') : 'long'
        }}
        dayHeaderContent={renderDayHeaderContent}
        slotLabelFormat={{
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        }}
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        }}
        initialView={view}
        nowIndicator={true}
        events={data}
        eventContent={renderEventContent}
        allDaySlot={false}
        firstDay={1}
        height={isMobile ? 600 : 351}
      />

      {Boolean(editingEvent) && isNew && (
        <CalendarAddForm
          initialEvent={editingEvent}
          customerList={customerList}
          handleClose={handleClose}
          onSubmit={handleSave}
        />
      )}

      {Boolean(editingEvent) && !isNew && (
        <CalendarEditForm
          initialEvent={editingEvent}
          customerList={customerList}
          handleClose={handleClose}
          onSubmit={handleSave}
          onDelete={handleDelete}
        />
      )}
    </CalendarWrapper>
  );
};

export default Calendar;
