import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { numberWithCommas } from 'utils/helper';
import { InvoiceDetailWrapper } from '../style';
import InvoiceLogo from 'assets/image/pages/app/invoice-logo.png';

type Props = {
  job: any,
  customer: any,
}
const InvoiceDetail: React.FC<Props> = ({ job, customer }) => {
  return (
    <InvoiceDetailWrapper>
      <div className="customer-invoice-detail__status draft">
        <span>Draft</span>
      </div>
      <div className="customer-invoice-detail__info">
        <div className="customer-invoice-detail__info-company">
          <img src={InvoiceLogo} alt="Company" />
          <h4 className="customer-invoice-detail__info-company-name">
            Company Name
          </h4>
          <div className="customer-invoice-detail__info-company-address">
            100 W 14th Ave Pkwy,<br/>
            Denver,<br/>
            CO 80204, United States
          </div>
        </div>
        <div className="customer-invoice-detail__info-invoice">
          <h4 className="customer-invoice-detail__info-invoice-title">
            Invoice
          </h4>
          <div className="customer-invoice-detail__info-invoice-item">
            #INV02934845900
          </div>
          <div className="customer-invoice-detail__info-invoice-item">
            Date: June 25, 2021
          </div>
          <div className="customer-invoice-detail__info-invoice-item">
            Date: <strong>June 25, 2021</strong>
          </div>
        </div>
      </div>
      <div className="customer-invoice-detail__address">
        <div className="customer-invoice-detail__address-bill">
          <strong>Bill To:</strong>
          <p>{customer.name}<br/>{customer.phone}</p>
        </div>
        <div className="customer-invoice-detail__address-shipping">
          <strong>Shipping Address:</strong>
          <p>
            100 W 14th Ave Pkwy, Denver,<br/>
            CO 80204, United States
          </p>
        </div>
      </div>
      <div className="customer-invoice-detail__services">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Service Name</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td><strong>{job.title}</strong></td>
              <td>1</td>
              <td>${numberWithCommas(job.earning)}</td>
              <td>${numberWithCommas(job.earning)}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Row>
        <Col
          xxl={{ span: 5, offset: 7 }}
          xl={{ span: 6, offset: 6 }}
          lg={{ span: 7, offset: 5 }}
          md={{ span: 8, offset: 4 }}
        >
          <div className="customer-invoice-detail__summary">
            <div className="customer-invoice-detail__summary-subtotal">
              <span>Subtotal($)</span>
              <span>${numberWithCommas(job.earning)}</span>
            </div>
            <div className="customer-invoice-detail__summary-fee">
              <span>Fee($)</span>
              <span>${numberWithCommas(job.fee)}</span>
            </div>
            <div className="customer-invoice-detail__summary-total">
              <span>Total($)</span>
              <span>${numberWithCommas(job.earning - job.fee)}</span>
            </div>
          </div>
        </Col>
      </Row>
      <div className="customer-invoice-detail__message">
        <strong>Message:</strong>
        <p>Thanks for the business!</p>
      </div>
    </InvoiceDetailWrapper>
  );
};

export default InvoiceDetail;
