import React from 'react';
import { ItemWrapper } from 'templates/app/style';

const Tabs = ({ setTab }) => {
  return (
    <>
      <ItemWrapper onClick={() => setTab('billingInfo')}>
        <span>Billing Info</span>
        <i className="fa fa-chevron-right" />
      </ItemWrapper>
      <ItemWrapper onClick={() => setTab('paymentMethod')}>
        <span>Payment Method</span>
        <i className="fa fa-chevron-right" />
      </ItemWrapper>
      <ItemWrapper onClick={() => setTab('billingHistory')}>
        <span>Billing History</span>
        <i className="fa fa-chevron-right" />
      </ItemWrapper>
    </>
  );
};

export default Tabs;
