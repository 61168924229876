import React, { useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CustomToggle } from 'components';
import { DropdownWrapper } from 'templates/app/style';

export const useTableColumns = (): any => {
  return useMemo(() => {
    const columns = [
      {
        Header: 'NAME',
        accessor: 'name'
      },
      {
        Header: 'DATE',
        accessor: 'date',
      },
      {
        Header: '',
        Cell: () => {
          return (
            <DropdownWrapper>
              <Dropdown.Toggle as={CustomToggle}>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="#">
                  <i className="fa fa-play fa-fw" /> Play
                </Dropdown.Item>
                <Dropdown.Item href="#">
                  <i className="fa fa-microphone fa-fw" /> Record
                </Dropdown.Item>
                <Dropdown.Item href="#">
                  <i className="fa fa-trash fa-fw" /> Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </DropdownWrapper>
          );
        },
        accessor: 'action',
        disableSortBy: true,
      },
    ];
    return columns;
  }, []);
};
