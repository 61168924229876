import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import classnames from 'classnames';
import { useIsMobile } from 'components/hooks';
import { statsData } from 'data/pages/app/dashboard';
import { StateWrapper } from './style';

type Props = {
  title: string,
  value: string,
  count?: number,
  color: string,
  Icon: any,
}
const StateItem: React.FC<Props> = ({
  title, value, count, Icon, ...props
}) => {
  const isMobile = useIsMobile();

  return (
    <StateWrapper {...props}>
      <div className="state__item-content">
        {!isMobile && <h5 className="state__item-content-title">{title}</h5>}
        {Icon && isMobile && <Icon className="state__item-content-icon" />}
        <h3 className="state__item-content-value">
          {value}
          {count && (
            <span
              className={classnames('state__item-content-count', {
                active: count > 0,
              })}
            >
              {count} <i className={classnames('fa', {
                'fa-arrow-up': count > 0,
                'fa-arrow-down': count <= 0,
              })} />
            </span>
          )}
        </h3>
      </div>
      {Icon && !isMobile && <Icon className="state__item-icon" />}
    </StateWrapper>
  );
};

const State: React.VFC = () => {
  const isMobile = useIsMobile();

  return (
    <>
      {isMobile ? (
        <ScrollMenu wrapperClassName="mb-3">
          {statsData.map((state, index) => (
            <StateItem {...state} key={index} />
          ))}
        </ScrollMenu>
      ) : (
        <Row className="mb-2 mx-n2">
          {statsData.map((state, index) => (
            <Col className="mb-2 px-2" md={6} lg={3} key={index}>
              <StateItem {...state} />
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};

export default State;
