import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Form } from 'components';

const Company: React.VFC = () => {
  return (
    <Row className="g-2">
      <Col lg={4} md={6}>
        <Form.Label>Company Name</Form.Label>
        <Form.Input
          type="text"
          placeholder=""
          className="account-setting__input"
        />
      </Col>
      <Col lg={4} md={6}>
        <Form.Label>Company Size</Form.Label>
        <Form.Select className="account-setting__select">
          <option>1 - 10 employees</option>
          <option>10 - 25 employees</option>
          <option>25 - 50 employees</option>
          <option>50 - 100 employees</option>
          <option>100 more employees</option>
        </Form.Select>
      </Col>
      <Col lg={4} md={6}>
        <Form.Label>Company Website</Form.Label>
        <Form.Input
          type="url"
          placeholder=""
          className="account-setting__input"
        />
      </Col>
    </Row>
  );
};

export default Company;
