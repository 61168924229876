import React from 'react';
import { Tab } from 'react-bootstrap';
import { AppPageWrapper, Title, Tabs } from 'components';
import { useIsMobile, useConnector } from 'components/hooks';
import InteractionsList from 'templates/app/interactions';
import { EQueryKeys } from 'interfaces';

const Calls: React.VFC = () => {
  const isMobile = useIsMobile();
  const { data: interactionsData = [] } = useConnector(EQueryKeys.Interactions);
  const archivedCallsTableData = interactionsData.filter(call => call.archived);

  return (
    <AppPageWrapper title="Interactions">
      <div className="main-content-container xs-p-0">
        {!isMobile && (
          <Title
            title={'Interactions'}
            description={'Lorem Ipsum has been the industry`s standard.'}
          />
        )}
        <div className="main-content-body">
          <Tabs defaultActiveKey="all">
            <Tab eventKey="all" title="All">
              <InteractionsList data={interactionsData} />
            </Tab>
            <Tab eventKey="active" title="Active">
              <InteractionsList data={archivedCallsTableData} />
            </Tab>
          </Tabs>
        </div>
      </div>
    </AppPageWrapper>
  );
};

export default Calls;
