import React from 'react';
import classnames from 'classnames';
import { useStep } from 'react-hooks-helper';

import { Modal } from 'components';
import { useIsMobile } from 'components/hooks';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import Step5 from './step5';
import Step6 from './step6';
import { AdvancedQuestionsWrapper } from './style';

const steps = [
  { id: 'step1' },
  { id: 'step2' },
  { id: 'step3' },
  { id: 'step4' },
  { id: 'step5' },
  { id: 'step6' },
];

type Props = {
  show: boolean,
  closeModal: () => void,
}
const AdvancedQuestionsModal: React.FC<Props> = ({
  show, closeModal,
}) => {
  const { step, navigation } = useStep({ initialStep: 0, steps });
  const isMobile = useIsMobile();
  const { id } = step;
  const props = { navigation, closeModal };

  const renderStep = (id, props) => {
    switch (id) {
      case 'step1':
        return <Step1 {...props} />;
      case 'step2':
        return <Step2 {...props} />;
      case 'step3':
        return <Step3 {...props} />;
      case 'step4':
        return <Step4 {...props} />;
      case 'step5':
        return <Step5 {...props} />;
      case 'step6':
        return <Step6 {...props} />;
      default:
        return null;
    }
  };

  return (
    <Modal
      className={classnames({
        'modal-xs': ['step1', 'step4', 'step5'].includes(id),
        'modal-md': ['step2', 'step3', 'step6'].includes(id),
        'center': ['step1'].includes(id),
        'top': !['step1'].includes(id),
      })}
      show={show}
      onHide={closeModal}
      backdrop="static"
    >
      <AdvancedQuestionsWrapper
        className={classnames({
          'paddable': ['step1'].includes(id)
        })}
      >
        {!['step1'].includes(id) && !isMobile && (
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={closeModal}
          >
          </button>
        )}
        {renderStep(id, props)}
      </AdvancedQuestionsWrapper>
    </Modal>
  );
};

export default AdvancedQuestionsModal;
