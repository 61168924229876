import React from 'react';
import { Button, Modal } from 'components';
import { useIsMobile } from 'components/hooks';
import DayTimeSelect from './components/dayTimeSelect';
import { Weekdays, IBusinessHours } from 'interfaces';
import { BusinessHoursWrapper } from './style';

type Props = {
  show: boolean,
  closeModal: () => void,
  onSubmit: (hours: Array<IBusinessHours>) => void
}
const defaultState: Array<IBusinessHours> = Weekdays.map((day, idx) => ({
  openHour: 9,
  openMinute: 0,
  closeHour: 17,
  closeMinute: 0,
  timezone: '',
  day: idx,
  active: idx < 5
}));

const getTimeFromSeconds = (seconds) => {
  const hour = Math.floor(seconds / 3600);
  const minute = Math.floor((seconds - hour * 3600) / 60);
  return { hour, minute };
};

const BusinessHoursModal: React.FC<Props> =
  ({ show, onSubmit, closeModal }) => {
    const [businessHours, updateHours] = React.useState(defaultState);
    const isMobile = useIsMobile();

    const updateBusinessHours = (dayIdx, updateObj) => {
      const newBusinessHours = businessHours.map((day, idx) => (
        idx === dayIdx ? {
          ...day,
          ...updateObj
        } : { ...day }
      ));
      updateHours(newBusinessHours);
    };

    const handleUpdate = (dayIdx, updateKey) => (updateValue) => {
      if (updateKey === 'open') {
        const {
          hour: openHour,
          minute: openMinute
        } = getTimeFromSeconds(updateValue);
        updateBusinessHours(dayIdx, { openHour, openMinute });
      } else if (updateKey === 'close') {
        const {
          hour: closeHour,
          minute: closeMinute
        } = getTimeFromSeconds(updateValue);
        updateBusinessHours(dayIdx, { closeHour, closeMinute });
      } else {
        updateBusinessHours(dayIdx, { [updateKey]: updateValue });
      }
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      onSubmit(businessHours);
    };

    return (
      <Modal
        className="modal-md"
        show={show}
        onHide={closeModal}
        backdrop="static"
      >
        <BusinessHoursWrapper>
          {isMobile && (
            <div className="business-hours__header">
              <h2 className="business-hours__header-title">Business hours</h2>
            </div>
          )}
          <div className="business-hours__form">
            <h4 className="business-hours__title">
              What are your business hours?
            </h4>
            <h5 className="business-hours__subtitle">Business Days & Hours</h5>
            {businessHours.map((dayObj, index) =>
              <DayTimeSelect
                key={index}
                hoursIdx={index}
                dayObj={dayObj}
                isShowingLabel={index === 0}
                onUpdate={handleUpdate}
              />
            )}
          </div>
          <div className="business-hours__footer">
            <Button
              className="business-hours__action"
              variant="primary"
              onClick={handleSubmit}
            >
              Get Started
            </Button>
          </div>
        </BusinessHoursWrapper>
      </Modal>
    );
  };

export default BusinessHoursModal;
