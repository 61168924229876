import React from 'react';
import classnames from 'classnames';
import { ListItemWrapper } from 'templates/app/style';

type Props = {
  item: any,
  getCall: React.MouseEventHandler<HTMLElement>
}
const Item: React.FC<Props> = ({ item, getCall, ...props }) => {
  const { img, name, phone, type: Icon, archived } = item;

  return (
    <ListItemWrapper
      className={classnames('d-flex align-items-center', {
        archived,
        'justify-content-between': true
      })}
      {...props}
    >
      <div className="d-flex align-items-center">
        <div className="me-2 avatar-wrapper online">
          <img src={img} alt="A" />
        </div>
        <div>
          <h3>{name}</h3>
          <span>{phone}</span>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className="me-3 icon-wrapper blue">
          <Icon />
        </div>
        <i
          className="fa fa-chevron-right"
          onClick={() => getCall(item)}
        />
      </div>
    </ListItemWrapper>
  );
};

export default Item;
