import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import debounce from 'lodash.debounce';
import { usaStates } from 'typed-usa-states';

import { Button, Form } from 'components';
import { useForm, useGoogleMaps } from 'components/hooks';
import { CustomerDetailWrapper } from './style';
import { getAvatarUrl } from 'utils/helper';
import { EGoogleComponentTypes } from 'interfaces';

export function validate(values) {
  const errors: any = {};
  if (!values.firstName) {
    errors.firstName = 'First Name is required';
  }
  if (!values.lastName) {
    errors.lastName = 'Last Name is required';
  }
  if (!values.phone) {
    errors.phone = 'Phone is required';
  }
  if (!values.email) {
    errors.email = 'Email is required';
  }
  if (!values.addressLine1) {
    errors.addressLine1 = 'Address is required';
  }
  if (!values.city) {
    errors.city = 'City is required';
  }
  if (!values.state) {
    errors.state = 'State is required';
  }
  if (!values.zipCode) {
    errors.zipCode = 'Zip Code is required';
  }
  if (!values.country) {
    errors.country = 'Country is required';
  }

  return errors;
};

const addressMap = {
  [EGoogleComponentTypes.StreetNumber]: 'addressLine1',
  [EGoogleComponentTypes.Route]: 'addressLine1',
  [EGoogleComponentTypes.City]: 'city',
  [EGoogleComponentTypes.State]: 'state',
  [EGoogleComponentTypes.Country]: 'country',
  [EGoogleComponentTypes.ZipCode]: 'zipCode'
};

const CustomerDetail = ({
  cancel,
  onSubmit,
  customer,
}) => {
  const [avatarValue, updateAvatarValue] = useState(getAvatarUrl('0'));
  const {
    values,
    errors,
    isValidated,
    handleChange,
    handleSelectChange,
    updateValues,
    handleSubmit,
  } = useForm(onSubmit, validate, customer);

  const { autoCompleteRef } = useGoogleMaps(updateValues, addressMap);

  useEffect(() => {
    return debounce(() =>
      updateAvatarValue(getAvatarUrl(values.phone)), 400);
  }, [avatarValue, values.phone]);

  return (
    <CustomerDetailWrapper>
      <div className="customer-detail__avatar">
        {avatarValue && (
          <img
            src={avatarValue}
            className="customer-box-item__avatar"
            alt="A"
          />
        )}
        {/*
        <div className="customer-detail__avatar-action">
          Upload Picture
        </div>
        */}
      </div>
      <Form
        className="customer-detail__form"
        noValidate
        onSubmit={handleSubmit}
      >
        <Form.Label className="mb-3">Profile</Form.Label>
        <Row>
          <Col xl={4} md={6}>
            <Form.Group className="mb-3">
              <Form.Label target="firstName" className="customer-detail__form-label">
                First Name <span className="color-danger">*</span>
              </Form.Label>
              <Form.Input
                id="firstName"
                name="firstName"
                className="customer-detail__form-input"
                value={values.firstName || ''}
                isValid={isValidated && Boolean(!errors.firstName)}
                isInValid={isValidated && Boolean(errors.firstName)}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col xl={4} md={6}>
            <Form.Group className="mb-3">
              <Form.Label target="lastName" className="customer-detail__form-label">
                Last Name <span className="color-danger">*</span>
              </Form.Label>
              <Form.Input
                id="lastName"
                name="lastName"
                className="customer-detail__form-input"
                value={values.lastName || ''}
                isValid={isValidated && Boolean(!errors.lastName)}
                isInValid={isValidated && Boolean(errors.lastName)}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col xl={4} md={6}>
            <Form.Group className="mb-3">
              <Form.Label target="phone" className="customer-detail__form-label">
                Phone NO. <span className="color-danger">*</span>
              </Form.Label>
              <Form.Input
                id="phone"
                name="phone"
                className="customer-detail__form-input"
                value={values.phone || ''}
                isValid={isValidated && Boolean(!errors.phone)}
                isInValid={isValidated && Boolean(errors.phone)}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col xl={4} md={6}>
            <Form.Group className="mb-3">
              <Form.Label target="businessName" className="customer-detail__form-label">
                Business Name
              </Form.Label>
              <Form.Input
                id="businessName"
                name="businessName"
                className="customer-detail__form-input"
                value={values.businessName || ''}
                isValid={isValidated && Boolean(!errors.businessName)}
                isInValid={isValidated && Boolean(errors.businessName)}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col xl={4} md={6}>
            <Form.Group className="mb-3">
              <Form.Label target="email" className="customer-detail__form-label">
                Email <span className="color-danger">*</span>
              </Form.Label>
              <Form.Input
                id="email"
                name="email"
                type="email"
                className="customer-detail__form-input"
                value={values.email || ''}
                isValid={isValidated && Boolean(!errors.email)}
                isInValid={isValidated && Boolean(errors.email)}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
          <Col xl={4} md={6}>
            <Form.Group className="mb-3">
              <Form.Label target="website" className="customer-detail__form-label">
                Website
              </Form.Label>
              <Form.Input
                id="website"
                name="website"
                type="website"
                className="customer-detail__form-input"
                value={values.website || ''}
                isValid={isValidated && Boolean(!errors.website)}
                isInValid={isValidated && Boolean(errors.website)}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xl={4} md={12}>
            <Form.Label target="addressLine1" className="customer-detail__form-label">
              Address <span className="color-danger">*</span>
            </Form.Label>
            <Form.Group className="mb-2">
              <Form.Input
                ref={autoCompleteRef}
                id="addressLine1"
                name="addressLine1"
                placeholder="ADDRESS LINE1"
                className="customer-detail__form-input"
                value={values.addressLine1 || ''}
                isValid={isValidated && Boolean(!errors.addressLine1)}
                isInValid={isValidated && Boolean(errors.addressLine1)}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Input
                id="addressLine2"
                name="addressLine2"
                placeholder="ADDRESS LINE2"
                className="customer-detail__form-input"
                value={values.addressLine2 || ''}
                isValid={isValidated && Boolean(!errors.addressLine2)}
                isInValid={isValidated && Boolean(errors.addressLine2)}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Row className="customer-detail__form-row">
              <Col md={7} className="customer-detail__form-col">
                <Form.Group className="mb-2">
                  <Form.Input
                    id="city"
                    name="city"
                    placeholder="CITY"
                    className="customer-detail__form-input"
                    value={values.city || ''}
                    isValid={isValidated && Boolean(!errors.city)}
                    isInValid={isValidated && Boolean(errors.city)}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={5} className="customer-detail__form-col">
                <Form.Group className="mb-2">
                  {values.country === 'United States' ? (
                    <Form.Select
                      name="state"
                      placeholder="STATE"
                      value={values.state || ''}
                      className="customer-detail__form-select"
                      isValid={isValidated && Boolean(!errors.state)}
                      isInValid={isValidated && Boolean(errors.state)}
                      error={errors.state}
                      onChange={(e) => handleSelectChange('state', e.target.value)}
                      required
                    >
                      <option value="" disabled>State</option>
                      {usaStates.map((s, i) => (
                        <option key={i}>{s.name}</option>
                      ))}
                    </Form.Select>
                  ) : (
                    <Form.Input
                      id="state"
                      name="state"
                      placeholder="STATE"
                      className="customer-detail__form-input"
                      value={values.state || ''}
                      isValid={isValidated && Boolean(!errors.state)}
                      isInValid={isValidated && Boolean(errors.state)}
                      onChange={handleChange}
                      required
                    />
                  )}
                </Form.Group>
              </Col>
              <Col md={5} className="customer-detail__form-col">
                <Form.Group className="mb-2">
                  <Form.Input
                    id="zipCode"
                    name="zipCode"
                    placeholder="ZIP CODE"
                    className="customer-detail__form-input"
                    value={values.zipCode || ''}
                    isValid={isValidated && Boolean(!errors.zipCode)}
                    isInValid={isValidated && Boolean(errors.zipCode)}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={7} className="customer-detail__form-col">
                <Form.Group className="mb-2">
                  <Form.Select
                    id="country"
                    name="country"
                    placeholder="COUNTRY"
                    className="customer-detail__form-select"
                    value={values.country || ''}
                    isValid={isValidated && Boolean(!errors.country)}
                    isInValid={isValidated && Boolean(errors.country)}
                    onChange={(e) => handleSelectChange('country', e.target.value)}
                    required
                  >
                    <option value="" disabled>Country</option>
                    <option>United States</option>
                    <option>Canada</option>
                    <option>Mexico</option>
                    <option>United Kingdom</option>
                    <option>Australia</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Col>
          <Col xl={8} md={12}>
            <Form.Group className="mb-2">
              <Form.Label target="notes" className="customer-detail__form-label">
                Notes
              </Form.Label>
              <Form.Textarea
                id="notes"
                name="notes"
                type="notes"
                rows={6}
                className="customer-detail__form-input notes"
                value={values.notes || ''}
                isValid={isValidated && Boolean(!errors.notes)}
                isInValid={isValidated && Boolean(errors.notes)}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <div className="customer-detail__form-body">
          <Form.Label className="mb-2">Interactions</Form.Label>
          <p>Empty Data</p>
          <Form.Label className="mb-2">Booking</Form.Label>
          <p>Empty Data</p>
        </div>
        <div className="customer-detail__form-footer">
          <Button variant="link" onClick={cancel}>
            Cancel
          </Button>
          <Button variant="primary" round onClick={handleSubmit}>
            Save
          </Button>
        </div>
      </Form>
    </CustomerDetailWrapper>
  );
};

export default CustomerDetail;
