import React from 'react';
import { Form } from 'components';
import { PlanWrapper } from '../style';

const Plan = () => {
  return (
    <>
      <Form.Label className='d-none d-sm-block'>Billing Period</Form.Label>
      <PlanWrapper>
        <div className='plan-content__action'>Change</div>
        <div className='plan-content__title'>Monthly Plan</div>
        <div className='plan-content__description'>
          Your next payment is <strong>98.99 EUR</strong>, to be charged on{' '}
          <strong>Dec 22, 2022</strong>
        </div>
      </PlanWrapper>
    </>
  );
};

export default Plan;
