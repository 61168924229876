import React, { useState, useMemo, useEffect } from 'react';
import { navigate } from 'gatsby';
import { Scrollbars } from 'react-custom-scrollbars';
import {
  Table, Form, Searchbox, SideModal, MessageItem, Checkbox
} from 'components';
import { useIsMobile, useModal } from 'components/hooks';
import Item from './item';
import { useTableColumns } from './columns';
import { ListWrapper } from 'templates/app/style';
import { InteractionDetailWrapper } from './style';
import VoicioIcon from 'assets/image/icons/app/voicio.svg';
import FilterIcon from 'assets/image/icons/app/filter.svg';
import OpenIcon from 'assets/image/icons/app/open.svg';
import { chatData } from 'data/pages/app/interactions';
import { Interactions as TInteractions } from 'interfaces'; 

type Props = {
  data: any,
}
const Interactions: React.FC<Props> = ({ data, ...props }) => {
  const [currentCall, updateCurrentCall] = useState(null);
  const [query, setQuery] = useState('');
  const [activity, setActivity] = useState('');
  const [outcome, setOutcome] = useState('');
  const [list, setList] = useState(data);
  const {isModalOpen, showModal, hideModal} = useModal();

  const isMobile = useIsMobile();

  useEffect(() => {
    setList(data);
  }, [data]);

  const getCall = (call) => () => {
    if (call.activity.id === TInteractions.EActivities.Call) {
      updateCurrentCall(call);
      showModal();
    } else {
      navigate('/chat');
    }
  };

  const columns = useTableColumns({ getCall, });

  const result = useMemo(() => {
    return chatData.reduce((r, obj) => {
      r[obj.date] = r[obj.date] || [];
      r[obj.date].push(obj);
      return r;
    }, Object.create(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatData]);

  const handleQuery = (value) => {
    setQuery(value);
  };

  const handleChangeActivity = (e) => {
    setActivity(e.target.value);
  };

  const handleChangeOutcome = (e) => {
    setOutcome(e.target.value);
  };

  const filterChange = () => {
    const newList = data.filter(d => {
      let isMatched = true;
      if (activity) {
        isMatched = d.activity === activity;
      }
      if (outcome) {
        isMatched = isMatched && d.outcome === outcome;
      }
      if (query) {
        isMatched = isMatched && d.name.toLowerCase().includes(query);
      }
      return isMatched;
    });
    setList(newList);
  };

  useEffect(() => {
    filterChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity, outcome, query]);

  return (
    <ListWrapper {...props}>
      <div className="list__content">
        {isMobile ? (
          <>
            <Searchbox
              className="list__content-filter-input"
              placeholder="Search..."
            />
            <div className="list__content-body">
              <Scrollbars universal autoHide>
                {list.map((item, index) => (
                  <Item item={item} key={index} getCall={getCall} />
                ))}
              </Scrollbars>
            </div>
          </>
        ) : (
          <Table
            columns={columns}
            data={list}
            paginatable
            rowsPerPage={11}
            filter={
              <div className="main-table__header custom">
                <div className="main-table__header-filter">
                  <div className="me-2">
                    <Form.Select
                      name="outcome"
                      className="main-table__header-filter-select"
                      onChange={handleChangeOutcome}
                    >
                      <option value="">All Calls</option>
                      <option>New Booking</option>
                      <option>Rescheduled</option>
                      <option>Misc</option>
                      <option>Cancellation</option>
                    </Form.Select>
                  </div>
                  <div className="me-2">
                    <Form.Select
                      name="activity"
                      className="main-table__header-filter-select"
                      onChange={handleChangeActivity}
                    >
                      <option value="">Activity</option>
                      <option>Call</option>
                      <option>Message</option>
                    </Form.Select>
                  </div>
                  <div className="me-2">
                    <Form.Select
                      name="day"
                      className="main-table__header-filter-select"
                    >
                      <option>Last 365 days</option>
                      <option>Last month</option>
                      <option>Last week</option>
                    </Form.Select>
                  </div>
                  <div className="main-table__header-filter-checkbox me-2">
                    <Checkbox id="remember" />
                    <label htmlFor="remember"> Remember my filter</label>
                  </div>
                </div>
                <div className="main-table__header-filter">
                  <Searchbox
                    className="main-table__header-filter-input me-2"
                    value={query || ''}
                    placeholder="Search..."
                    onChange={e => handleQuery(e.target.value)}
                  />
                  <div className="main-table__header-filter-button me-2">
                    <FilterIcon />
                  </div>
                  <div className="main-table__header-filter-button">
                    <OpenIcon />
                  </div>
                </div>
              </div>
            }
          />
        )}
      </div>

      {currentCall && (
        <SideModal
          right
          show={isModalOpen}
          onHide={hideModal}
          title={
            <div className="d-flex align-items-center">
              <img src={currentCall.img} alt="Avatar" width="35" className="me-2" />
              <span>{currentCall.name}</span>
            </div>
          }
        >
          <InteractionDetailWrapper>
            <div className="interaction-detail-item-list">
              {Object.entries(result).map(([key, value]) => (
                <div className="interaction-detail-item" key={key}>
                  <div className="interaction-detail-item__date">{key}</div>
                  {value.map((msg, index) => (
                    <MessageItem
                      key={index}
                      Avatar={msg.type === 'voicio' ? VoicioIcon : null}
                      left={msg.type === 'voicio'}
                      message={msg.message}
                      isFeedbackSuggestable={msg.type === 'voicio'}
                    />
                  ))}
                  <div className="interaction-detail-item__hr"></div>
                </div>
              ))}
            </div>
            <div className="interaction-detail-summary">
              <div className="interaction-detail-summary__title">
                Interaction Summary
              </div>
              <div className="interaction-detail-summary__body">
                Jennifer has just had new appliances delivered, a dishwasher and
                a refrigerator. She needs to have them hooked up to the plumbing
                system in Orlando. Agent from Mario Brothers Plumbing will give
                her an estimate and send her an e-mail to confirm it.
              </div>
              <div className="interaction-detail-summary__feedback">
                Provide Feedback
              </div>
            </div>
          </InteractionDetailWrapper>
        </SideModal>
      )}
    </ListWrapper>
  );
};

export default Interactions;
