import styled from 'styled-components/macro';

export const BusinessHoursWrapper = styled.div.attrs(props => ({
  className: `business-hours ${props.className || ''}`,
}))`
  padding: 22px 43px;

  .business-hours {
    &__form {
      &-label {
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        color: #1D1D1D;
      }

      &-switch {
        padding-top: 0.45rem;
        padding-bottom: 0.45rem;
      }

      &-text {
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.3px;
        color: #8A9DA4;
        text-transform: uppercase;
      }

      &-select {
        padding: 8px 10px;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        color: #000000;
      }

      &-tabs {
        border-bottom: none;

        .nav {
          &-link {
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            color: #1D1D1D;
            padding: 9px 18px;

            &.active, &:hover, &:focus {
              border-color: transparent transparent #04B3C0;
              border-bottom-width: 2px;
            }
          }
        }
      }

      .tab-content {
        background-color: #F3F7FA;
        padding: 20px 30px;
      }
    }
  }

  @media (max-width: 575.98px) {
    height: 100%;
    padding: 20px 22px;

    .business-hours {
      &__form {
        &-label {
          font-size: 18px;
          line-height: 21px;
          padding-top: 8px;
          padding-bottom: 8px;
        }

        &-switch {
          padding-top: 0.35rem;
          padding-bottom: 0.35rem;
        }

        &-text {
          font-size: 13px;
          line-height: 15px;
        }

        // &-timezone {
        //   > div:nth-of-type(1) {
        //     border: 1px solid #DAE3E3;
        //     box-shadow: none;

        //     @media (max-width: 575px) {
        //       min-height: 45px;
        //     }
        //   }
        // }

        &-select {
          padding: 12px 10px 10px 17px;
          font-weight: 400;
          font-size: 18px;
          line-height: 21px;
          color: #667778;
          margin-bottom: 21px;
        }

        &-tabs {
          background-color: #FFFFFF;
          border-bottom: none;
          flex-wrap: nowrap;
          overflow-x: auto;
          overflow-y: hidden;

          * {
            flex-shrink: 0;
          }
        }

        .tab-content {
          padding: 15px 0px;
        }
      }
    }
  }
`;
