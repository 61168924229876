import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ErrorPage from './style';
import ErrorImage from 'assets/image/mixed/404.png';

const Error: React.VFC = () => {
  return (
    <ErrorPage backgroundColor="#f3f4f6">
      <Container>
        <Row className="justify-content-center">
          <Col xs="12" className="col-xl-6 col-lg-7 col-md-8 col-xs-10">
            <ErrorPage.Image className="error-page__image text-center">
              <img className="w-100" src={ErrorImage} alt="Error" />
            </ErrorPage.Image>
            <ErrorPage.BigTitle as="h1">404</ErrorPage.BigTitle>
            <ErrorPage.Title>Page not found</ErrorPage.Title>
            <ErrorPage.Text>
              The page you are looking for is not available or
              <br className="d-none d-xs-block" /> doesn’t belong to this
              website!
            </ErrorPage.Text>
            <ErrorPage.ButtonGroup>
              <ErrorPage.Button to="/">
                Go home
              </ErrorPage.Button>
              <ErrorPage.Button
                secondary
                className="btn-2"
                to="#"
              >
                Contact us
              </ErrorPage.Button>
            </ErrorPage.ButtonGroup>
          </Col>
        </Row>
      </Container>
    </ErrorPage>
  );
};

export default Error;
