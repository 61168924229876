import styled from 'styled-components/macro';

export const ItemWrapper = styled.div.attrs({
  className: 'onboarding__item',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 186px;
  height: 205px;
  background: #ffffff;
  box-shadow: 0px 28px 45px rgba(0, 0, 0, 0.05);
  border-radius: 35px;
  margin: 16px;
  padding: 16px 24px;

  .onboarding__item {
    &-img {
      height: 110px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-title {
      font-family: 'Gilroy', sans-serif;
      font-size: 18px;
      font-weight: 700;
      line-height: 21px;
      text-align: center;
      color: #1d1d1d;
      margin: 20px 0 0;
    }
  }

  &:hover {
    box-shadow: 0px 28px 45px rgba(102, 119, 120, 0.24);
    transform: translateY(-10px);
    transition: all 0.25s;
  }

  @media (max-width: 575px) {
    width: 172px;
    height: 190px;
    background: #ffffff;
    box-shadow: 0px 28px 45px rgba(34, 87, 113, 0.05);
    border-radius: 41px;
    margin: 12px;
    padding: 14px 24px;

    .onboarding__item {
      &-title {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
`;
