import React from 'react';
import { AppPageWrapper, Title, DateRange } from 'components';
import { useIsMobile } from 'components/hooks';
import { notificationsData } from 'data/pages/app/notifications';
import NotificationList from 'templates/app/notifications';

const Notifications: React.VFC = () => {
  const isMobile = useIsMobile();

  return (
    <AppPageWrapper title="Notifications">
      <div className="main-content-container xs-p-0">
        {!isMobile && (
          <Title
            title={'Notifications'}
            description={'Lorem Ipsum has been the industry`s standard.'}
          >
            <DateRange />
          </Title>
        )}
        <div className="main-content-body">
          <div className="main-content-wrapper">
            <NotificationList data={notificationsData} />
          </div>
        </div>
      </div>
    </AppPageWrapper>
  );
};

export default Notifications;
