import UserIcon from 'assets/image/icons/app/user.svg';
import VoicioIcon from 'assets/image/icons/app/voicio.svg';
import AvatarIcon from 'assets/image/pages/app/avatar.png';
import CallAudio from 'assets/audio/example.mp3';

export const emptyCustomer = {
  firstName: '',
  lastName: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  country: '',
  zipCode: '',
  notes: '',
  phone: '',
  website: '',
  fax: '',
};

export const customerDetailData = {
  name: 'Jennifer Smith',
  phone: '407.543.1920',
  email: 'jennifer.smith@gmail.com',
  address: '100 W 14th Ave Pkwy, Denver, CO 80204, United States',
  jobs: 3,
  earning: 2000,
  social: {
    facebook: '#',
    twitter: '#',
    instagram: '#'
  }
};

export const interactionsData = [
  {
    img: AvatarIcon,
    name: 'Andrew Smith',
    outcome: 'New Booking',
    date: '21/06/2021 at 12:15 PM',
    activity: 'Call',
    type: UserIcon,
    audio: CallAudio,
    archived: false,
  },
  {
    img: AvatarIcon,
    name: 'John Doe',
    outcome: 'Rescheduled',
    date: '21/06/2021 at 12:15 PM',
    activity: 'Message',
    type: VoicioIcon,
    text: 'Hello there\nCan I ask you some questions?',
    archived: false,
  },
  {
    img: AvatarIcon,
    name: 'Andrew Smith',
    outcome: 'Misc',
    date: '21/06/2021 at 12:15 PM',
    activity: 'Message',
    type: VoicioIcon,
    text: 'Hello there\nCan I ask you some questions?',
    archived: true,
  },
  {
    img: AvatarIcon,
    name: 'Jennier Smith',
    outcome: 'Cancellation',
    date: '21/06/2021 at 12:15 PM',
    activity: 'Call',
    type: UserIcon,
    audio: CallAudio,
    archived: true,
  },
];

export const bookingsData = [
  {
    img: AvatarIcon,
    name: 'Andrew Smith',
    address: '006 Maple Street, Orlando',
    date: '21/06/2021 at 12:15 PM',
    service: 'Fix Broken Pipe',
    archived: false,
  },
  {
    img: AvatarIcon,
    name: 'John Doe',
    address: '006 Maple Street, Orlando',
    date: '21/06/2021 at 12:15 PM',
    service: 'Fix Broken Pipe',
    archived: false,
  },
  {
    img: AvatarIcon,
    name: 'Andrew Smith',
    address: '006 Maple Street, Orlando',
    date: '21/06/2021 at 12:15 PM',
    service: 'Fix Broken Pipe',
    archived: false,
  },
  {
    img: AvatarIcon,
    name: 'Jennier Smith',
    address: '006 Maple Street, Orlando',
    date: '21/06/2021 at 12:15 PM',
    service: 'Fix Broken Pipe',
    archived: true,
  },
  {
    img: AvatarIcon,
    name: 'Andrew Smith',
    address: '006 Maple Street, Orlando',
    date: '21/06/2021 at 12:15 PM',
    service: 'Fix Broken Pipe',
    archived: true,
  },
];
