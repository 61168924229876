import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Form } from 'components';

const BillinInfo = () => {
  return (
    <Row className="g-2 align-items-end">
      <Col lg={6}>
        <Form.Label>Billing Address</Form.Label>
        <Form.Input
          type="text"
          placeholder=""
          className="billing__input"
        />
      </Col>
      <Col lg={6}>
        <Form.Label>Company Name</Form.Label>
        <Form.Input
          type="text"
          placeholder=""
          className="billing__input"
        />
      </Col>
      <Col lg={6}>
        <Form.Label>Country</Form.Label>
        <Form.Select className="billing__input">
          <option>United States</option>
          <option>Canada</option>
          <option>Mexico</option>
          <option>United Kingdom</option>
          <option>Australia</option>
        </Form.Select>
      </Col>
      <Col lg={6}>
        <Form.Label>Vat Number</Form.Label>
        <Form.Input
          type="text"
          placeholder=""
          className="billing__input"
        />
      </Col>
    </Row>
  );
};

export default BillinInfo;
