import styled from 'styled-components/macro';

export const LogDetailWrapper = styled.div.attrs(props => ({
  className: `call-log-wrapper ${props.className || ''}`,
}))`
  position: relative;
  text-align: center;
  user-select: none;
  padding: 4px 8px;

  .call-log {
    &__avatar {
      margin-bottom: 13px;

      img {
        width: 83px;
        height: 83px;
      }

      @media (max-width: 575px) {
        margin-bottom: 18px;

        img {
          width: 120px;
          height: 120px;
        }
      }
    }

    &__name {
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      color: #1D1D1D;
      margin-bottom: 3px;

      @media (max-width: 575px) {
        font-size: 22px;
        line-height: 27px;
        margin-bottom: 6px;
      }
    }

    &__phone {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #8A9DA4;
      margin-bottom: 24px;

      @media (max-width: 575px) {
        font-size: 18px;
        line-height: 21px;
      }
    }

    &__text {
      background: #F3F7FA;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: #000000;
      border-radius: 5px;
      padding: 9px 12px 18px;
      margin-bottom: 13px;
      max-height: 300px;
      overflow-y: auto;

      @media (max-width: 575px) {
        background: transparent;
        font-size: 20px;
        line-height: 28px;
        padding: 9px 0 12px;
        border-radius: 0;
        border-bottom: 1px solid #000000;
        margin-bottom: 56px;
      }

      p {
        white-space: pre-wrap;
        text-align: left;
        margin: 0;
      }
    }

    &__callback {
      @media (max-width: 575px) {
        font-size: 22px;
        line-height: 22px;
        padding: 16px 42px;
        border-radius: 59px;
      }

      &.callback {
        position: absolute;
        right: 4px;
        bottom: 39px;
      }
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      color: #A9BBBB;
      padding-top: 8px;
      margin-top: 24px;
      margin-bottom: -10px;
      margin-left: -18px;
      margin-right: -18px;
      border-top: 1px solid #DCE7EF;
    }

    &__delete {
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;