import React from 'react';
import { CardWrapper } from '../style';
import VisaIcon from 'assets/image/pages/app/visa.png';

const Card = () => {
  return (
    <CardWrapper>
      <img src={VisaIcon} alt="Visa" />
      <div className='card-content'>
        <div className='card-content__number'>**** **** **** 4141</div>
        <div className='card-content__name'>Johe Doe</div>
        <div className='card-content__date'>
          Expires on: <strong>Dec 2021</strong>
        </div>
      </div>
    </CardWrapper>
  );
};

export default Card;
