import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useQuery, useMutation } from 'react-query';
import { useRecoilStateLoadable } from 'recoil';
import { AppPageWrapper } from 'components';
import { useIsMobile, useModal, useConnector } from 'components/hooks';
import Header from 'templates/app/dashboard/header';
import Stats from 'templates/app/dashboard/stats';
import Overview from 'templates/app/dashboard/overview';
import Calendar from 'templates/app/dashboard/calendar';
import MinutesSaved from 'templates/app/dashboard/minutesSaved';
import Menu from 'templates/app/dashboard/menu';
import {
  AdvancedQuestionsModal,
  BusinessNameModal,
  ZipCodeModal,
  GetNumberModal,
  BusinessHoursModal,
} from 'templates/app/modals';
import {
  EQueryKeys,
  RefetchPolicies,
  ERefetchPolicies,
  Auth,
  ELocalStorageKeys,
} from 'interfaces';
import { getMyPhones } from 'data/pages/app/phone';
import { AuthUserState, updateUser } from 'data/auth';
import { formatNumber } from 'utils/helper';
import VoicioIcon from 'assets/image/icons/app/voicio.svg';

const Dashboard: React.VFC = () => {
  const isMobile = useIsMobile();
  const [userLoadable, setUser] = useRecoilStateLoadable(AuthUserState);
  const userToken = userLoadable?.contents.token;
  const userId = userLoadable?.contents.id;
  const { data: numbers = [], isLoading: isPhoneLoading } = useQuery(
    [EQueryKeys.MyPhones, userId],
    async () => await getMyPhones(userToken),
    {
      enabled: !!userToken,
      retry: 1,
      ...RefetchPolicies[ERefetchPolicies.Light]
    }
  );
  const {
    data: businessHours,
    mutationController: businessHoursMutation,
    isLoading: isBHLoading
  } = useConnector(
    EQueryKeys.BusinessHours
  );

  const mutation = useMutation(updateUser, {
    onSuccess: (updateBody: Auth.IUser) => {
      if (updateBody) {
        const newUser = { ...userLoadable.contents, ...updateBody };
        localStorage
          .setItem(ELocalStorageKeys.AuthUser, JSON.stringify(newUser));
        setUser(newUser);
      }
    }
  });

  const {
    isModalOpen: isBusinessNameModalOpen,
    showModal: showBusinessNameModalOpen,
    hideModal: hideBusinessNameModalOpen,
  } = useModal();
  const {
    isModalOpen: isZipCodeModalOpen,
    showModal: showZipCodeModalOpen,
    hideModal: hideZipCodeModalOpen,
  } = useModal();
  const {
    isModalOpen: isGetNumberModalOpen,
    showModal: showGetNumberModalOpen,
    hideModal: hideGetNumberModalOpen,
  } = useModal();
  const {
    isModalOpen: isBusinessHoursModalOpen,
    showModal: showBusinessHoursModalOpen,
    hideModal: hideBusinessHoursModalOpen,
  } = useModal();
  const {
    isModalOpen: isAdvancedQuestionsModalOpen,
    showModal: showAdvancedQuestionsModalOpen,
    hideModal: hideAdvancedQuestionsModalOpen,
  } = useModal();

  useEffect(() => {
    const user = userLoadable.contents;

    if (userToken && user) {
      if (!user.businessName) {
        showBusinessNameModalOpen();
      } else if (!user.zipCode) {
        showZipCodeModalOpen();
      } else if (!isBHLoading && !businessHours.length) {
        showBusinessHoursModalOpen();
      } else if (!isPhoneLoading && !numbers.length) {
        showGetNumberModalOpen();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken, isPhoneLoading, numbers, isBHLoading]);

  const getPhoneNumber = () => {
    if (numbers && numbers.length) {
      return formatNumber(numbers[0].number);
    } else {
      return '';
    }
  };

  const saveBusinessName: any = ({ name }): void => {
    mutation.mutate(
      {
        token: userToken,
        user: {
          businessName: name,
          id: userLoadable?.contents.id,
        }
      });
    hideBusinessNameModalOpen();
    if (!userLoadable.contents.zipCode) {
      showZipCodeModalOpen();
    }
  };

  const backToBusinessNameModal = (): void => {
    hideZipCodeModalOpen();
    showBusinessNameModalOpen();
  };

  const saveZipCode: any = ({ zip }): void => {
    mutation.mutate(
      {
        token: userToken,
        user: {
          zipCode: zip,
          id: userLoadable?.contents.id,
        }
      });
    hideZipCodeModalOpen();
    if (!isBHLoading && !businessHours.length) {
      showBusinessHoursModalOpen();
    }
  };

  const backToZipCodeModal = (): void => {
    hideGetNumberModalOpen();
    showZipCodeModalOpen();
  };

  const saveBusinessHours = (hours) => {
    businessHoursMutation.onCreate(hours);
    hideBusinessHoursModalOpen();
    if (!isPhoneLoading && !numbers.length) {
      showGetNumberModalOpen();
    }
  };

  return (
    <>
      <AppPageWrapper
        title={
          <div className="navbar-title__phone">
            <VoicioIcon className="me-1" /> (407) 123-1234
          </div>
        }
      >
        <div className="main-content-container">
          {isMobile ? (
            <Menu />
          ) : (
            <>
              <Header
                user={userLoadable?.contents}
                phoneNumber={getPhoneNumber()}
              />
              <Stats />
              <Row>
                <Col>
                  <Overview />
                </Col>
              </Row>
              <Row>
                <Col xl={8}>
                  <Calendar />
                </Col>
                <Col xl={4}>
                  <MinutesSaved />
                </Col>
              </Row>
            </>
          )}
        </div>
      </AppPageWrapper>

      {isBusinessNameModalOpen && (
        <BusinessNameModal
          show={isBusinessNameModalOpen}
          closeModal={hideBusinessNameModalOpen}
          onSubmit={saveBusinessName}
        />
      )}
      {isZipCodeModalOpen && (
        <ZipCodeModal
          show={isZipCodeModalOpen}
          closeModal={hideZipCodeModalOpen}
          onSubmit={saveZipCode}
          back={backToBusinessNameModal}
        />
      )}
      {isBusinessHoursModalOpen && (
        <BusinessHoursModal
          show={isBusinessHoursModalOpen}
          closeModal={hideBusinessHoursModalOpen}
          onSubmit={saveBusinessHours}
        />
      )}
      {isGetNumberModalOpen && (
        <GetNumberModal
          show={isGetNumberModalOpen}
          closeModal={hideGetNumberModalOpen}
          back={backToZipCodeModal}
          userId={userId}
        />
      )}
      <AdvancedQuestionsModal
        show={isAdvancedQuestionsModalOpen}
        closeModal={hideAdvancedQuestionsModalOpen}
      />
    </>
  );
};

export default Dashboard;
