import React from 'react';
import { Row, Col } from 'react-bootstrap';

import { useForm } from 'components/hooks';
import { Customer } from 'interfaces';
import Avatar from './components/avatar';
import Profile from './components/profile';
import Interactions from './components/interactions';
import Bookings from './components/bookings';
import JobDetail from './components/jobDetail';
import Invoice from './invoice';
import { CustomerWrapper } from './style';
import { validate } from '../customers/detail';

type Props = {
  isInvoiceForm: boolean,
  handleInvoiceForm: () => void,
  customer: Customer.IShape,
  interactionsData: any,
  bookingsData: any,
  selectedJob: any,
  handleSelectJob: (job: any) => void,
  isEditing: boolean
  updateEditing: (props: boolean) => void,
  onSubmit: (props: any) => void,
}
const CustomerDetail: React.FC<Props> = ({
  isInvoiceForm,
  handleInvoiceForm,
  customer,
  interactionsData,
  bookingsData,
  selectedJob,
  handleSelectJob,
  isEditing,
  updateEditing,
  onSubmit,
}) => {
  const {
    values,
    errors,
    isValidated,
    handleChange,
    handleSelectChange,
    handleSubmit,
    initiate,
  } = useForm(onSubmit, validate, customer);
  
  if (isInvoiceForm) {
    return (<Invoice job={selectedJob} customer={customer} />);
  }

  return (
    <Row>
      <Col xl={selectedJob ? 8 : 12}>
        <div className="main-content-body">
          <div className="main-content-wrapper">
            <CustomerWrapper>
              <Row>
                <Col xxl={2} xl={3} md={4}>
                  <Avatar
                    isEditing={isEditing}
                    updateEditing={updateEditing}
                    handleSubmit={handleSubmit}
                    initiate={initiate}
                  />
                </Col>
                <Col xxl={10} xl={9} md={8}>
                  <Profile
                    data={customer}
                    isEditing={isEditing}
                    values={values}
                    errors={errors}
                    isValidated={isValidated}
                    handleChange={handleChange}
                    handleSelectChange={handleSelectChange}
                  />
                  <hr />
                  <Interactions data={interactionsData} />
                  <Bookings data={bookingsData} />
                </Col>
              </Row>
            </CustomerWrapper>
          </div>
        </div>
      </Col>
      {selectedJob && (
        <Col xl={4} className="mt-3 mt-xl-0">
          <div className="main-content-body">
            <div className="main-content-wrapper">
              <JobDetail
                job={selectedJob}
                handleDeselect={() => handleSelectJob(null)}
                handleInvoiceForm={handleInvoiceForm}
              />
            </div>
          </div>
        </Col>
      )}
    </Row>
  );
};

export default CustomerDetail;
