import React, { useState, useMemo } from 'react';
import classnames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import { Table } from 'components';
import { useIsMobile, useModal } from 'components/hooks';
import CalendarEditForm from 'templates/app/bookingCalendar/editForm';
import { ListItemWrapper } from 'templates/app/style';
import { SectionTitle, SectionAction } from '../style';
import MessageIcon from 'assets/image/icons/app/chat.svg';


const useTableColumns = ({ getBooking }) => {
  return useMemo(() => {
    const columns = [
      {
        Header: 'NAME',
        accessor: 'name',
        Cell: ({ row }) => {
          const { original } = row;
          return (
            <div className="d-flex align-items-center">
              <img src={original.img} alt="Avatar" className="me-2" />
              {original.archived ? original.name : (
                <strong>{original.name}</strong>
              )}
            </div>
          );
        },
      },
      {
        Header: 'DATE',
        accessor: 'date',
        Cell: ({ row }) => {
          const { original } = row;
          return original.archived ? original.date : (
            <strong>{original.date}</strong>
          );
        },
      },
      {
        Header: 'ADDRESS',
        accessor: 'address',
        Cell: ({ row }) => {
          const { original } = row;
          return original.archived ? original.address : (
            <strong>{original.address}</strong>
          );
        },
      },
      {
        Header: 'SERVICE',
        accessor: 'service',
        Cell: ({ row }) => {
          const { original } = row;
          return original.archived ? original.service : (
            <strong>{original.service}</strong>
          );
        },
      },
      {
        Header: '',
        Cell: ({ data, row }) => {
          return (
            <div
              onClick={getBooking(data[row.index])}
              style={{cursor: 'pointer'}}
            >
              <MessageIcon />
            </div>
          );
        },
        accessor: 'action',
        disableSortBy: true,
      },
    ];
    return columns;
  }, [getBooking]);
};


type ItemProps = {
  item: any,
  getBooking: React.MouseEventHandler<HTMLElement>
}
const Item: React.FC<ItemProps> = ({ item, getBooking, ...props }) => {
  const { img, name, service, archived } = item;

  return (
    <ListItemWrapper
      className={classnames('d-flex align-items-center', {
        archived,
        'justify-content-between': true
      })}
      {...props}
    >
      <div className="d-flex align-items-center">
        <div className="me-2 avatar-wrapper online">
          <img src={img} alt="A" />
        </div>
        <div>
          <h3>{name}</h3>
          <span>{service}</span>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <i
          className="fa fa-chevron-right"
          onClick={() => getBooking(item)}
        />
      </div>
    </ListItemWrapper>
  );
};


type Props = {
  data: any,
}
const Bookings: React.FC<Props> = ({ data }) => {
  const [currentBooking, updateCurrentBooking] = useState(null);
  const {isModalOpen, showModal, hideModal} = useModal();

  const isMobile = useIsMobile();

  const getBooking = (booking) => () => {
    updateCurrentBooking(booking);
    showModal();
  };

  const handleSave = (): void => {
    hideModal();
  };

  const handleDelete = (): void => {
    hideModal();
  };

  const columns = useTableColumns({ getBooking, });

  return (
    <>
      {!isMobile && (
        <div className="d-flex justify-content-between align-items-center mt-5">
          <SectionTitle className="mb-1">Bookings</SectionTitle>
          <SectionAction>See all</SectionAction>
        </div>
      )}
      {isMobile ? (
        <div className="main-content-body">
          <div className="main-content-wrapper">
            <div className="customer-job-lists">
              <Scrollbars universal autoHide>
                {data.map((item, index) => (
                  <Item item={item} key={index} getBooking={getBooking} />
                ))}
              </Scrollbars>
            </div>
          </div>
        </div>
      ) : (
        <Table columns={columns} data={data} />
      )}

      {isModalOpen && currentBooking && (
        <CalendarEditForm
          initialEvent={currentBooking}
          customerList={[]}
          handleClose={hideModal}
          onSubmit={handleSave}
          onDelete={handleDelete}
        />
      )}
    </>
  );
};

export default Bookings;
