import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { useIsMobile } from 'components/hooks';
import InvoiceDetail from './components/invoiceDetail';
import InvoiceForm from './components/invoiceForm';

type Props = {
  job: any,
  customer: any,
}
const Invoice: React.FC<Props> = ({ job, customer }) => {
  const isMobile = useIsMobile();

  return (
    <Row>
      {!isMobile && (
        <Col xl={8}>
          <div className="main-content-body" style={{minHeight: '100%'}}>
            <div className="main-content-wrapper">
              <InvoiceDetail job={job} customer={customer} />
            </div>
          </div>
        </Col>
      )}
      <Col xl={4} className="mt-sm-3 mt-xl-0">
        <div className="main-content-body">
          <div className="main-content-wrapper">
            {isMobile ? (
              <div className="customer-invoice-form-wrapper">
                <Scrollbars universal autoHide>
                  <InvoiceForm job={job} customer={customer} />
                </Scrollbars>
              </div>
            ) : (
              <InvoiceForm job={job} customer={customer} />
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Invoice;
