import styled from 'styled-components/macro';

export const MinutesSavedWrapper = styled.div`
  background-color: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  padding: 17px 20px 25px;

  @media (max-width: 575px) {
    background-color: transparent;
    box-shadow: none;
    padding: 20px 0 0 0;
  }

  .minutes-saved {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      h4 {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #1D1D1D;
        margin-bottom: 0;
      }

      @media (max-width: 1300px) and (min-width: 1200px) {
        flex-direction: column;
      }
    }

    &__content {
      position: relative;
      height: 256px;

      &-chart {
        position: relative;
        height: 200px;
        margin-bottom: 16px;

        &-text {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #6C7A7B;
          text-align: center;

          strong {
            display: block;
            font-weight: 700;
            font-size: 45px;
            line-height: 53px;
            text-align: center;
            color: #1D1D1D;
          }
        }
      }

      &-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #8A9DA4;
        margin-bottom: 0;
        text-align: center;
      }
    }
  }
`;
