import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Form } from 'components';
import { Customer } from 'interfaces';
import { formatAddressLine } from 'utils/helper';

type Props = {
  customer: Customer.IShape,
}
const ProfileTab: React.FC<Props> = ({ customer }) => {
  return (
    <Row className="g-2">
      <Col xl={4} lg={6} md={12}>
        <Form.Label className="customer-profile__label">Name</Form.Label>
        <Form.Text className="customer-profile__text">
          {`${customer.firstName} ${customer.lastName}`}
        </Form.Text>
      </Col>
      <Col xl={4} lg={6} md={12}>
        <Form.Label className="customer-profile__label">
          Phone No.
        </Form.Label>
        <Form.Text className="customer-profile__text">
          {customer.phone}
        </Form.Text>
      </Col>
      <Col xl={4} lg={6} md={12}>
        <Form.Label className="customer-profile__label">Business Name</Form.Label>
        <Form.Text className="customer-profile__text">
          {customer.name}
        </Form.Text>
      </Col>
      <Col xl={4} lg={6} md={12}>
        <Form.Label className="customer-profile__label">Address</Form.Label>
        <Form.Text className="customer-profile__text">
          {formatAddressLine(customer.address)}
        </Form.Text>
      </Col>
      <Col xl={4} lg={6} md={12}>
        <Form.Label className="customer-profile__label">
          Email
        </Form.Label>
        <Form.Text className="customer-profile__text">
          {customer.email}
        </Form.Text>
      </Col>
      <Col xl={4} lg={6} md={12}>
        <Form.Label className="customer-profile__label">Website</Form.Label>
        <Form.Text className="customer-profile__text">
          {customer.website}
        </Form.Text>
      </Col>
      <Col xl={8} md={12}>
        <Form.Label className="customer-profile__label">Notes</Form.Label>
        <Form.Text className="customer-profile__text">
          {customer.notes}
        </Form.Text>
      </Col>
    </Row>
  );
};

export default ProfileTab;
