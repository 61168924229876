import React from 'react';
import { Row, Col, Form as BForm } from 'react-bootstrap';
import TimePicker from 'react-bootstrap-time-picker';
import { Form } from 'components';
import { useIsMobile } from 'components/hooks';
import { DayTimeSelectWrapper } from '../style';
import { Weekdays, IBusinessHours } from 'interfaces';

type Props = {
  dayObj: IBusinessHours,
  isShowingLabel: boolean,
  hoursIdx: number,
  onUpdate: (dayIdx: number, updateKey: string) => (val: any) => void;
}
const DayTimeSelect: React.FC<Props> = ({
  dayObj,
  hoursIdx,
  isShowingLabel = false,
  onUpdate
}) => {
  const isMobile = useIsMobile();
  const {
    day,
    openMinute,
    openHour,
    closeMinute,
    closeHour,
    active: isActive,
  } = dayObj;

  const updateValue = (key) => (e) => {
    const { value } = e.target;
    if (key === 'active') {
      onUpdate(hoursIdx, key)(!isActive);
    } else {
      onUpdate(hoursIdx, key)(parseInt(value));
    }
  };
  return (
    <DayTimeSelectWrapper>
      <Row className="day-time-select__item">
        <Col md={5} xs={3}>
          {isShowingLabel && (
            <label className="day-time-select__item-label">Weekday</label>
          )}
          <Form.Select
            value={day}
            name="weekday"
            className="day-time-select__item-select"
            onChange={updateValue('day')}
          >
            {Weekdays.map((d, index) => (
              <option
                key={index}
                value={index}
              >
                {d}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col md={4} xs={7} className="day-time-select__item-subitem">
          <div className="day-time-select__item-subitem-field">
            {isShowingLabel && (
              <label className="day-time-select__item-label">Open Time</label>
            )}
            <TimePicker
              step={30}
              value={`${openHour}:${openMinute}`}
              onChange={onUpdate(hoursIdx, 'open')}
              className="day-time-select__item-select"
            />
          </div>
          <div className="day-time-select__item-subitem-field">
            {isShowingLabel && (
              <label className="day-time-select__item-label">Close Time</label>
            )}
            <TimePicker
              step={30}
              value={`${closeHour}:${closeMinute}`}
              className="day-time-select__item-select"
              onChange={onUpdate(hoursIdx, 'close')}

            />
          </div>
        </Col>
        <Col md={3} xs={2} className="text-center">
          {isShowingLabel && (
            <label className="day-time-select__item-label">
              {isMobile ? 'Open' : 'Open/Closed'}
            </label>
          )}
          <div className="day-time-select__item-subitem-field">
            <BForm.Switch
              id="open-closed"
              checked={isActive}
              onChange={updateValue('active')}
              className="day-time-select__item-switch"
            />
          </div>
        </Col>
      </Row>
    </DayTimeSelectWrapper>
  );
};

export default DayTimeSelect;
