import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { navigate } from 'gatsby';
import { useRecoilStateLoadable } from 'recoil';
import { AuthPageWrapper, Link, BrandLogo } from 'components';
import { sendEmailSSO, AuthUserState } from 'data/auth';
import VerifyEmailIcon from 'assets/image/icons/onboarding/verify-email.svg';

const VerifyEmail: React.VFC = () => {
  const [user] = useRecoilStateLoadable(AuthUserState);
  const [isEmailSent, updateEmailSent] = useState(false);

  if (user?.state === 'hasValue' && user?.contents?.emailVerified) {
    navigate('/dashboard');
  }
  
  const emailSSO = useMutation(sendEmailSSO, {
    onSuccess: (isEmailSent: boolean) => {
      console.log('Email Sent', isEmailSent);
      updateEmailSent(true);
    }
  });

  const onSubmit = () => {
    emailSSO.mutate({ email: user?.contents?.email });
  };

  return (
    <AuthPageWrapper>
      <Link to='/'>
        <BrandLogo className='register__logo' />
      </Link>
      <div className='register__steps'>
        <div className='register__step'>
          <h2 className='register__step-title'>Please verify your Email</h2>
          <VerifyEmailIcon />
          <p className="register__step-subtitle mt-5 mb-3">
            {`Please click on the link in the email we just sent to you at
              ${user?.contents?.email}. `}
          </p>
          {!isEmailSent ?
            <span className='btn-anchor' onClick={onSubmit}>
              Click to re-send verification link
            </span>
            :
            <span>
              Email Sent!
            </span>
          }
        </div>
      </div>
      <div className='register__footer'>
        © {(new Date().getFullYear())} Voicio. Alright Reserved.
      </div>
    </AuthPageWrapper>
  );
};

export default VerifyEmail;
