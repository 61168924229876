import React from 'react';
import { useIsDesktop } from 'components/hooks';
import MessageList from './messageList';
import SendMessage from './sendMessage';
import PhoneIcon from 'assets/image/icons/app/phone2.svg';
import MoreIcon from 'assets/image/icons/app/more.svg';
import BackIcon from 'assets/image/icons/app/back.svg';

type Props = {
  channel: any,
  onSendMessage: (channelId: string, text: string) => void,
  onClearChannel: () => void,
}
const MessagesPanel: React.FC<Props> = ({
  channel, onSendMessage, onClearChannel,
}) => {
  const isDesktop = useIsDesktop();

  return (
    <div className="chat__messages-panel">
      {channel && (
        <div className="chat__messages-header">
          {isDesktop && (
            <div
              className="chat__messages-header-back"
              onClick={onClearChannel}
            >
              <BackIcon />
            </div>
          )}
          <div className="chat__messages-header-channel">
            <div className="chat__messages-header-channel-avatar">
              {channel.avatar && (
                <img src={channel.avatar} alt={channel.name} />
              )}
            </div>
            <div className="chat__messages-header-channel-body">
              <div className="chat__messages-header-channel-body-text">
                <div>Relayed from</div>
                <div className="chat__messages-header-channel-body-name">
                  {channel.name}
                </div>
              </div>
            </div>
          </div>
          <div className="chat__messages-header-action">
            <span className="chat__messages-header-action-item">
              <PhoneIcon />
            </span>
            <span className="chat__messages-header-action-item">
              <MoreIcon />
            </span>
          </div>
        </div>
      )}
      <MessageList channel={channel} />
      {channel &&
        <SendMessage channel={channel} onSendMessage={onSendMessage} />
      }
    </div>
  );
};

export default MessagesPanel;
