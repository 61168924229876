import React, { useState, useEffect, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';

import { FloatingButton } from 'components';
import { useIsMobile, useModal } from 'components/hooks';
import { emptyForm } from 'data/pages/app/bookingCalendar';
import { Calendar } from 'interfaces';
import CalendarAddForm from './addForm';
import CalendarEditForm from './editForm';
import ViewModal from './viewModal';
import { CalendarWrapper } from './style';
import PlusIcon from 'assets/image/icons/app/plus.svg';

export const renderDayHeaderContent = (columnInfo) => {
  const dateArr = columnInfo.text.split(' ');
  return (
    <>
      {dateArr[0]}{' '}
      <span className="fc-col-header-cell-cushion-week">{dateArr[1]}</span>
    </>
  );
};

const BookingCalendar = ({
  view = 'timeGridWeek',
  eventsData = [],
  customerList = [],
  onSaveEvent,
  onDeleteEvent,
  setView,
}) => {
  const [editingEvent, updateEditingEvent] = useState(null);
  const [isNew, setIsNew] = useState(false);
  const calendarRef = useRef(null);
  const isMobile = useIsMobile();
  const { isModalOpen, showModal, hideModal } = useModal();

  useEffect(() => {
    calendarRef?.current?.getApi().changeView(view);
  }, [view]);

  const handleClose = () => {
    setIsNew(false);
    updateEditingEvent(null);
  };

  const handleEdit = (eventInfo) => {
    const { event } = eventInfo;
    const eventDetail: Calendar.IEvent = {
      id: event.id,
      href: event.href,
      start: event.startStr,
      end: event.endStr,
      ...event.extendedProps
    };
    updateEditingEvent(eventDetail);
    setIsNew(false);
  };

  const handleAddNew = (): void => {
    setIsNew(true);
    updateEditingEvent(emptyForm);
  };

  const handleSave = (eventDetail): void => {
    onSaveEvent(eventDetail);
    updateEditingEvent(null);
    setIsNew(false);
  };

  const handleDelete = (eventDetail): void => {
    onDeleteEvent(eventDetail);
    updateEditingEvent(null);
    setIsNew(false);
  };

  const handleEventDrop = ({ event }): void => {
    onSaveEvent({
      ...event.extendedProps,
      start: event.startStr,
      end: event.endStr,
    });
  };

  const renderEventContent = (eventInfo) => {console.log(eventInfo);
    return (
      <div
        className="fc-event-detail"
        style={{
          borderTop: `3px solid ${eventInfo.borderColor}`,
          backgroundColor: '#FFFFFF'
        }}
      >
        <div className="fc-event-detail__info">
          <div className="fc-event-detail__title">
            {eventInfo.event.extendedProps?.organizer?.name}
          </div>
          <div className="fc-event-detail__service">
            {eventInfo.event.extendedProps?.summary}
          </div>
        </div>
        <div className="fc-event-detail__time">{eventInfo.timeText}</div>
        <div className="fc-event-detail__actions">
          <div className="fc-event-detail__actions-edit">
            <i
              className="fa fa-edit"
              onClick={() => handleEdit(eventInfo)}
            />
          </div>
        </div>
      </div>
    );};

  return (
    <CalendarWrapper>
      <FullCalendar
        ref={calendarRef}
        plugins={[
          timeGridPlugin,
          dayGridPlugin,
          listPlugin,
          interactionPlugin
        ]}
        themeSystem={'bootstrap'}
        customButtons={{
          refresh: {
            icon: 'sync fc-icon-fontawesome',
            click: () => { }
          },
          filter: {
            text: 'Filter',
            click: () => { }
          },
          'filter-mobile': {
            icon: 'filter fc-icon-fontawesome',
            click: () => { }
          },
          setting: {
            text: 'Schedule Setting',
            click: () => { }
          },
          add: {
            icon: 'plus fc-icon-fontawesome',
            click: () => handleAddNew(),
          },
          view: {
            icon: 'view fc-icon-fontawesome',
            click: () => showModal(),
          },
        }}
        headerToolbar={{
          left: 'prev title next',
          center: '',
          right: 'refresh filter filter-mobile setting add view'
        }}
        dayHeaderFormat={{
          weekday: isMobile ? 'short' : 'long',
          day: 'numeric'
        }}
        dayHeaderContent={renderDayHeaderContent}
        slotLabelFormat={{
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        }}
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        }}
        initialView={view}
        editable={true}
        droppable={true}
        nowIndicator={true}
        events={eventsData}
        eventContent={renderEventContent}
        eventDrop={handleEventDrop}
        allDaySlot={false}
        firstDay={1}
      />

      {Boolean(editingEvent) && isNew && (
        <CalendarAddForm
          initialEvent={editingEvent}
          customerList={customerList}
          handleClose={handleClose}
          onSubmit={handleSave}
        />
      )}

      {Boolean(editingEvent) && !isNew && (
        <CalendarEditForm
          initialEvent={editingEvent}
          customerList={customerList}
          handleClose={handleClose}
          onSubmit={handleSave}
          onDelete={handleDelete}
        />
      )}

      {isMobile && (
        <FloatingButton onClick={handleAddNew}>
          <PlusIcon />
        </FloatingButton>
      )}

      {isMobile && (
        <ViewModal
          show={isModalOpen}
          onHide={hideModal}
          view={view}
          setView={setView}
        />
      )}
    </CalendarWrapper>
  );
};

export default BookingCalendar;
