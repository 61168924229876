import React, { useState } from 'react';
import { AppPageWrapper, Title, Button } from 'components';
import { useIsMobile } from 'components/hooks';
import AdvancedSetting from 'templates/app/businessHours/advancedSetting';
import Setting from 'templates/app/businessHours/setting';
import { SettingButton } from 'templates/app/businessHours/setting/style';
import { BackButton, SaveButton } from 'templates/app/style';
import BackIcon from 'assets/image/icons/app/back.svg';

const BusinessHours: React.VFC = () => {
  const isMobile = useIsMobile();
  const [isAdvancedSetting, setIsAdvancedSetting] = useState(false);

  const handleAdvancedSetting = () => setIsAdvancedSetting(true);

  return (
    <AppPageWrapper
      title={isAdvancedSetting && isMobile ? 'Settings' : 'Business Hours'}
    >
      {isAdvancedSetting ? (
        <div className="main-content-container xs-p-0">
          {isMobile ? (
            <>
              <BackButton onClick={() => setIsAdvancedSetting(false)}>
                <BackIcon />{' '}Back
              </BackButton>
              <SaveButton>Save</SaveButton>
            </>
          ) : (
            <Title
              title={'Calendar Settings'}
              handleBack={() => setIsAdvancedSetting(false)}
            >
              <Button round>Save</Button>
            </Title>
          )}
          <AdvancedSetting />
        </div>
      ) : (
        <div className="main-content-container xs-p-0">
          {isMobile ? (
            <SettingButton onClick={handleAdvancedSetting}>
              <i className="fas fa-cog"></i>
            </SettingButton>
          ) : (
            <Title
              title={'Business Hours'}
              description={'Please select the days and times you are availble for appointments.'}
            >
              <div
                className='page-title__action-link'
                onClick={handleAdvancedSetting}
              >
                <span className='page-title__action-link-text'>Advanced Setting</span>
                &nbsp;&nbsp;<i className="fas fa-cog"></i>
              </div>
            </Title>
          )}
          <div className="main-content-body">
            <div className="main-content-wrapper">
              <Setting />
            </div>
          </div>
        </div>
      )}
    </AppPageWrapper>
  );
};

export default BusinessHours;
