import React from 'react';
import { ListItemWrapper } from 'templates/app/style';
import AiVoicesIcon from 'assets/image/icons/app/ai-voices.svg';

type TProps = {
  item: any,
}
const AIVoicesItem: React.FC<TProps> = ({ item }) => {
  const { name } = item;

  return (
    <ListItemWrapper
      className="d-flex align-items-center justify-content-between"
    >
      <div className="d-flex align-items-center">
        <div className="me-2 icon-wrapper purple">
          <AiVoicesIcon />
        </div>
        <div>
          <h3>{name}</h3>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <i className="fa fa-chevron-right" />
      </div>
    </ListItemWrapper>
  );
};

export default AIVoicesItem;
