import {
  Onboarding,
  Dashboard,
  VirtualReceptionist,
  Interactions,
  BookingCalendar,
  BusinessHours,
  Customers,
  Customer,
  Billing,
  Reviews,
  Settings,
  Notifications,
  Chat,
  VerifyToken,
  VerifyEmail
} from 'components/pages';
import { IAuthRoute } from 'interfaces';

const authRoutes: Array<IAuthRoute> = [
  {
    name: 'root',
    path: '/',
    Component: Dashboard,
  },
  {
    name: 'verifyToken',
    path: '/verify-token/:token/:user_id',
    Component: VerifyToken,
  },
  {
    name: 'verifyEmail',
    path: '/verify-email',
    Component: VerifyEmail,
  },
  {
    name: 'onboarding',
    path: '/onboarding',
    Component: Onboarding,
  },
  {
    name: 'dashboard',
    path: '/dashboard',
    Component: Dashboard,
  },
  {
    name: 'virtualReceptionist',
    path: '/virtual-receptionist',
    Component: VirtualReceptionist,
  },
  {
    name: 'interactions',
    path: '/interactions',
    Component: Interactions,
  },
  {
    name: 'bookingCalendar',
    path: '/booking-calendar',
    Component: BookingCalendar,
  },
  {
    name: 'businessHours',
    path: '/business-hours',
    Component: BusinessHours,
  },
  {
    name: 'customers',
    path: '/customers',
    Component: Customers,
  },
  {
    name: 'customer',
    path: '/customers/:customer_id',
    Component: Customer,
  },
  {
    name: 'billing',
    path: '/billing',
    Component: Billing,
  },
  {
    name: 'reviews',
    path: '/reviews',
    Component: Reviews,
  },
  {
    name: 'settings',
    path: '/settings',
    Component: Settings,
  },
  {
    name: 'notifications',
    path: '/notifications',
    Component: Notifications,
  },
  {
    name: 'chat',
    path: '/chat',
    Component: Chat,
  }
];

export default authRoutes;
