import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { MenuWrapper } from './style';
import VoicioIcon from 'assets/image/icons/onboarding/voicio.svg';
import CallsIcon from 'assets/image/icons/onboarding/calls.svg';
import BookingCalendarIcon from 'assets/image/icons/onboarding/booking-calendar.svg';
import CustomersIcon from 'assets/image/icons/onboarding/dashboard.svg';
import HistoryIcon from 'assets/image/icons/onboarding/history.svg';
import AddIcon from 'assets/image/icons/onboarding/add.svg';

const menuData = [
  {
    Icon: VoicioIcon,
    title: 'AI is On',
    description: 'Top to turn off AI',
    color: '#d9fafe'
  },
  {
    Icon: CallsIcon,
    title: 'Phone',
    color: '#f7f9fb'
  },
  {
    Icon: BookingCalendarIcon,
    title: 'Calendar',
    color: '#f8efff'
  },
  {
    Icon: CustomersIcon,
    title: 'Customer',
    color: '#fdfbef'
  },
  {
    Icon: HistoryIcon,
    title: 'History',
    color: '#f8f2ef'
  },
  {
    Icon: AddIcon,
    title: 'Add',
    color: '#e3f4ed'
  },
];

type Props = {
  title: string,
  description?: string,
  color: string,
  Icon: any,
}
const MenuItem: React.FC<Props> = ({
  title, description, Icon, ...props
}) => {
  return (
    <MenuWrapper {...props}>
      {Icon && <Icon className="menu__item-icon" />}
      <h3 className="menu__item-title">{title}</h3>
      <span className="menu__item-description">{description}</span>
    </MenuWrapper>
  );
};

const Menu: React.VFC = () => {
  return (
    <Row>
      {menuData.map((menu, index) => (
        <Col className="mb-3" xs={6} key={index}>
          <MenuItem {...menu} />
        </Col>
      ))}
    </Row>
  );
};

export default Menu;
