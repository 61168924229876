import React from 'react';
import classnames from 'classnames';

type Props = {
  id: string,
  selectedId: string,
  name: string,
  avatar: string,
  message: string,
  time: string,
  unread: number,
  handleClick: (id: string) => void,
}
const Channel: React.FC<Props> = ({
  id, selectedId, name, avatar, time, message, unread, handleClick
}) => {
  return (
    <div
      className={classnames('chat__channel-item', {
        selected: selectedId === id
      })}
      onClick={() => handleClick(id)}
    >
      <div className="chat__channel-item-avatar">
        {avatar && <img src={avatar} alt={name} />}
      </div>
      <div className="chat__channel-item-body">
        <div className="chat__channel-item-body-text">
          <div className="chat__channel-item-body-name">{name}</div>
          <div className="chat__channel-item-body-time">{time}</div>
        </div>
        <div className="chat__channel-item-body-text m-0">
          <div className="chat__channel-item-body-msg">{message}</div>
          {unread > 0 && (
            <div className="chat__channel-item-body-unread">{unread}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Channel;
