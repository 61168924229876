import React from 'react';
import { Link } from 'components';
import { ItemWrapper } from './style';

const Item = ({ to, title, Icon }) => (
  <Link to={to}>
    <ItemWrapper>
      <div className="onboarding__item-img">
        <Icon />
      </div>
      <p className="onboarding__item-title">{title}</p>
    </ItemWrapper>
  </Link>
);

export default Item;
