import styled from 'styled-components/macro';

export const SettingWrapper = styled.div.attrs(props => ({
  className: `account-setting-wrapper ${props.className || ''}`,
}))`
  padding: 21px 36px 32px 36px;

  @media (max-width: 575px) {
    transition: all 0.35s;
    padding: 26px 24px;
  }

  .account-setting {
    &__input, &__select {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: #000000;
      padding: 8px 13px 7px 13px;
      margin-bottom: 8px;
      box-shadow: none;

      &:focus {
        box-shadow: 0px 1px 3px rgba(0, 115, 123, 0.25);
      }

      @media (max-width: 575px) {
        font-size: 19px;
        line-height: 22px;
        padding: 14px 17px 12px 17px;
      }
    }
    &__label {
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      color: #1D1D1D;
    }
  }
`;

export const AvatarWrapper = styled.div.attrs(props => ({
  className: `avatar-setting-wrapper ${props.className || ''}`,
}))`
  @media (max-width: 575px) {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
  }

  .avatar-setting {
    &__title {
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      color: #8A9DA4;
      margin-bottom: 12px;
    }
    &__content {
      display: flex;
      align-items: center;
      position: relative;
    }
    &__avatar {
      width: 67px;
      height: 67px;
      border-radius: 12px;
      margin-right: 16px;

      img {
        width: 100%;
      }

      @media (max-width: 575px) {
        width: 125px;
        height: 125px;
        margin-right: 0;
      }
    }
    &__text {
      &-title {
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        color: #1D1D1D;
        margin-bottom: 4px;
      }
      &-description {
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;
        color: #839393;
        max-width: 240px;
        margin-right: 8px;
      }
    }
    &__action {
      button {
        background-color: #CADDDE;
      }
    }
  }
`;

export const TextWrapper = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #000000;

  span {
    color: #04b3c0;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const UploadButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  position: absolute;
  right: -10px;
  bottom: -10px;
  background-color: #FFFFFF;
  border-radius: 50%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
`;
