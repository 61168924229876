import styled from 'styled-components/macro';

export const PeriodWrapper = styled.div.attrs(props => ({
  className: `day-period-wrapper ${props.className || ''}`,
}))`
  width: 112px;
  height: 139px;
  padding: 24px 8px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border: ${props => props.checked ? '1.6px solid #04B3C0' : '1px solid #DCE7EF'};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
  border-radius: 13px;
  margin: 0 9px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #1D1D1D;
  cursor: pointer;

  @media (max-width: 575px) {
    width: 100%;
    height: 66px;
    padding: 12px 12px 12px 19px;
    flex-direction: row-reverse;
    background: #FFFFFF;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.04);
    border: 1px solid #DCE7EF;
    border-radius: 12px;
    margin-bottom: 11px;
  }

  .day-period {
    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 575px) {
        width: 80px;
        height: 42px;
      }

      svg {
        @media (max-width: 575px) {
          height: 100%;
        }
      }
    }
    &-description {
      display: inline-flex;
      justify-content: center;
      align-items: center;

      label {
        margin-right: 6px;

        @media (max-width: 575px) {
          margin-right: 13px;
        }
      }
    }
  }
`;

export const DayWrapper = styled.div.attrs(props => ({
  className: `day-wrapper ${props.className || ''}`,
}))`
  padding: 27px 0 31px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  @media (max-width: 575px) {
    padding: 21px 24px;
    flex-direction: column;
  }
`;

export const SettingButton = styled.div`
  position: absolute;
  right: 18px;
  top: 21px;
  font-size: 21px;
  color: #000000;
  z-index: 1021;
`;
