import AvatarIcon from 'assets/image/pages/app/avatar.png';

export const channelsData = [
  {
    id: '1',
    name: 'Jerry Acosta',
    date: '21/06/2021',
    time: '2:34pm',
    message: 'We can be there on the room',
    avatar: AvatarIcon,
    unread: 3,
    messages: [
      {
        id: '1',
        type: 'voicio',
        date: '21/06/2021 2:34pm',
        text: 'John, Jennifer Smith wants to know if you replace flapper valves?'
      },
      {
        id: '2',
        date: '21/06/2021 2:34pm',
        text: 'Yes, I can. Ask if they are rubber or plastic.'
      },
      {
        id: '3',
        type: 'voicio',
        date: '21/06/2021 2:34pm',
        text: 'Jennifer said they are rubber. Should  I ask anything?'
      },
      {
        id: '4',
        date: '21/06/2021 2:34pm',
        text: 'No, that’s fine'
      },
      {
        id: '5',
        type: 'voicio',
        date: '21/06/2021 2:34pm',
        text: 'Okay, thanks!'
      },
    ]
  },
  {
    id: '2',
    name: 'Jennifer Smith',
    date: '21/06/2021',
    time: '2:32pm',
    message: 'Okay, thanks!',
    avatar: AvatarIcon,
    unread: 0,
  },
  {
    id: '3',
    name: 'Chace Field',
    date: '21/06/2021',
    time: '2:30pm',
    message: 'We can be there on...',
    avatar: AvatarIcon,
    unread: 0,
  },
  {
    id: '4',
    name: 'Elena Morgan',
    date: '21/06/2021',
    time: '1:34pm',
    message: 'We can be there on...',
    avatar: AvatarIcon,
  },
  {
    id: '5',
    name: 'Johnnie Portillo',
    date: '21/06/2021',
    time: '1:30pm',
    message: 'Really? That’s great...',
    avatar: AvatarIcon,
    unread: 0,
  },
  {
    id: '6',
    name: 'Sandra Turner',
    date: '21/06/2021',
    time: '1:24pm',
    message: 'Where do you go?',
    avatar: AvatarIcon,
    unread: 0,
  },
  {
    id: '7',
    name: 'Mylo Bouvet',
    date: '21/05/2021',
    time: '2:34pm',
    message: 'We can be there on...',
    avatar: AvatarIcon,
    unread: 0,
  },
  {
    id: '8',
    name: 'Cade Haynes',
    date: '21/05/2021',
    time: '2:30pm',
    message: 'Where do you go?',
    avatar: AvatarIcon,
    unread: 0,
  },
  {
    id: '9',
    name: 'Imran Mckee',
    date: '21/05/2021',
    time: '2:24pm',
    message: 'Really? That’s great...',
    avatar: AvatarIcon,
    unread: 0,
  },
  {
    id: '10',
    name: 'Jerry Acosta',
    date: '21/05/2021',
    time: '2:15pm',
    message: 'We can be there on...',
    avatar: AvatarIcon,
    unread: 0,
  },
  {
    id: '11',
    name: 'George Porter',
    date: '21/05/2021',
    time: '1:30pm',
    message: 'We can be there on...',
    avatar: AvatarIcon,
    unread: 0,
  },
];
