import React from 'react';
import classnames from 'classnames';
import { Modal } from 'components';
import { ViewWrapper } from './style';

type Props = {
  show: boolean,
  onHide: () => void,
  view: string,
  setView: (props: string) => void,
}
const ViewModal: React.FC<Props> = ({
  show, onHide, view, setView,
}) => {
  const updateView = (nextView: string): void => {
    setView(nextView);
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <ViewWrapper>
        <h3 className='calendar-view__title'>Calendar View</h3>
        <div
          className={classnames('calendar-view__btn', {
            'calendar-view__btn-active': view === 'timeGridDay',
          })}
          onClick={() => updateView('timeGridDay')}
        >
          List
        </div>
        <div
          className={classnames('calendar-view__btn', {
            'calendar-view__btn-active': view === 'dayGridMonth',
          })}
          onClick={() => updateView('dayGridMonth')}
        >
          Monthly
        </div>
        <div
          className={classnames('calendar-view__btn', {
            'calendar-view__btn-active': view === 'timeGridWeek',
          })}
          onClick={() => updateView('timeGridWeek')}
        >
          Weekly
        </div>
      </ViewWrapper>
    </Modal>
  );
};

export default ViewModal;
