import React from 'react';
import { CustomerBoxItemWrapper } from './style';
import AvatarIcon from 'assets/image/pages/app/avatar.png';
import BusinessIcon from 'assets/image/icons/app/business.svg';
import AddressIcon from 'assets/image/icons/app/address.svg';
import EditIcon from 'assets/image/icons/app/edit.svg';
import CallIcon from 'assets/image/icons/app/phone.svg';
import MessageIcon from 'assets/image/icons/app/message.svg';
import SendIcon from 'assets/image/icons/app/navigate.svg';

type Props = {
  item: any,
  showEditCustomer: (props: any) => void,
}
const CustomerItem: React.FC<Props> = ({ item, showEditCustomer }) => {
  const { name, addressLine1, addressLine2, city } = item;

  return (
    <CustomerBoxItemWrapper className="customer-box-item">
      <div className="customer-box-item__header">
        <img src={AvatarIcon} className="customer-box-item__avatar" alt="A" />
        <h3>{name}</h3>
      </div>
      <div className="customer-box-item__body">
        <div className="customer-box-item__body-item">
          <BusinessIcon />
          <div className="customer-box-item__body-item-text">
            <span>Business Name</span>
            <p>{name}</p>
          </div>
        </div>
        <div className="customer-box-item__body-item">
          <AddressIcon />
          <div className="customer-box-item__body-item-text">
            <span>Address</span>
            <p>{addressLine1}{addressLine2?', '+addressLine2:''}{city?', '+city:''}</p>
          </div>
        </div>
      </div>
      <div className="customer-box-item__footer">
        <div
          className="customer-box-item__footer-action"
          onClick={() => showEditCustomer(item)}
        >
          <EditIcon />
        </div>
        <div className="customer-box-item__footer-action"><CallIcon /></div>
        <div className="customer-box-item__footer-action"><MessageIcon /></div>
        <div className="customer-box-item__footer-action"><SendIcon /></div>
      </div>
    </CustomerBoxItemWrapper>
  );
};

export default CustomerItem;
