import styled from 'styled-components/macro';

export const RecorderWrapper = styled.div.attrs(props => ({
  className: `recorder-wrapper ${props.className || ''}`,
}))`
  text-align: center;
  padding-bottom: 15px;
  user-select: none;

  .recorder {
    &__top-actions {
      margin-bottom: 14px;

      .btn {
        font-size: 11px;
        line-height: 11px;
        padding: 7px 13px 6px 13px;
        margin: 0 4px;

        &-default {
          background: #F0F3F5;
        }
      }
    }

    &__time {
      font-weight: 400;
      font-size: 60px;
      line-height: 70px;
      color: #1D1D1D;
    }

    &__helptext {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #8A9DA4;
      margin-bottom: 14px;
    }

    &__action {
      cursor: pointer;
      margin: 0 8px;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;