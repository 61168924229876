import styled from 'styled-components/macro';

export const SubListWrapper = styled.div`
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 12px;

    .notification-item:last-child {
      border-bottom: none;
      margin-bottom: 12px;
    }
  }
`;

export const ListItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #839393;
  padding-bottom: 15px;
  border-bottom: 1px solid #DAE4EB;
  margin-bottom: 12px;

  @media (max-width: 575px) {
    background: #F3F7FA;
    border: 1px solid #DCE7EF;
    color: #667778;
    border-radius: 12px;
    margin-bottom: 10px;
    padding: 13px 9px 11px 13px;
  }

  &:nth-child(2) {
    @media (max-width: 575px) {
      background: #FFFFFF;
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.04);
      border: none;
    }
  }

  .notification-item {
    &__main {
      display: flex;
      flex-grow: 1;
    }

    &__avatar {
      position: relative;
      width: 53px;
      height: 53px;
      border-radius: 8px;

      @media (max-width: 575px) {
        width: 41px;
        height: 41px;
      }

      img {
        width: 100%;
        border-radius: 8px;
      }

      &-icon {
        position: absolute;
        width: 21px;
        height: 21px;
        right: -5px;
        bottom: -5px;
        background: #04B3C0;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        svg {
          width: 10px;
          height: 10px;

          path {
            fill: #FFF;
          }
        }
      }
    }

    &__info {
      width: 100%;

      &-text {
        font-size: 16px;
        line-height: 19px;
        color: #1D1D1D;
        margin-bottom: 3px;

        @media (max-width: 575px) {
          font-size: 15px;
          line-height: 18px;
          color: #667778;
          margin-bottom: 4px;

          strong {
            font-size: 16px;
            color: #1D1D1D;
          }
        }
      }

      &-message {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &__date {
      display: inline-flex;
      text-align: right;

      @media (max-width: 575px) {
        display: none;
      }
    }

    &__close {
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }

    &__icon {
      width: 40px;
      min-width: 40px;
      height: 40px;
      background: #F3F7FA;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;

      svg {
        width: 22px;
        height: 22px;
      }
    }
  }
`;

export const DateLabel = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.3px;
  color: #8A9DA4;
  margin-bottom: 14px;

  @media (max-width: 575px) {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
  }
`;