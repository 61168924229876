import styled from 'styled-components/macro';

export const CalendarWrapper = styled.div`
  position: relative;
  height: 351px;
  padding: 0;
  background-color: #FFFFFF;
  border-radius: 12px;

  @media (max-width: 575px) {
    height: 100%;
    background-color: transparent;
    box-shadow: none;
  }

  .calendar {
    &__title {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #1D1D1D;
      position: absolute;
      top: 16px;
      left: 24px;

      @media (max-width: 575px) {
        left: 0;
      }
    }
  }

  .fc {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    overflow: hidden;

    @media (max-width: 575px) {
      height: 100%;
    }

    .fc-toolbar {
      &.fc-header-toolbar {
        padding: 8px 10px;
        margin: 0;

        @media (max-width: 575px) {
          padding: 8px 0;
        }

        .fc-toolbar-chunk {
          display: flex;
          align-items: center;
          flex-direction: row;
        }

        .fc-toolbar-title {
          background-color: #FFF;
          border: 1px solid #CADDDE;
          border-left: none;
          border-right: none;
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: #1D1D1D;
          padding: 6.5px 19px;
          margin: 0 -10px 0 0;

          @media (max-width: 575px) {
            font-size: 14px;
            padding: 6.5px 6px;
          }
        }

        .fc-button-primary {
          background-color: #FFF;
          border: 1px solid #D1DADA;
          border-radius: 5px;
          color: #1D1D1D;
          padding: 4px 4px;
          min-height: 33px;
          min-width: 33px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          outline: none !important;
          box-shadow: none !important;
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: #1D1D1D;

          &:not(:first-child) {
            margin-left: 10px;
          }

          &:hover, &:active, &:focus {
            opacity: 0.8;
          }

          &.fc-prev-button {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
          &.fc-next-button {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
          &.fc-add-button {
            background: #04B3C0;
            border: 1px solid #04B3C0;
            color: #FFF;
          }

          .fc-icon-fontawesome {
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
            font-family: "Font Awesome 5 Free" !important;
            font-weight: 600;
            font-size: 16px;

            &.fc-icon-sync:before {
              content: "\f2f1";
            }
            &.fc-icon-plus:before {
              content: "\f067";
            }
            &.fc-icon-filter:before {
              content: "\f0b0";
            }
          }
        }
      }
    }
    .fc-col-header-cell {
      border: 1px solid #CADDDE;

      &-cushion {
        width: 100%;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: #000000;
        padding: 9px 2px 7px 2px;

        @media (max-width: 575px) {
          font-size: 16px;
          line-height: 16px;
        }
      }

      &.fc-day-today {
        .fc-col-header-cell-cushion {
          font-weight: 700;
        }
      }
      &.fc-day-sun {
        .fc-col-header-cell-cushion {
          color: #AEB9B9;
        }
        &.fc-day-today {
          .fc-col-header-cell-cushion {
            color: #1F5673;
          }
        }
      }
    }
    .fc-timegrid {
      .fc-scrollgrid {
        border-color: #CADDDE;
        border-left: none;
        border-right: none;
      }
      .fc-timegrid-slot {
        border: none;
        height: 1em;

        &-label-cushion {
          font-weight: 500;
          font-size: 13px;
          line-height: 15px;
          text-align: right;
          color: #667778;
          padding: 0 10px;

          @media (max-width: 575px) {
            color: #1D1D1D;
          }
        }
      }
      .fc-timegrid-col.fc-day-today {
        background: none;
      }
      .fc-timegrid-axis {
        border-color: #CADDDE;
      }
      .fc-timegrid-now-indicator-arrow {
        border-color: #1F5673;
        border-width: 5px;
        border-radius: 50%;
      }
    }
    .fc-event {
      padding: 0;

      .fc-event-main {
        padding: 0;
      }
      .fc-event-detail {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        padding: 6px 5px;
        border-radius: 3px;
        overflow: hidden;
        overflow-y: auto;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);

        @media (max-width: 575px) {
          justify-content: flex-start;
          color: #FFFFFF;
        }

        &__title {
          color: #1D1D1D;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;

          @media (max-width: 575px) {
            color: #FFFFFF;
          }
        }
      }
      &.fc-timegrid-event {
        border: none;
      }
    }
    .fc-timegrid-now-indicator-line {
      border: 1px solid #1F5673;
    }
    .fc-view-harness-active {
      @media (max-width: 575px) {
        width: auto;
        background: #F3F7FA;
      }

      .fc-view {
        @media (max-width: 575px) {
          overflow-x: scroll;
        }

        > table {
          @media (max-width: 575px) {
            width: 100%;
          }
        }
        &.fc-timeGridDay-view {
          > table {
            @media (max-width: 575px) {
              width: 100%;
            }

            > thead {
              @media (max-width: 575px) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
`;
