import styled from 'styled-components/macro';
import { Box } from 'components';

const Footer = styled(Box)`
  padding: 50px 150px;
  @media (max-width: 1440px) {
    padding: 80px;
  }
  @media (max-width: 1200px) {
    padding: 50px;
  }
  @media (max-width: 992px) {
    text-align: center;
  }
  @media (max-width: 520px) {
    padding: 20px;
  }
  ::before {
    content: '';
    width: 80vw;
    height: 80vw;
    background: #F0F9FD;
    border-radius: 100%;
    position: absolute;
    left: -35vw;
    bottom: -45vw;
    @media (max-width: 992px) {
      width: 150vw;
      height: 150vw;
      left: -65vw;
      bottom: -80vw;
    }
  }
  .d-desktop {
    display: block;
    @media (max-width: 992px) {
      display: none;
    }
  }
  .d-mobile {
    display: none;
    @media (max-width: 992px) {
      display: block;
    }
  }
`;

Footer.Logo = styled.div`
  position: relative;
  margin-bottom: 2rem;
  img {
    max-width: 170px;
  }
`;

Footer.SocialShare = styled(Box).attrs({
  className: 'footer-social-share list-unstyled',
  as: 'ul',
})`
  padding: 0;
  margin-top: 30px;
  @media (min-width: 992px) {
    margin-bottom: 0;
  }
`;

Footer.Block = styled.div`
  position: relative;
  h5 {
    font-size: 1.3rem;
    margin-bottom: 1.3rem;
  }
  ul {
    list-style: none;
    padding-left: 0px;
    li {
      padding: 5px 0px;
      a {
        color: black;
        transition: 0.4s;
        &:hover {
          color: #08b6c2;
          text-decoration: underline;
        }
      }
      img {
        max-width: 170px;
      }
    }
  }
`;

Footer.SocialShareItem = styled(Box).attrs({
  className: 'list-items',
  as: 'li',
})`
  display: inline-block;
  padding: 0 10px;
  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 25px;
    min-height: 25px;
    padding-left: 0;
    border-radius: 500px;
    color: #262729;
    font-size: 1.2rem;
    transition: 0.4s;
    &:hover {
      color: #08b6c2;
    }
  }
`;

Footer.CopyRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  position: relative;
  @media (max-width: 992px) {
    justify-content: center;
  }
  span {
    color: black;
    font-weight: 700;
  }
  @media (max-width: 992px) {
    .language {
      display: none;
    }
  }
`;

export default Footer;
