import React from 'react';
import { Dropdown } from 'react-bootstrap';
import styled from 'styled-components/macro';

type CustomActionProps = {
  children: any,
  onClick: React.MouseEventHandler<HTMLDivElement>
}
export const CustomAction =
  React.forwardRef<HTMLDivElement, CustomActionProps>(({
    children, onClick
  }, ref) => (
    <div
      className="btn-action"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  ));

export const ListWrapper = styled.div.attrs(props => ({
  className: `list-wrapper ${props.className || ''}`,
}))`
  padding: 18px 28px;

  .btn-action {
    width: 20px;
    height: 20px;
    background-color: #04B3C0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;

    svg {
      height: 10px;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  @media (max-width: 575.98px) {
    height: 100%;
    padding: 15px 24px;

    .list {
      &__content {
        height: 100%;

        &-filter-input {
          background-color: #EAEFF2;
          border-radius: 11px;
          height: 40px;
          margin-bottom: 16px;
        }
        &-body {
          height: calc(100% - 56px);

          &.no-searchbar {
            height: 100%;
          }
        }
      }
    }
  }
`;

export const ListItemWrapper = styled.div`
  padding: 13px 16px 11px 12px;
  background-color: #FFFFFF;
  color: #CADDDE;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-bottom: 12px;

  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 12px;

    &.purple {
      background-color: #FBF3FF;
    }
    &.blue {
      background-color: #F3F7FA;
    }
  }

  .avatar-wrapper {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 12px;

    img {
      width: 100%;
      border-radius: 12px;
    }

    &.online {
      &:before {
        content: '';
        position: absolute;
        bottom: -3px;
        right: -3px;
        width: 11px;
        height: 11px;
        background-color: #5FCA2D;
        border: 2px solid #FFFFFF;
        border-radius: 50%;
      }
    }
  }

  h3 {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: #1D1D1D;
    margin-bottom: 3px;
  }

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #AEB9B9;
  }

  .badge {
    font-weight: 700;
    font-size: 8px;
    line-height: 10px;
    letter-spacing: 1px;
    color: #FFFFFF;
    background-color: #EAEAEA;
    border-radius: 30px;
    padding: 2px 6px 1px 6px;
    margin-left: 6px;

    &-primary {
      background-color: #46CE7C;
    }
    &-danger {
      background-color: #EE4C4C;
    }
    &-warning {
      background-color: #FFB600;
    }
  }

  &.archived {
    h3 {
      color: #8A9DA4;
    }

    .icon-wrapper svg path {
      fill: #AEB9B9;
    }
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFFF;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #1D1D1D;
  padding: 16px 18px 13px 18px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-bottom: 9px;

  i {
    color: #CADDDE;
  }
`;

export const DropdownWrapper = styled(Dropdown)`
  user-select: none;

  &.show {
    .dropdown-button {
      opacity: 0.8;
    }
  }

  .dropdown {
    &-button {
      width: 20px;
      height: 20px;
      background-color: #04B3C0;
      padding: 1px 9px;
      cursor: pointer;
      border-radius: 50%;

      svg {
        height: 10px;

        path {
          fill: #FFFFFF;
        }
      }
    }
    &-menu {
      min-width: 88px;
      box-shadow: 0px 7.91304px 22.6087px rgba(0, 0, 0, 0.15);
      border-radius: 5.65217px;
      padding: 5px 0;
    }
    &-item {
      font-weight: 400;
      font-size: 13px;
      line-height: 1;
      color: #809597;
      padding: 0.25rem 0.75rem;
      user-select: none;

      &.active, &:active {
        color: #FFFFFF;
      }
    }
  }
`;

export const BackButton = styled.div`
  position: absolute;
  left: 18px;
  top: 23px;
  font-weight: 700;
  font-size: 21px;
  line-height: 26px;
  background-color: #FFFFFF;
  color: #000000;
  z-index: 1021;
`;

export const SaveButton = styled.div`
  position: absolute;
  right: 18px;
  top: 23px;
  font-weight: 700;
  font-size: 21px;
  line-height: 26px;
  color: #04B3C0;
  z-index: 1021;

  svg {
    width: 23px;
    height: 23px;

    path {
      fill: #000;
    }
  }
`;
