import React, { useState } from 'react';
import { Searchbox, FloatingButton } from 'components';
import { useIsDesktop } from 'components/hooks';
import ChannelList from './channelList';
import PlusIcon from 'assets/image/icons/app/plus.svg';

type Props = {
  channels: Array<any>,
  selectedChannel: any,
  onSelectChannel: (id: string) => void,
  onSearchChannel: (id: string) => void,
}
const ChannelsPanel: React.FC<Props> = ({
  channels = [], selectedChannel, onSelectChannel, onSearchChannel
}) => {
  const [key, setKey] = useState('');
  const isDesktop = useIsDesktop();

  const handleInput = e => {
    setKey(e.target.value);
    onSearchChannel(e.target.value);
  };

  return (
    <div className="chat__channel-panel">
      {!isDesktop && (
        <div className="chat__channel-header">
          <div className="chat__channel-header-text">Conversations</div>
          <span className="chat__channel-header-plus"><PlusIcon /></span>
        </div>
      )}
      <div className="chat__channel-search">
        <Searchbox
          className="chat__channel-search-input"
          value={key}
          placeholder="Search..."
          onChange={handleInput}
        />
      </div>
      <ChannelList
        channels={channels}
        selectedChannel={selectedChannel}
        onSelectChannel={onSelectChannel}
      />
      {isDesktop && (
        <FloatingButton>
          <PlusIcon />
        </FloatingButton>
      )}
    </div>
  );
};

export default ChannelsPanel;
