import styled from 'styled-components/macro';

export const CustomerWrapper = styled.div.attrs(props => ({
  className: `customer-wrapper ${props.className || ''}`,
}))`
  padding: 28px 34px;
  transition: all 0.35s;

  .customer-profile {
    &__label {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      color: #8A9DA4;

      @media (max-width: 575px) {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 8px;
      }
    }

    &__text {
      font-size: 16px;
      line-height: 19px;
      color: #1D1D1D;
      padding: 8px 0;

      @media (max-width: 575px) {
        font-size: 20px;
        line-height: 23px;
        padding: 0;
      }
    }

    &__input {
      font-size: 16px;
      line-height: 19px;
      color: #1D1D1D;
      padding: 7px 10px;

      &::placeholder {
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        color: rgba(138, 157, 164, 0.2);
      }
    }

    &__select {
      padding: 10px 14px 8px 8px;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      color: #1D1D1D;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);

      option:disabled {
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        color: rgba(138, 157, 164, 0.2);
      }
    }

    &__row {
      margin: 0 -6px;
    }

    &__col {
      padding: 0 6px;
    }

    &__box  {
      @media (max-width: 575px) {
        margin-top: 21px;
        padding-top: 14px;
        padding-bottom: 11px;
        text-align: center;
        background-color: #FFFFFF;
        &:not(:first-child) {
          border-left: 1px dashed #CCD6DD;
        }
      }
    }
  }

  @media (max-width: 575px) {
    padding: 22px 20px;
    height: 100%;
  }
`;

export const JobDetailWrapper = styled.div.attrs(props => ({
  className: `customer-job-detail-wrapper ${props.className || ''}`,
}))`
  position: relative;
  padding: 23px 30px;
  transition: all 0.35s;

  @media (max-width: 575px) {
    padding: 20px 0;
  }

  .customer-job-detail {
    &__close {
      position: absolute;
      top: 11px;
      right: 10px;
      color: #D7E3E3;
      cursor: pointer;
    }
    &__icon {
      position: relative;
      width: 69px;
      height: 69px;
      background: #F3F7FA;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 9px;
      margin: 0 auto;
      margin-bottom: 16px;

      @media (max-width: 575px) {
        width: 83px;
        height: 83px;

        svg {
          width: 42px;
          height: 42px;
        }
      }

      .customer-job-detail__badge {
        position: absolute;
        right: -4px;
        bottom: -3px;
        width: 21px;
        height: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &__body {
      display: inline-flex;
      justify-content: space-between;
      width: 100%;
    }
    &__text {
      text-align: center;
      margin-bottom: 27px;

      @media (max-width: 575px) {
        margin-bottom: 18px;
      }

      &-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        color: #1D1D1D;
        margin-bottom: 9px;

        @media (max-width: 575px) {
          font-size: 22px;
          line-height: 26px;
        }
      }
      &-description {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #8A9DA4;
        margin-bottom: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        max-height: 36px;

        @media (max-width: 575px) {
          font-size: 17px;
          line-height: 20px;
          max-height: 40px;
        }
      }
    }
    &__list {
      &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #4B595E;
        margin-bottom: 9px;

        @media (max-width: 575px) {
          font-size: 20px;
          line-height: 23px;
          margin-bottom: 7px;
        }

        &.total {
          font-weight: 700;
          color: #04B3C0;
        }
      }
    }
    &__badge {
      font-weight: 700;
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      letter-spacing: 0.5px;
      background: #46CE7C;
      border-radius: 35px;
      color: #FFFFFF;
      text-transform: uppercase;
      padding: 3px 5px;
      margin-left: 8px;
      white-space: nowrap;
    }
    &__action {
      .btn {
        @media (max-width: 575px) {
          font-size: 22px;
          line-height: 22px;
          padding-top: 16px;
          padding-bottom: 14px;
        }
      }
    }
  }
`;

export const AvatarWrapper = styled.div.attrs(props => ({
  className: `customer-avatar-wrapper ${props.className || ''}`,
}))`
  @media (max-width: 575px) {
    display: flex;
    justify-content: center;
    margin-bottom: 27px;
  }

  .customer-avatar {
    &__avatar {
      position: relative;
      max-width: 130px;
      width: 100%;
      height: auto;
      border-radius: 12px;
      margin-bottom: 18px;

      img {
        width: 100%;
      }

      @media (max-width: 575px) {
        width: 89px;
        height: 89px;
        margin-bottom: 0px;
      }
    }
    &__status {
      position: absolute;
      width: 14px;
      height: 14px;
      right: 4px;
      bottom: 3px;
      border: 3px solid #FFFFFF;
      border-radius: 50%;

      &.online {
        background: #46CE7C;
      }
      &.offline {
        background: #8A9DA4;
      }
    }
    &__save {
      display: inline-block;
      max-width: 130px;
      width: 100%;
      padding: 8px 4px;
      margin-bottom: 8px;
      border-radius: 10px;

      &-icon {
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);

        path {
          fill: #FFFFFF;
        }
      }
    }
    &__action {
      max-width: 130px;
      width: 100%;
      font-weight: 500;
      font-size: 13px;
      line-height: 14px;
      color: #8A9DA4;
      border: 1px solid #DCE7EF;
      border-radius: 10px;
      padding: 9px 5px 7px 12px;
      cursor: pointer;
      user-select: none;
      margin-bottom: 8px;

      svg {
        max-width: 16px;

        path {
          fill: #8A9DA4;
        }
      }

      &:hover {
        opacity: 0.8;
      }

      &.danger {
        border: 1px solid #AE585A;
      }
    }
  }
`;

export const SectionTitle = styled.label`
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #1D1D1D;
  margin-bottom: 15px;
`;

export const SectionAction = styled.label`
  color: #1D1D1D;
  font-weight: 500;
  font-size: 13px;
  color: #8A9DA4;
  line-height: 15px;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const InteractionDetailWrapper = styled.div.attrs(props => ({
  className: `interaction-detail-wrapper ${props.className || ''}`,
}))`
  position: relative;
  background: #F3F7FA;
  height: 100%;
  padding: 24px 21px;

  .interaction-detail-item {
    &__date {
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      text-align: center;
      color: #8A9DA4;
      margin-bottom: 20px;
    }

    &__hr {
      border-bottom: 1px solid rgba(0, 0, 0, 0.5);
      margin-top: 42px;
      margin-bottom: 44px;
    }

    &:last-child {
      .interaction-detail-item__hr {
        display: none;
      }
    }
  }
`;


export const InvoiceDetailWrapper = styled.div.attrs(props => ({
  className: `customer-invoice-detail-wrapper ${props.className || ''}`,
}))`
  position: relative;
  padding: 31px 37px;
  transition: all 0.35s;

  .customer-invoice-detail {
    &__status {
      width: 80px;
      height: 80px;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;

      &::before,
      &::after {
        position: absolute;
        z-index: -1;
        content: '';
        display: block;
        border: 5px solid #9646C3;
        border-top-color: transparent;
        border-left-color: transparent;
      }
      &::before {
        top: 0;
        right: 0;
      }
      &::after {
        bottom: 0;
        left: 0;
      }
      & span {
        position: absolute;
        display: block;
        width: 120px;
        padding: 7px 0 6px;
        background-color: #9646C3;
        color: #fff;
        font-weight: 700;
        font-size: 13px;
        line-height: 15px;
        text-align: center;
        letter-spacing: 1.15px;
        text-transform: uppercase;
        text-align: center;
        right: -10px;
        top: 15px;
        transform: rotate(-45deg);
      }
    }
    &__info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 14px;

      &-company {
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: #1D1D1D;

        &-name {
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          margin-top: 16px;
        }
      }

      &-invoice {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #1D1D1D;
        text-align: right;

        &-title {
          font-weight: 700;
          font-size: 36px;
          line-height: 42px;
          text-align: right;
          letter-spacing: 1.3px;
          text-transform: uppercase;
          color: #04B3C0;
          margin-top: 6px;
          margin-bottom: 7px;
        }
        &-item {
          margin-bottom: 3px;
        }
      }
    }
    &__address {
      display: flex;
      justify-content: space-between;
      background: #F3F7FA;
      padding: 18px 20px;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: #485455;
      margin-bottom: 18px;

      p {
        margin: 6px 0 0;
      }
    }
    &__services {
      margin-bottom: 25px;

      table {
        width: 100%;

        thead {
          tr {
            border-bottom: 1.4px solid #04B3C0;

            th {
              font-weight: 700;
              font-size: 12px;
              line-height: 14px;
              letter-spacing: 0.3px;
              color: #8A9DA4;
              padding: 9px 5px;
              text-transform: uppercase;
            }
          }

        }
        tbody {
          tr {
            &:not(:last-child) {
              border-bottom: 1px dashed #D1DADA;
            }

            td {
              padding: 19px 5px 16px;
              font-weight: 400;
              font-size: 15px;
              line-height: 18px;
              color: #1D1D1D;
            }
          }
        }
      }
    }
    &__summary {
      text-align: right;
      padding: 28px 12px 28px 24px;
      border-top: 1.4px solid #04B3C0;

      &-subtotal,
      &-fee {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #1D1D1D;
        margin-bottom: 12px;
      }
      &-total {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        color: #1D1D1D;
      }
    }
    &__message {
      margin-top: 100px;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: #485455;

      p {
        margin: 6px 0 0;
      }
    }
  }

  @media (max-width: 575px) {
    padding: 26px 24px;
    height: 100%;
  }
`;

export const InvoiceFormWrapper = styled.div`
  position: relative;
  padding: 19px 22px 36px;
  transition: all 0.35s;

  @media (max-width: 575px) {
    padding: 18px 28px 18px 27px;
  }

  .customer-invoice-form {
    &__number {
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      color: #04B3C0;
      margin-bottom: 16px;
    }
    &__info {
      margin-bottom: 11px;

      &-label {
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        color: #8A9DA4;
        margin-bottom: 8px;

        @media (max-width: 575px) {
          font-size: 14px;
          line-height: 16px;
        }
      }
      &-text {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #1D1D1D;

        @media (max-width: 575px) {
          font-size: 20px;
          line-height: 23px;
        }
      }
    }
    &__form {
      margin-top: 18px;
      margin-bottom: 21px;

      &-add {
        background: #FDFDFD;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #A6AEB1;
        border: 1px dashed #D5D5D5;
        border-radius: 5px;
        padding: 6px 10px;
      }
      &-datepicker {
        position: relative;

        &-icon {
          position: absolute;
          top: 12px;
          right: 13px;
        }
      }
    }
    &__total {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #F3F7FA;
      border-radius: 5px;
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      color: #04B3C0;
      padding: 11px 12px;
      margin-bottom: 16px;

      @media (max-width: 575px) {
        background: #FFFFFF;
        box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.04);
        border-radius: 12px;
        font-size: 18px;
        line-height: 22px;
      }
    }
    &__actions {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-item {
        font-weight: 500;
        font-size: 13px;
        line-height: 14px;
        text-align: center;
        color: #8A9DA4;

        &.underlined {
          text-decoration: underline;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }`;
