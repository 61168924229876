import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { DateRange } from 'components';
import { MinutesSavedWrapper } from './style';

const options = {
  responsive: true,
  maintainAspectRatio: false,
  cutout: 90,
  events: [],
  plugins: {
    legend: {
      display: false
    }
  },
};

const data = {
  labels: ['minutes'],
  datasets: [
    {
      label: 'minutes',
      data: [75, 25],
      backgroundColor: [
        '#04b3c0', '#DCE7EF'
      ],
      borderColor: [
        '#04b3c0', '#FFFFFF'
      ],
      borderWidth: 2,
    },
  ],
};

const MinutesSaved: React.VFC = ({ ...props }) => {
  return (
    <MinutesSavedWrapper className="mb-4" {...props}>
      <div className="minutes-saved__header">
        <h4>Minutes Saved</h4>
        <DateRange isLabelEnabled={false} />
      </div>
      <div className="minutes-saved__content">
        <div className="minutes-saved__content-chart">
          <Doughnut data={data} options={options} />
          <span className="minutes-saved__content-chart-text">
            <strong>1,304</strong> Minutes
          </span>
        </div>
        <p className="minutes-saved__content-text">
          Voicio has saved your time with<br />total 1,304 minutes.
        </p>
      </div>
    </MinutesSavedWrapper>
  );
};

export default MinutesSaved;
