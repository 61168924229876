import React from 'react';
import classnames from 'classnames';
import { ListItemWrapper } from 'templates/app/style';
import DownloadIcon from 'assets/image/icons/app/download.svg';

const BillingHistoryItem = ({ item, ...rest }) => {
  const { date, amount, invoice_number: invoiceNumber, status } = item;

  return (
    <ListItemWrapper
      className="d-flex align-items-center justify-content-between"
      {...rest}
    >
      <div className="d-flex align-items-center">
        <div>
          <h3>
            {amount} - {invoiceNumber}
            <span
              className={classnames('badge', {
                'badge-primary': status === 'PAID',
                'badge-danger': status === 'FAILED',
              })}
            >
              {status}
            </span>
          </h3>
          <span>{date}</span>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className="icon-wrapper blue">
          <DownloadIcon />
        </div>
      </div>
    </ListItemWrapper>
  );
};

export default BillingHistoryItem;
