import React, { useState } from 'react';
import { Button, ProgressBar } from 'components';
import { useIsMobile } from 'components/hooks';
import { HeaderButton } from './style';
import PhoneIcon from 'assets/image/icons/app/telephone.svg';
import GoogleIcon from 'assets/image/icons/app/google.svg';
import CalendlyIcon from 'assets/image/icons/app/calendly.svg';
import BackIcon from 'assets/image/icons/app/back.svg';
import NextIcon from 'assets/image/icons/app/next.svg';

const Step4 = ({ navigation = {} }) => {
  const [isIntegrating, setIsIntegrating] = useState(false);
  const isMobile = useIsMobile();
  const { previous, next } = navigation;

  return (
    <div className="advanced-questions__step mt-5 mb-4">
      <div className="advanced-questions__step-header">
        {isMobile && (
          <HeaderButton onClick={previous}>
            <BackIcon />{'  '}Back
          </HeaderButton>
        )}
        <h2>{isMobile ? 'Calendar' : 'Keep your calendar in sync'}</h2>
        {isMobile && (
          <HeaderButton onClick={next}>
            Next{'  '}<NextIcon />
          </HeaderButton>
        )}
      </div>
      <ProgressBar className="full" steps={5} focus={3} />
      <div className="advanced-questions__step-content mb-4">
        <h2 className="advanced-questions__step-subtitle mb-3">
          {isMobile ? 'Keep your calendar in sync' : 'Calendar Options'}
        </h2>
        <div
          className="advanced-questions__step-content-description"
          style={{color: '#000'}}
        >
          Lorem Ipsum has been the industry`s standard dummy text ever since the
          1500s, when an unknown printer took a gallery of type and scrambled
          it.
        </div>
      </div>
      <div className="advanced-questions__step-content text-center mb-6">
        {isIntegrating ? (
          <>
            <Button
              pt={'12px'}
              pb={'11px'}
              sizeX={'280px'}
              backgroundColor={'#CC3B3B'}
              borderColor={'#CC3B3B'}
              hoverBackgroundColor={'#DF646'}
              hoverBorderColor={'#DF646'}
              round={isMobile}
              className="advanced-questions__step-content-btn mb-2"
            >
              <GoogleIcon />Login with Google
            </Button>
            <Button
              pt={'12px'}
              pb={'11px'}
              sizeX={'280px'}
              backgroundColor={'#006BFF'}
              borderColor={'#006BFF'}
              hoverBackgroundColor={'#1678FF'}
              hoverBorderColor={'#1678FF'}
              round={isMobile}
              className="advanced-questions__step-content-btn"
            >
              <CalendlyIcon />Login with Calendly
            </Button>
          </>
        ) : (
          <>
            <Button
              pt={'12px'}
              pb={'11px'}
              sizeX={'280px'}
              round={isMobile}
              className="mb-2"
            >
              {isMobile ? 'Use Voicio as My Calendar' : 'I want to use Voicio as my calendar'}
            </Button>
            <Button
              pt={'12px'}
              pb={'11px'}
              sizeX={'280px'}
              round={isMobile}
              onClick={() => setIsIntegrating(true)}
            >
              {isMobile ? 'Integrate My Own Calendar' : 'I want to integrate my own calendar'}
            </Button>
          </>
        )}
      </div>
      {isMobile ? (
        <div className="advanced-questions__step-phone-action absolute">
          <PhoneIcon /> Finish this over the phone
        </div>
      ) : (
        <div className="advanced-questions__step-footer">
          <span
            className="advanced-questions__step-footer-back"
            onClick={previous}
          >
            Back
          </span>
          <Button
            large
            onClick={next}
            sizeX={'137px'}
          >
            Next
          </Button>
        </div>
      )}
    </div>
  );
};

export default Step4;
