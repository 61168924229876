import { EApiRoutes, ERestMethods, Phone } from 'interfaces';
import { sendRequest } from 'data/utils';
import { usaStates } from 'typed-usa-states';

const phoneStateMapper = (num): Phone.IPPShape => {
  const phoneState = num.state;
  const stateName = usaStates.find(st =>
    st.abbreviation.toLowerCase() === phoneState.toLowerCase()
  ).name;
  return {
    ...num,
    state: stateName
  };
};

export const getMyPhones = async (token): Promise<Phone.IDBShape[]> => {
  const response =
    await sendRequest({
      route: EApiRoutes.MyPhones,
      token
    });
  if (process.env.NODE_ENV === 'development') {
    return [
      {
        id: '1',
        number: '13059998123',
        userId: '1',
        country: '1',
        freeMinutesIncluded: 1,
        maxChannels: 1,
        owner: '1',
        pricePerMinute: '1',
        pricePerMonth: '1',
        salePricePerMinute: '1',
        salePricePerMonth: '1',
        scriptId: 1,
        billingRenewal: '1',
        costApi: 1,//
        source: 1, // copy from neogen
        language: 1,
        costBody: '1',
        bluemixOverride: '1',
        notes: '1',
      }
    ];
  }
  return response;
};

export const searchPhones = async ({ state, code = null }, token) => {
  const abbreviation = usaStates.find(st =>
    st.name.toLowerCase() === state.toLowerCase()
  ).abbreviation;
  let route = EApiRoutes.PhoneSearch + `?state=${abbreviation}`;
  if (code) route += `&startsWith=${code}`;
  const result = await sendRequest({
    route,
    token,
  });
  const phones: Phone.IPPShape[] = result.map(phoneStateMapper).slice(0,6);
  return phones;
};

export const purchasePhoneNumber =
  async ({ phoneNumber, token }): Promise<Phone.IDBShape> => {
    const response = await sendRequest({
      route: EApiRoutes.PurchasePhoneNumber,
      method: ERestMethods.POST,
      token,
      body: {
        phoneNumber
      }
    });

    return response;
  };


