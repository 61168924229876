import styled from 'styled-components/macro';

export const AdvancedQuestionsWrapper = styled.div`
  margin: -15px -22px;

  @media (max-width: 575px) {
    margin: -5px -30px;

    &:not(.paddable) {
      min-height: 100vh;
      background: #F3F7FA;
    }
  }

  .btn-close {
    position: absolute;
    width: 10px;
    height: 10px;
    right: 20px;
    top: 13px;
    padding: 0;
    box-sizing: border-box;
  }

  .advanced-questions {
    &__step {
      border-radius: 10px;

      @media (max-width: 575px) {
        margin: 0 !important;
        padding-bottom: 80px;
      }

      &-image {
        max-width: 100%;
        width: 100%;
        height: auto;

        img {
          width: 100%;
        }
      }

      &-text-content {
        position: relative;
        padding: 19px 50px 33px;

        @media (max-width: 575px) {
          padding: 28px 22px 30px;
        }
      }

      &-title {
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        color: #1D1D1D;
        margin-bottom: 10px;

        @media (max-width: 575px) {
          line-height: 32px;
        }
      }

      &-description {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #000000;
        margin-bottom: 11px;

        @media (max-width: 575px) {
          font-size: 18px;
          line-height: 24px;
          color: #717171;
          margin-bottom: 22px;
        }
      }

      &-phone-action {
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: #667778;
        text-decoration: underline;
        user-select: none;
        cursor: pointer;

        @media (max-width: 575px) {
          &.absolute {
            position: absolute;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }

      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px 14px 29px;

        @media (max-width: 575px) {
          background-color: #FFFFFF;
          padding: 23px 18px 14px 18px;
        }

        h2 {
          font-weight: 700;
          font-size: 20px;
          line-height: 23px;
          color: #1D1D1D;
          margin: 0;
        }
      }

      &-subtitle {
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        color: #8A9DA4;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &-content {
        padding: 11px 16px 11px 26px;

        &-schedule {
          h2 {
            font-weight: 700;
            font-size: 15px;
            line-height: 18px;
            text-align: center;
            color: #1D1D1D;

            @media (max-width: 575px) {
              font-weight: 600;
              font-size: 14px;
              line-height: 16px;
            }
          }

          p {
            font-weight: 700;
            font-size: 15px;
            line-height: 18px;

            @media (max-width: 575px) {
              font-weight: 600;
              font-size: 14px;
              line-height: 16px;
            }
          }
        }

        &-field {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #1D1D1D;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &-select {
          font-size: 15px;
          line-height: 18px;
          color: #667778;
          padding: 8px 11px 7px 11px;
          min-width: 110px;
        }

        &-description {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #8A9DA4;
        }

        &-day-item {
          display: inline-flex;
          align-items: center;
          flex-direction: row;
          border: 1px solid #CFDCDD;
          border-radius: 10px;
          padding: 8px;
          margin-right: 17px;
          margin-bottom: 9px;

          span {
            padding: 0 8px 0 6px;
            font-weight: 500;
            font-size: 15px;
            line-height: 16px;
            color: #667778;
          }

          svg {
            cursor: pointer;

            &:hover {
              opacity: 0.8;
            }
          }
        }

        &-dropdown-toggle {
          svg {
            path {
              fill: #FFFFFF;
            }
          }

          &-item {
            i {
              margin-right: 12px;
            }
            span {
              font-size: 80%;
              font-style: italic;
              color: #8A9DA4;
            }
          }
        }

        &-btn {
          svg {
            position: absolute;
            top: 50%;
            left: 15px;
            transform: translateY(-50%);
          }
        }

        .billing__input {
          font-size: 15px;
          line-height: 18px;
        }
      }

      &-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px 0 26px;

        &-back {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          text-decoration: underline;
          color: #8A9DA4;
          cursor: pointer;
        }
      }

      &-subfooter {
        text-align: center;
        padding: 28px 0 0 0;
      }
    }
  }
`;

export const HeaderButton = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  background-color: #FFFFFF;
  color: #000000;

  &.primary {
    color: #04B3C0;
  }

  &:hover {
    opacity: 0.8;
  }
`;
