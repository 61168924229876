import React, { useState } from 'react';
import { AppPageWrapper, Title, Button } from 'components';
import { useIsMobile, useToast } from 'components/hooks';
import AccountSetting from 'templates/app/settings';
import { BackButton, SaveButton } from 'templates/app/style';
import BackIcon from 'assets/image/icons/app/back.svg';

const items = [
  {
    id: 'personal',
    label: 'Profile'
  },
  {
    id: 'company',
    label: 'Company'
  },
  {
    id: 'password',
    label: 'Password'
  },
];

const Settings: React.VFC = () => {
  const [tab, setTab] = useState('');
  const isMobile = useIsMobile();
  const toast = useToast();
  const selectedLabel = items.find((item) => item.id === tab);

  const handleSave = (): void => {
    toast({
      type: 'success',
      title: 'Success!',
      text: 'Your changes have been saved successfully.',
    });
  };

  return (
    <AppPageWrapper
      title={isMobile && selectedLabel ? selectedLabel.label : 'Account Settings'}
    >
      <div className="main-content-container xs-p-0">
        {isMobile && selectedLabel && (
          <>
            <BackButton onClick={() => setTab('')}>
              <BackIcon />{' '}Back
            </BackButton>
            <SaveButton onClick={handleSave}>Save</SaveButton>
          </>
        )}
        {!isMobile && (
          <Title
            title={'Account Settings'}
            description={'Lorem Ipsum has been the industry`s standard.'}
          >
            <Button round onClick={handleSave}>Save</Button>
          </Title>
        )}
        <div className="main-content-body">
          <div className="main-content-wrapper">
            <AccountSetting tab={tab} setTab={setTab} />
          </div>
        </div>
      </div>
    </AppPageWrapper>
  );
};

export default Settings;
