import React, { useState } from 'react';
import { Modal, Form, Button } from 'components';
import { QuickMessageWrapper } from './style';
import VoicioIcon from 'assets/image/icons/app/voicio.svg';

const messages = [
  'I am running 10 minutes late',
  'I need to cancel',
  'Can we postpone to another day?'
];

const QuickMessage = ({ handleClose }): React.ReactElement<any, any> => {
  const [isCustomMessage, setIsCustomMessage] = useState(false);

  return (
    <Modal
      show={true}
      onHide={handleClose}
      title="Quick Message"
    >
      <QuickMessageWrapper>
        <div className="quick-message">
          <div className="quick-message__header">
            <div className="quick-message__header-icon">
              <VoicioIcon />
            </div>
            <div className="quick-message__header-message">
              Choose your quick message below.
            </div>
          </div>
          <div className="quick-message__body">
            {messages.map((message, index) => (
              <span className="quick-message__message" key={index}>
                {message}
              </span>
            ))}
            <span
              className="quick-message__message custom"
              onClick={() => setIsCustomMessage(true)}
            >
              Custom Message...
            </span>
          </div>
          {isCustomMessage && (
            <div className="quick-message__custom-message">
              <div className="mb-3">
                <Form.Label>Custom Message</Form.Label>
                <Form.Textarea />
              </div>
              <div className="d-flex justify-content-end">
                <Button variant="primary" round>Send</Button>
              </div>
            </div>
          )}
        </div>
      </QuickMessageWrapper>
    </Modal>
  );
};

export default QuickMessage;
