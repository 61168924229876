import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { usaStates } from 'typed-usa-states';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useRecoilValueLoadable } from 'recoil';
import { Button, Modal, Form, Loader } from 'components';
import { useIsMobile, useForm } from 'components/hooks';
import { searchPhones, purchasePhoneNumber } from 'data/pages/app/phone';
import { AuthUserState } from 'data/auth';
import { ERefetchPolicies, RefetchPolicies, AreaCodeByState, EQueryKeys } from 'interfaces';
import { BackButton } from 'templates/app/style';
import { GetNumberWrapper } from './style';
import { formatNumber } from 'utils/helper';
import PhoneIcon from 'assets/image/icons/app/telephone.svg';
import SearchIcon from 'assets/image/icons/app/search.svg';
import BackIcon from 'assets/image/icons/app/back.svg';
import ErrorFace from 'assets/image/icons/app/error-face.svg';

function validate(values) {
  const errors: any = {};
  if (!values.state) {
    errors.state = 'State is required';
  }
  return errors;
};

type ItemProps = {
  data: any,
  buy: (phoneNumber: any) => void,
}
const NumberItem: React.FC<ItemProps> = ({ data, buy }) => {
  return (
    <div className="get-number__result-item">
      <div className="get-number__result-item-badge"><span>Free</span></div>
      <div className="get-number__result-item-number">
        {formatNumber(data.number)}
      </div>
      <div className="get-number__result-item-state">{data.state}</div>
      <div className="get-number__result-item-action">
        <Button
          className="get-number__result-item-action-btn"
          variant="secondary"
          round
          onClick={() => buy(data)}
        >
          Get this number
        </Button>
      </div>
    </div>
  );
};

type Props = {
  show: boolean,
  closeModal: () => void,
  back: () => void,
  userId: string
}
const GetNumberModal: React.FC<Props> =
  ({ show, closeModal, back, userId }) => {
    const [search, setSearch] = useState({ state: '', area_code: '' });
    const [isFetched, setIsFetched] = useState(false);
    const queryClient = useQueryClient();
    const isMobile = useIsMobile();
    const authUserLoadable = useRecoilValueLoadable(AuthUserState);
    const userToken = authUserLoadable?.contents.token;
    const { data: numbers = [], isLoading } = useQuery(
      ['phoneNumbers', search],
      async () => await searchPhones(
        { code: search.area_code, state: search.state }, userToken),
      {
        enabled: !!isFetched,
        retry: false,
        ...RefetchPolicies[ERefetchPolicies.None]
      }
    );
    const queryKey = [EQueryKeys.MyPhones, userId];
    const {
      mutate: sendPurchase,
      isLoading: isPurchaseLoading
    } = useMutation(purchasePhoneNumber, {
      mutationKey: queryKey,
      onSuccess: (newPhone) => {
        queryClient.setQueryData(queryKey,
          (old: Array<any>) => [newPhone, ...old]);
        closeModal();
      }
    }
    );

    const purchaseNumber = (phoneNumber) => {
      sendPurchase({ phoneNumber, token: userToken });
    };

    const searchNumber = (values): void => {
      if (isLoading) return;
      setSearch(values);
      setIsFetched(true);
    };

    const refreshSearch = (e): void => {
      e.preventDefault();
      setSearch({ state: '', area_code: '' });
      setIsFetched(false);
    };

    const {
      values,
      errors,
      isValidated,
      handleChange,
      handleSubmit,
    } = useForm(searchNumber, validate);

    const getAreaCodes = (): Array<number> => {
      if (!values.state) {
        return [];
      } else {
        return AreaCodeByState[values.state] || [];
      }
    };

    return (
      <Modal
        className="modal-md"
        show={show}
        onHide={closeModal}
        backdrop="static"
      >
        {(isLoading || isPurchaseLoading) && <Loader fillContainer />}
        <GetNumberWrapper>
          {isMobile && (
            <div className="get-number__header">
              <BackButton onClick={back}>
                <BackIcon />{' '}Back
              </BackButton>
              <h2 className="get-number__header-title">Setting Up</h2>
              {isFetched && numbers.length > 0 && (
                <span
                  className="get-number__header-search"
                  onClick={refreshSearch}
                >
                  <SearchIcon />
                </span>
              )}
            </div>
          )}
          {((isMobile &&
            (!isFetched ||
              (isFetched && numbers.length === 0))) || !isMobile) && (
            <Form noValidate className="mb-4" onSubmit={handleSubmit}>
              <h4 className="get-number__title">
                  Get a Shiny new AI number!
              </h4>
              <Row>
                <Col md={6}>
                  <p className="get-number__text">
                      Use the filters below to find the right number.
                  </p>
                </Col>
                <Col md={6} className="text-end">
                  <div className="get-number__phone-action">
                    <PhoneIcon /> Finish this over the phone
                  </div>
                </Col>
              </Row>
              <Row className="get-number__row">
                <Col sm={6} md={4} className="get-number__col">
                  <Form.Group className="mb-2">
                    <Form.Select
                      name="state"
                      placeholder="State"
                      value={values.state || ''}
                      isValid={isValidated && Boolean(!errors.state)}
                      isInValid={isValidated && Boolean(errors.state)}
                      error={errors.state}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select state</option>
                      {usaStates.map((s, i) => (
                        <option key={i}>{s.name}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm={6} md={4} className="get-number__col">
                  <Form.Group className="mb-2">
                    <Form.Select
                      name="area_code"
                      placeholder="Area Code"
                      value={values.area_code || ''}
                      isValid={isValidated && Boolean(!errors.area_code)}
                      isInValid={isValidated && Boolean(errors.area_code)}
                      error={errors.area_code}
                      onChange={handleChange}
                      disabled={!values.state}
                    >
                      <option value="">Select Area Code</option>
                      {getAreaCodes().map((a: number) => (
                        <option key={a}>{a}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm={12} md={4} className="get-number__col">
                  <Form.Group className="mb-2">
                    <Button
                      className="get-number__search"
                      variant="primary"
                      disabled={isLoading}
                      onClick={handleSubmit}
                    >
                      {isLoading ? 'Searching...' : 'Search'}
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          )}
          {isFetched && !isLoading && (
            <div className="get-number__result">
              {numbers.length > 0 ? (
                <div className="get-number__result-content">
                  {numbers.map((number, index) => (
                    <NumberItem
                      key={index}
                      data={number}
                      buy={purchaseNumber}
                    />
                  ))}
                </div>
              ) : (
                <div className="get-number__result-no-result">
                  <Row xs={6} md={6}>
                    <Col
                      xs={3}
                      md={3}
                      className="get-number__result-no-result-error-icon"
                    >
                      <ErrorFace />
                    </Col>
                    <Col
                      xs={9}
                      md={9}
                      className="get-number__result-no-result-message"
                    >
                      <Row className="get-number__result-no-result-header">
                        Oops!
                      </Row>
                      <Row>
                        We can’t find any available numbers for that state and
                        area code combination
                      </Row>
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          )}
        </GetNumberWrapper>
      </Modal>
    );
  };

export default GetNumberModal;
