import React from 'react';
import { Button, Modal, Form } from 'components';
import { useIsMobile, useForm } from 'components/hooks';

function validate (values) {
  const errors: any = {};
  if (!values.name) {
    errors.name = 'Name is required';
  }
  if (!values.phone) {
    errors.phone = 'Phone is required';
  }
  if (!values.addressLine1) {
    errors.addressLine1 = 'Address is required';
  }

  return errors;
};

const CustomerForm = ({
  show,
  closeModal,
  onSubmit,
  customer,
}) => {
  const isMobile = useIsMobile();

  const {
    values,
    errors,
    isValidated,
    handleChange,
    handleSubmit,
  } = useForm(onSubmit, validate, customer);

  return (
    <Modal
      show={show}
      onHide={closeModal}
      title={'New Customer'}
      footer={
        <>
          <Button variant="primary" round onClick={handleSubmit}>
            Save
          </Button>
          <Button variant="link" onClick={closeModal}>
            Close
          </Button>
        </>
      }
    >
      <Form noValidate onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          {!isMobile && <Form.Label target="name">Name</Form.Label>}
          <Form.Input
            id="name"
            name="name"
            placeholder="Name"
            value={values.name || ''}
            isValid={isValidated && Boolean(!errors.name)}
            isInValid={isValidated && Boolean(errors.name)}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          {!isMobile && <Form.Label target="phone">Phone</Form.Label>}
          <Form.Input
            id="phone"
            name="phone"
            placeholder="Phone"
            value={values.phone || ''}
            isValid={isValidated && Boolean(!errors.phone)}
            isInValid={isValidated && Boolean(errors.phone)}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          {!isMobile && <Form.Label target="addressLine1">Address</Form.Label>}
          <Form.Textarea
            id="addressLine1"
            name="addressLine1"
            placeholder="Address"
            value={values.addressLine1 || ''}
            isValid={isValidated && Boolean(!errors.addressLine1)}
            isInValid={isValidated && Boolean(errors.addressLine1)}
            onChange={handleChange}
            required
          />
        </Form.Group>
      </Form>
    </Modal>
  );
};

export default CustomerForm;
