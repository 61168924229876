import React, { useState } from 'react';
import * as fedHolidays from '@18f/us-federal-holidays';
import ScheduleSelector from 'react-schedule-selector';
import { Button, Form, ProgressBar } from 'components';
import { useIsMobile } from 'components/hooks';
import { HeaderButton } from './style';
import PhoneIcon from 'assets/image/icons/app/telephone.svg';
import EditIcon from 'assets/image/icons/app/edit.svg';
import CloseIcon from 'assets/image/icons/app/close.svg';
import BackIcon from 'assets/image/icons/app/back.svg';
import NextIcon from 'assets/image/icons/app/next.svg';

const holidays = fedHolidays.allForYear(new Date().getFullYear());
const DayItem = ({ day, edit, close }) => (
  <div className="advanced-questions__step-content-day-item">
    <EditIcon onClick={() => edit(day)} />
    <span>{day.name}</span>
    <CloseIcon onClick={() => close(day)} />
  </div>
);

const Step3 = ({ navigation = {} }) => {
  const [days, setDays] = useState([]);
  const [editableDay, setEditableDay] = useState(null);
  const [schedules, setSchedules] = useState([]);
  const isMobile = useIsMobile();
  const { previous, next } = navigation;

  const addDays = (d: string): void => {
    const day = holidays.find(day => day.name === d);
    setDays(oldDays => [...oldDays, day]);
  };

  const removeDay = (day: string): void => {
    setDays(days.filter(d => d !== day));
  };

  const handleDay = (day: string): void => {
    setEditableDay(day);
  };

  const saveDay = (): void => {
    setEditableDay(null);
  };

  const handleChange = (newSchedule): void => {
    setSchedules(newSchedule);
  };

  return (
    <div className="advanced-questions__step mt-5 mb-4">
      {editableDay ? (
        <>
          {isMobile && (
            <>
              <div className="advanced-questions__step-header">
                <HeaderButton onClick={previous}>
                  <BackIcon />{'  '}Back
                </HeaderButton>
                <h2>Me-days</h2>
                <HeaderButton className="primary" onClick={saveDay}>
                  Save
                </HeaderButton>
              </div>
              <ProgressBar className="full" steps={5} focus={2} />
            </>
          )}
          <div className="advanced-questions__step-content">
            <h2 className="advanced-questions__step-content-field mb-4">
              <span style={{
                color: isMobile ? '#8A9DA4': '#000000',
                fontSize: isMobile ? 14: 20,
                fontWeight: 700
              }}>
                Edit: { editableDay.name }
              </span>
              <Button
                variant="secondary"
                pt={'6px'}
                pb={'6px'}
                pl={'12px'}
                pr={'12px'}
                sizeX={'174px'}
                sizeY={'29px'}
                round={isMobile}
              >
                {isMobile ? 'Emergency Hours' : 'Paint Emergency Hours'}
              </Button>
            </h2>
            <div className="advanced-questions__step-content-schedule">
              <ScheduleSelector
                selection={schedules}
                numDays={1}
                minTime={8}
                maxTime={21}
                hourlyChunks={1}
                unselectedColor={'#cef0f2'}
                selectedColor={'#42b4c1'}
                hoveredColor={'#60d6e3'}
                startDate={editableDay.date}
                timeFormat={'h:mm a'}
                onChange={handleChange}
              />
            </div>
            <div className="advanced-questions__step-subfooter">
              <Button
                large
                onClick={saveDay}
                sizeX={'137px'}
              >
                Save
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="advanced-questions__step-header">
            {isMobile && (
              <HeaderButton onClick={previous}>
                <BackIcon />{'  '}Back
              </HeaderButton>
            )}
            <h2>Me-days</h2>
            {isMobile && (
              <HeaderButton onClick={next}>
                Next{'  '}<NextIcon />
              </HeaderButton>
            )}
            {!isMobile && (
              <div className="advanced-questions__step-phone-action">
                <PhoneIcon /> Finish this over the phone
              </div>
            )}
          </div>
          <ProgressBar className="full" steps={5} focus={2} />
          <div className="advanced-questions__step-content">
            {isMobile && (
              <h2 className="advanced-questions__step-subtitle mb-3">
                Vacation days
              </h2>
            )}
            <div className="advanced-questions__step-content-field mb-3">
              Which days do you want to be left the #$*% alone?
            </div>
            <div className="advanced-questions__step-content-days">
              {days.map((day) => (
                <DayItem
                  key={day}
                  day={day}
                  edit={handleDay}
                  close={removeDay}
                />
              ))}
            </div>
            <div className="advanced-questions__step-content-field mb-4">
              {!isMobile && (<span></span>)}
              <Form.Select
                className="advanced-questions__step-content-select"
                onChange={e => addDays(e.target.value)}
              >
                <option>Add another</option>
                {holidays.filter((d) => !days.includes(d)).map((d) => (
                  <option key={d.name}>{d.name}</option>
                ))}
              </Form.Select>
            </div>
          </div>
          {isMobile ? (
            <div className="advanced-questions__step-phone-action absolute">
              <PhoneIcon /> Finish this over the phone
            </div>
          ) : (
            <div className="advanced-questions__step-footer">
              <span
                className="advanced-questions__step-footer-back"
                onClick={previous}
              >
                Back
              </span>
              <Button
                large
                onClick={next}
                sizeX={'137px'}
              >
                Next
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Step3;
