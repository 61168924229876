import styled from 'styled-components/macro';

export const ChatWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  height: calc(100vh - 3.75rem);

  @media (max-width: 1200px) {
    height: 100%;
  }

  .chat {
    &__channel {
      &-panel {
        width: 276px;
        height: 100%;
        background: #FFFFFF;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);

        @media (max-width: 1200px) {
          width: 100%;
          background-color: #F2F9FA;
          padding: 12px 0 18px;
          box-shadow: none;
        }

        &-empty {
          color: #AEB9B9;
          font-style: italic;
          font-size: 16px;
          text-align: center;
          margin: 20px;
        }
      }

      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 18px 17px 10px 17px;

        &-text {
          font-weight: 700;
          font-size: 20px;
          line-height: 23px;
          color: #1D1D1D;
        }

        &-plus {
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #04B3C0;
          border-radius: 50%;

          &:hover {
            opacity: 0.8;
          }

          svg {
            width: 14px;
            height: 14px;
          }
        }
      }

      &-search {
        padding: 3px 17px 7px 17px;

        &-input {
          background: #F4F9FA;
          height: 42px;

          @media (max-width: 1200px) {
            background: #EAEFF2;
          }
        }
      }

      &-list {
        width: 100%;
        height: calc(100% - 112px);

        &-date {
          font-weight: 700;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0.3px;
          text-transform: uppercase;
          color: #8A9DA4;
          padding: 16px 17px 2px;

          @media (max-width: 1200px) {
            padding: 18px 22px 10px;
          }
        }

        &-empty {
          color: #AEB9B9;
          font-style: italic;
          font-size: 16px;
          text-align: center;
          margin: 10px;
        }
      }

      &-item {
        display: flex;
        align-items: center;
        background: #FFFFFF;
        padding: 12px 18px 13px 16px;
        cursor: pointer;

        @media (max-width: 1200px) {
          margin: 0 21px 7px 22px;
          box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.04);
          border-radius: 12px;
        }

        &.selected {
          background: #E1EBF0;
        }

        &-avatar {
          width: 46px;
          margin-right: 13px;

          img {
            width: 100%;
            border-radius: 12px;
          }
        }

        &-body {
          flex: 1;

          &-text {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 4px;
          }

          &-name {
            font-weight: 400;
            font-size: 17px;
            line-height: 20px;
            color: #1D1D1D;
          }

          &-time {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            text-align: right;
            color: #AEB9B9;
          }

          &-msg {
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            color: #8A9DA4;
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-right: 6px;
          }

          &-unread {
            width: 24px;
            height: 24px;
            background: #04B3C0;
            color: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            border-radius: 8px;
          }
        }
      }
    }

    &__messages {
      &-panel {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1;

        @media (max-width: 1200px) {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 100vh;
          z-index: 1021;
        }
      }

      &-header {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background: #E1EBF0;
        padding: 6px 12px 7px 16px;

        @media (max-width: 1200px) {
          background: #FFFFFF;
          padding: 17px 16px 12px 43px;
        }

        &-back {
          position: absolute;
          left: 17px;
          top: 50%;
          transform: translateY(-50%);
          padding: 0 2px;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }
        }

        &-channel {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &-avatar {
            width: 36px;
            margin-right: 11px;

            img {
              width: 100%;
              border-radius: 12px;
            }
          }

          &-body {
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #8A9DA4;

            &-name {
              font-size: 18px;
              line-height: 21px;
              color: #1D1D1D;
            }
          }
        }

        &-action {
          &-item {
            width: 24px;
            margin: 0 2px;
            text-align: center;
            cursor: pointer;

            &:hover {
              opacity: 0.8;
            }

            svg path {
              fill: #000000;

              @media (max-width: 1200px) {
                fill: #8A9DA4;
              }
            }
          }
        }
      }

      &-list {
        align-self: stretch;
        height: calc(100% - 60px);
        background-color: #F2F9FA;
        padding: 22px 40px 10px 45px;
        overflow: auto;

        @media (max-width: 1200px) {
          padding: 16px 11px 10px 10px;
        }

        &-empty {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          color: #AEB9B9;
          font-style: italic;
          font-size: 16px;
        }

        &-body {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          min-height: 100%;
        }
      }

      &-input {
        position: relative;
        width: 100%;
        display: flex;
        background-color: #F2F9FA;
        padding: 0 44px 35px 45px;

        @media (max-width: 1200px) {
          background-color: #FFFFFF;
          min-height: 104px;
          padding: 0;
        }

        input {
          margin: 0 auto;
          width: 100%;
          height: 49px;
          background: #FFFFFF;
          box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
          border: none;
          border-radius: 6px;
          padding: 15px 45px 14px 85px;

          &:focus {
            outline: none;
          }

          @media (max-width: 1200px) {
            box-shadow: none;
            padding-left: 25px;
          }
        }

        &-icons {
          position: absolute;
          top: 15px;

          &.left {
            left: 60px;
            width: 55px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media (max-width: 1200px) {
              top: 60px;
              left: 19px;
            }
          }
          &.right {
            right: 58px;

            @media (max-width: 1200px) {
              top: 18px;
              right: 18px;
            }
          }
        }

        &-icon {
          cursor: pointer;
        }
      }
    }
  }
`;
