import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useIsMobile } from 'components/hooks';
import { ListItemWrapper } from './style';
import CallIcon from 'assets/image/icons/app/notification-call.svg';
import MailIcon from 'assets/image/icons/app/notification-mail.svg';

type Props = {
  item: any,
}
const NotificationItem: React.FC<Props> = ({ item, ...props }) => {
  const { img, name, phone, date, type, message, answered, voicio } = item;
  const isMobile = useIsMobile();

  const text = (): string => {
    let str = '';
    if (type === 'call') {
      if (answered) {
        str = 'called you, and you answered';
      } else {
        if (voicio) {
          str = 'called you, and Voicio answered';
        } else {
          str = 'tried to call you, but was unanswered';
        }
      }
    } else {
      str = 'sent you a text message';
    }

    return str;
  };

  return (
    <ListItemWrapper className="notification-item" {...props}>
      <div className="notification-item__main">
        <div className="me-2 notification-item__avatar">
          <img src={img} alt={name} />
          {!isMobile && (
            <div className="notification-item__avatar-icon">
              {type === 'call' && <CallIcon />}
              {type === 'message' && <MailIcon />}
            </div>
          )}
        </div>
        <div className="notification-item__info">
          <div className="notification-item__info-text">
            <strong>{name}</strong> {text()}
          </div>
          <div className="notification-item__info-desc">
            <Row>
              <Col md={12} lg={3}>
                {phone}
              </Col>
              <Col md={8} lg={9} className="notification-item__info-message d-none d-lg-block">
                {message}
              </Col>
            </Row>
          </div>
        </div>
      </div>

      {isMobile ? (
        <div className="notification-item__icon ms-2">
          {type === 'call' && <CallIcon />}
          {type === 'message' && <MailIcon />}
        </div>
      ) : (
        <div className="notification-item__date">
          <small className="ms-2">{date}</small>
          <div className="notification-item__close ms-2">
            <i className="fa fa-times" />
          </div>
        </div>
      )}
    </ListItemWrapper>
  );
};

export default NotificationItem;
