import React from 'react';
import { BillingInfo, PaymentMethod, BillingHistory } from './components';

const TabContent = ({ tab }) => {
  switch (tab) {
    case 'billingInfo':
      return <BillingInfo />;
    case 'paymentMethod':
      return <PaymentMethod />;
    case 'billingHistory':
      return <BillingHistory />;
    default:
      return <></>;
  }
};

export default TabContent;
