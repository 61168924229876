import React, { useEffect } from 'react';
import { useParams } from '@reach/router';
import { navigate } from 'gatsby';
import { useRecoilStateLoadable } from 'recoil';
import { useMutation } from 'react-query';
import { Loader } from 'components';
import { sendVerificationToken, AuthUserState } from 'data/auth';

const VerifyToken: React.VFC<any> = () => {
  const [_, setUser] = useRecoilStateLoadable(AuthUserState);

  const params = useParams();

  const mutation = useMutation(sendVerificationToken, {
    onSuccess: (user) => {
      if (user) {
        setUser(user);
        navigate('/dashboard');
      } else {
        navigate('/login');
      }
    }
  });

  useEffect(() => {
    mutation.mutate({ token: params.token, userId: params.user_id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Loader fillContainer />
  );
};

export default VerifyToken;
