import React from 'react';
import { AppPageWrapper } from 'components';
import ChatComponent from 'templates/app/chat';

const Chat: React.VFC = () => {
  return (
    <AppPageWrapper title="Conversations">
      <div className="main-content-container p-0">
        <div className="main-content-body">
          <ChatComponent />
        </div>
      </div>
    </AppPageWrapper>
  );
};

export default Chat;
