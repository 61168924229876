import AvatarIcon from 'assets/image/pages/app/user-actions-avatar.png';

export const notificationsData = [
  {
    img: AvatarIcon,
    name: 'Andrew Smith',
    phone: '+19203930489',
    date: '21/06/2021 12:00AM',
    type: 'call',
    answered: true,
    voicio: false,
  },
  {
    img: AvatarIcon,
    name: 'John Doe',
    phone: '+19203930489',
    date: '21/06/2021 12:20AM',
    type: 'message',
    message: 'Lorem Ipsum has been the industry`s standard dummy text',
  },
  {
    img: AvatarIcon,
    name: 'Andrew Smith',
    phone: '+19203930489',
    date: '14/06/2021 01:00PM',
    type: 'call',
    answered: false,
  },
  {
    img: AvatarIcon,
    name: 'Jennier Smith',
    phone: '+19203930489',
    date: '11/06/2021 04:00PM',
    type: 'message',
    message: 'Hello there... how are you?',
  },
  {
    img: AvatarIcon,
    name: 'Andrew Smith',
    phone: '+19203930489',
    date: '05/06/2021 02:30PM',
    type: 'call',
    answered: false,
    voicio: true,
  },
  {
    img: AvatarIcon,
    name: 'Jennier Smith',
    phone: '+19203930489',
    date: '05/06/2021 12:00AM',
    type: 'message',
    message: 'Hello there... how are you?',
  },
  {
    img: AvatarIcon,
    name: 'John Smith',
    phone: '+19203930489',
    date: '05/06/2021 01:00AM',
    type: 'call',
    answered: false,
    voicio: false,
  },
];
