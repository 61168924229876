import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Form } from 'components';

const Personal: React.VFC = () => {
  return (
    <Row className="g-2">
      <Col lg={4} md={6}>
        <Form.Label>Full Name</Form.Label>
        <Form.Input
          type="text"
          placeholder=""
          className="account-setting__input"
        />
      </Col>
      <Col lg={4} md={6}>
        <Form.Label>Email address</Form.Label>
        <Form.Input
          type="email"
          placeholder=""
          className="account-setting__input"
        />
      </Col>
      <Col lg={4} md={6}>
        <Form.Label>Phone</Form.Label>
        <Form.Input
          type="phone"
          placeholder=""
          className="account-setting__input"
        />
      </Col>
      <Col lg={4} md={6}>
        <Form.Label>Country</Form.Label>
        <Form.Select className="account-setting__select">
          <option>United States</option>
          <option>Canada</option>
          <option>Mexico</option>
          <option>United Kingdom</option>
          <option>Australia</option>
        </Form.Select>
      </Col>
      <Col lg={4} md={6}>
        <Form.Label>Language</Form.Label>
        <Form.Select className="account-setting__select">
          <option>English</option>
          <option>French</option>
          <option>Chinese</option>
        </Form.Select>
      </Col>
    </Row>
  );
};

export default Personal;
