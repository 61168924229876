import React from 'react';
import { AppPageWrapper, Title, DateRange } from 'components';
import { useIsMobile } from 'components/hooks';
import ReviewsList from 'templates/app/reviews';

const Reviews: React.VFC = () => {
  const isMobile = useIsMobile();

  return (
    <AppPageWrapper title="Reviews">
      <div className="main-content-container xs-p-0">
        {!isMobile && (
          <Title
            title={'Reviews'}
            description={'Lorem Ipsum has been the industry`s standard.'}
          >
            <DateRange />
          </Title>
        )}
        <div
          className="main-content-body"
          style={{ backgroundColor: 'transparent' }}
        >
          <div className="main-content-wrapper">
            <ReviewsList />
          </div>
        </div>
      </div>
    </AppPageWrapper>
  );
};

export default Reviews;
