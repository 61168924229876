import React from 'react';
import { Button, Dropdown, ProgressBar } from 'components';
import { useIsMobile } from 'components/hooks';
import { HeaderButton } from './style';
import PhoneIcon from 'assets/image/icons/app/telephone.svg';
import CalendarIcon from 'assets/image/icons/app/calendar.svg';
import BackIcon from 'assets/image/icons/app/back.svg';
import NextIcon from 'assets/image/icons/app/next.svg';

const Step5 = ({ navigation = {} }) => {
  const isMobile = useIsMobile();
  const { previous, next } = navigation;

  return (
    <div className="advanced-questions__step mt-5 mb-4">
      <div className="advanced-questions__step-header">
        {isMobile && (
          <HeaderButton onClick={previous}>
            <BackIcon />{'  '}Back
          </HeaderButton>
        )}
        <h2>Don`t be late...</h2>
        {isMobile && (
          <HeaderButton onClick={next}>
            Next{'  '}<NextIcon />
          </HeaderButton>
        )}
      </div>
      <ProgressBar className="full" steps={5} focus={4} />
      <div className="advanced-questions__step-content mb-4">
        <h2 className="advanced-questions__step-subtitle mb-3">
          Add your Voicio Calendar
        </h2>
        <div
          className="advanced-questions__step-content-description"
          style={{color: '#000'}}
        >
          Keeping track of where you need to has never been easier. Add your
          Voicio calendar to your devices to receive calendar notifications, and
          keep an eye on your day.
        </div>
      </div>
      <div className="advanced-questions__step-content text-center mb-6">
        <Dropdown
          label={
            <Button
              className="advanced-questions__step-content-dropdown-toggle"
              pt={'14px'}
              pb={'14px'}
            >
              <CalendarIcon />&nbsp;&nbsp;Add to Calendar
            </Button>
          }
        >
          <Dropdown.Item
            as="button"
            className="advanced-questions__step-content-dropdown-toggle-item"
          >
            <i className="fab fa-apple fa-fw"></i> Apple <span>(online)</span>
          </Dropdown.Item>
          <Dropdown.Item
            as="button"
            className="advanced-questions__step-content-dropdown-toggle-item"
          >
            <i className="fab fa-google fa-fw"></i> Google
          </Dropdown.Item>
          <Dropdown.Item
            as="button"
            className="advanced-questions__step-content-dropdown-toggle-item"
          >
            <i className="fab fa-microsoft fa-fw"></i> Outlook <span>(online)</span>
          </Dropdown.Item>
          <Dropdown.Item
            as="button"
            className="advanced-questions__step-content-dropdown-toggle-item"
          >
            <i className="fab fa-yahoo fa-fw"></i> Yahoo
          </Dropdown.Item>
          <Dropdown.Item
            as="button"
            className="advanced-questions__step-content-dropdown-toggle-item"
          >
            <i className="fab fa-yandex fa-fw"></i> Yandex
          </Dropdown.Item>
        </Dropdown>
      </div>
      {isMobile ? (
        <div className="advanced-questions__step-phone-action absolute">
          <PhoneIcon /> Finish this over the phone
        </div>
      ) : (
        <div className="advanced-questions__step-footer">
          <span
            className="advanced-questions__step-footer-back"
            onClick={previous}
          >
            Back
          </span>
          <Button
            large
            onClick={next}
            sizeX={'137px'}
          >
            Next
          </Button>
        </div>
      )}
    </div>
  );
};

export default Step5;
