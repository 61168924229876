import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Form } from 'components';
import CreditCardIcon from 'assets/image/icons/app/credit-card.svg';
import { QuestionWrapper } from '../style';

const PaymentMethod = () => {
  return (
    <Row className="g-2">
      <Col lg={6}>
        <Form.Label>Card Holder Name</Form.Label>
        <Form.Input
          type="text"
          placeholder=""
          className="billing__input"
        />
      </Col>
      <Col lg={6}>
        <Form.Label>Card Number</Form.Label>
        <Form.Input
          type="text"
          placeholder=""
          className="billing__input"
        />
      </Col>
      <Col lg={6}>
        <Form.Label>Date Expires</Form.Label>
        <Row className="g-2">
          <Col xs={6}>
            <Form.Input
              type="text"
              placeholder=""
              className="billing__input"
            />
          </Col>
          <Col xs={6}>
            <Form.Input
              type="text"
              placeholder=""
              className="billing__input"
            />
          </Col>
        </Row>
      </Col>
      <Col lg={3} md={6}>
        <Form.Label>CVV Number</Form.Label>
        <Form.Input
          type="text"
          placeholder=""
          className="billing__input"
        />
      </Col>
      <Col lg={3} md={6}>
        <Form.Label className="d-none d-md-block">&nbsp;</Form.Label>
        <QuestionWrapper>
          <CreditCardIcon />
          <a href="#">What is CVV?</a>
        </QuestionWrapper>
      </Col>
    </Row>
  );
};

export default PaymentMethod;
