import React from 'react';
import { MessageItem } from 'components';
import { useChatScroll } from 'components/hooks';
import VoicioIcon from 'assets/image/icons/app/voicio.svg';

type Props = {
  channel: any,
}
const MessageList: React.FC<Props> = ({ channel }) => {
  const ref = useChatScroll(channel?.messages || []);

  return (
    <div className="chat__messages-list" ref={ref}>
      {channel?.messages ? (
        <div className="chat__messages-list-body">
          {channel.messages.map(msg => (
            <MessageItem
              key={msg.id}
              Avatar={msg.type === 'voicio' ? VoicioIcon : null}
              left={msg.type === 'voicio'}
              message={msg.text}
              isFeedbackSuggestable={msg.type === 'voicio'}
            />
          ))}
        </div>
      ) : (
        <div className="chat__messages-list-empty">
          {channel ? 'No messages' : 'No channel selected'}
        </div>
      )}
    </div>
  );
};

export default MessageList;
