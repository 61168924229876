import React, { useMemo } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import Channel from './channel';

type Props = {
  channels: Array<any>,
  selectedChannel: any,
  onSelectChannel: (id: string) => void,
}
const ChannelList: React.FC<Props> = ({
  channels = [], selectedChannel, onSelectChannel
}) => {
  const channelList = useMemo(() => {
    return channels.reduce((r, obj) => {
      r[obj.date] = r[obj.date] || [];
      r[obj.date].push(obj);
      return r;
    }, Object.create(null));
  }, [channels]);

  return (
    <div className="chat__channel-list">
      {channels.length > 0 ? (
        <Scrollbars universal autoHide>
          {Object.entries(channelList).map(([key, value]) => (
            <div className="chat__channel-list-item" key={key}>
              <div className="chat__channel-list-date">{key}</div>
              {value.map((c, index) => (
                <Channel
                  key={index}
                  selectedId={selectedChannel?.id}
                  handleClick={onSelectChannel}
                  {...c}
                />
              ))}
            </div>
          ))}
        </Scrollbars>
      ) : (
        <div className="chat__channel-list-empty">No channels</div>
      )}
    </div>
  );
};

export default ChannelList;
