import styled from 'styled-components/macro';

export const BusinessNameWrapper = styled.div`
  padding: 24px 28px;
  text-align: center;

  @media (max-width: 575px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    background: #F3F7FA;
    padding: 65px 26px 56px;
    margin: -5px -30px;
  }

  .business-name {
    &__header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: #FFFFFF;
      padding: 23px 5px 14px;
      z-index: 1;

      &-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        color: #000000;
        margin: 0;
      }
    }

    &__title {
      font-size: 20px;
      line-height: 23px;
      color: #1D1D1D;
      margin-bottom: 12px;

      @media (max-width: 575px) {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 8px;
      }
    }

    &__text {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #000000;
      margin-bottom: 12px;

      @media (max-width: 575px) {
        font-size: 18px;
        line-height: 25px;
        color: #667778;
        margin-bottom: 24px;
      }
    }

    &__action {
      @media (max-width: 575px) {
        position: absolute;
        bottom: 56px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 24px;
        line-height: 24px;
        padding: 16px 42px 13px;
      }
    }
  }
`;

export const ZipCodeWrapper = styled.div`
  padding: 24px 1px;
  text-align: center;

  @media (max-width: 575px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    background: #F3F7FA;
    padding: 65px 26px 56px;
    margin: -5px -30px;
  }

  .business-name {
    &__header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: #FFFFFF;
      padding: 23px 5px 14px;
      z-index: 1;

      &-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        color: #000000;
        margin: 0;
      }
    }

    &__title {
      font-size: 20px;
      line-height: 23px;
      color: #1D1D1D;
      margin-bottom: 12px;

      @media (max-width: 575px) {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 8px;
      }
    }

    &__text {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #000000;
      margin-bottom: 12px;

      @media (max-width: 575px) {
        font-size: 18px;
        line-height: 25px;
        color: #667778;
        margin-bottom: 24px;
      }
    }

    &__action {
      @media (max-width: 575px) {
        position: absolute;
        bottom: 56px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 24px;
        line-height: 24px;
        padding: 16px 42px 13px;
      }
    }
  }
`;

export const GetNumberWrapper = styled.div`
  padding: 18px 7px 0 7px;
  margin-bottom: -15px;

  @media (max-width: 575px) {
    height: 100vh;
    background: #F3F7FA;
    padding: 65px 26px 56px;
    margin: -5px -30px;
    text-align: center;
  }

  .get-number {
    &__header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: #FFFFFF;
      padding: 23px 5px 14px;
      z-index: 1;

      &-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        color: #000000;
        margin: 0;
      }
      &-search {
        position: absolute;
        right: 18px;
        top: 23px;

        svg {
          width: 23px;
          height: 23px;

          path {
            fill: #000;
          }
        }
      }
    }

    &__title {
      font-size: 20px;
      line-height: 23px;
      color: #1D1D1D;
      margin-bottom: 7px;

      @media (max-width: 575px) {
        font-size: 22px;
        line-height: 26px;
        margin-top: 45px;
      }
    }

    &__text {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
      margin-bottom: 8px;

      @media (max-width: 575px) {
        margin-bottom: 22px;
      }
    }

    &__phone-action {
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      text-decoration: underline;
      color: #667778;
      margin-bottom: 21px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      @media (max-width: 575px) {
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        font-size: 17px;
        line-height: 23px;
        margin: 0;
      }
    }

    &__row {
      margin-left: -6px;
      margin-right: -6px;
    }

    &__col {
      padding-left: 6px;
      padding-right: 6px;
    }

    &__search {
      width: 100%;
      height: 42px;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
      border-radius: 5px;

      @media (max-width: 575px) {
        width: 204px;
        height: 55px;
        font-size: 24px;
        line-height: 24px;
        padding: 16px 42px 13px;
        border-radius: 60px;
        margin-top: 28px;
      }
    }

    &__result {
      background: #F3F7FA;
      padding: 25px 29px;
      margin: 0 -29px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      @media (max-width: 575px) {
        margin-left: -18px;
        margin-right: -18px;
        margin-top: 0;
        padding: 29px 0px;
      }

      &-no-result {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-center;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #C1C1C1;

        &-error-icon {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          fill: #EE6352;
        }
        &-message {
          justify-content: flex-start;
          text-align: left;
        }
        &-header {
          font-weight: bold;
          color: #667778;
        }
        @media (max-width: 575px) {
          font-size: 16px;
          line-height: 22px;
        }
      }

      &-content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 10px 10px;
        place-items: center;
        justify-content: center;

        @media (max-width: 767px) {
          grid-template-columns: 1fr 1fr;
          grid-gap: 24px 11px;
        }
      }

      &-item {
        position: relative;
        width: 100%;
        text-align: center;
        background: #FFFFFF;
        border: 1px solid #D5D5D5;
        border-radius: 5px;
        padding: 35px 9px 22px;

        @media (max-width: 767px) {
          padding: 35px 7px 22px;
        }

        &-badge {
          width: 64px;
          height: 64px;
          overflow: hidden;
          position: absolute;
          top: 0;
          left: 0;

          &::before,
          &::after {
            position: absolute;
            z-index: -1;
            content: '';
            display: block;
            border: 5px solid #FFBF00;
            border-top-color: transparent;
            border-left-color: transparent;
          }
          &::before {
            top: 0;
            right: 0;
          }
          &::after {
            bottom: 0;
            left: 0;
          }
          & span {
            position: absolute;
            display: block;
            width: 100px;
            padding: 8px 0 5px;
            background-color: #FFBF00;
            color: #543F00;
            font-weight: 700;
            font-size: 18px;
            line-height: 16px;
            text-align: center;
            text-align: center;
            right: -5px;
            top: 2px;
            transform: rotate(-45deg);
          }
        }
        &-number {
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
          color: #000000;

          @media (max-width: 767px) {
            font-size: 20px;
            line-height: 27px;
          }
        }
        &-state {
          font-family: 300;
          font-size: 18px;
          line-height: 24px;
          color: #000000;
          margin-bottom: 21px;

          @media (max-width: 767px) {
            font-size: 18px;
            line-height: 24px;
          }
        }
      }
    }
  }
`;

export const BusinessHoursWrapper = styled.div`
  padding: 33px 27px 36px 27px;
  margin-bottom: -15px;

  @media (max-width: 575px) {
    height: 100vh;
    background: #F3F7FA;
    padding: 65px 16px 56px;
    margin: -5px -30px;
    text-align: center;
  }

  .business-hours {
    &__header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: #FFFFFF;
      padding: 23px 5px 14px;
      z-index: 1;

      &-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        color: #000000;
        margin: 0;
      }
    }

    &__title {
      font-size: 20px;
      line-height: 23px;
      color: #1D1D1D;
      margin-bottom: 20px;

      @media (max-width: 575px) {
        font-size: 22px;
        line-height: 26px;
        margin-top: 45px;
      }
    }

    &__subtitle {
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      color: #8A9DA4;
      margin-bottom: 14px;
    }

    &__footer {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }
  }
`;

export const DayTimeSelectWrapper = styled.div`
  .day-time-select {
    &__item {
      margin-bottom: 7px;

      @media (max-width: 575px) {
        margin: 0 -6px;

        > * {
          padding: 0 3px;
        }
      }

      &-label {
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        color: #1D1D1D;
      }

      &-select {
        font-size: 15px;
        line-height: 18px;
        padding: 7px 13px 6px;
      }

      &-subitem {
        display: flex;

        &-field {
          margin: 0 4px;
        }
      }
    }
  }
`;
