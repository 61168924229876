import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { usaStates } from 'typed-usa-states';
import { Form } from 'components';
import { formatAddressLine } from 'utils/helper';
import { SectionTitle } from '../style';

type Props = {
  data: any,
  isEditing: boolean,
  values: any,
  errors: any,
  isValidated: boolean,
  handleChange: (event: any) => void,
  handleSelectChange: (key: any, value: any) => void,
}
const Profile: React.FC<Props> = ({
  data,
  isEditing,
  values,
  errors,
  isValidated,
  handleChange,
  handleSelectChange
}) => {
  return (
    <>
      <SectionTitle>Profile</SectionTitle>
      <Row className="g-2">
        {isEditing ? (
          <>
            <Col xl={4} lg={6} md={12}>
              <Form.Label className="customer-profile__label">
                First Name <span className="color-danger">*</span>
              </Form.Label>
              <Form.Input
                type="text"
                placeholder=""
                className="customer-profile__input"
                name="firstName"
                value={values.firstName || ''}
                isValid={isValidated && Boolean(!errors.firstName)}
                isInValid={isValidated && Boolean(errors.firstName)}
                onChange={handleChange}
                required
              />
            </Col>
            <Col xl={4} lg={6} md={12}>
              <Form.Label className="customer-profile__label">
                Last Name <span className="color-danger">*</span>
              </Form.Label>
              <Form.Input
                type="text"
                placeholder=""
                className="customer-profile__input"
                name="lastName"
                value={values.lastName || ''}
                isValid={isValidated && Boolean(!errors.lastName)}
                isInValid={isValidated && Boolean(errors.lastName)}
                onChange={handleChange}
                required
              />
            </Col>
          </>
        ) : (
          <Col xl={4} lg={6} md={12}>
            <Form.Label className="customer-profile__label">Name</Form.Label>
            <Form.Text className="customer-profile__text">
              {`${data.firstName || ''} ${data.lastName || ''}`}
            </Form.Text>
          </Col>
        )}
        <Col xl={4} lg={6} md={12}>
          <Form.Label className="customer-profile__label">
            Phone No. {isEditing && <span className="color-danger">*</span>}
          </Form.Label>
          {isEditing ? (
            <Form.Input
              type="phone"
              placeholder=""
              className="customer-profile__input"
              name="phone"
              value={values.phone || ''}
              isValid={isValidated && Boolean(!errors.phone)}
              isInValid={isValidated && Boolean(errors.phone)}
              onChange={handleChange}
              required
            />
          ) : (
            <Form.Text className="customer-profile__text">
              {data.phone}
            </Form.Text>
          )}
        </Col>
        <Col xl={4} lg={6} md={12}>
          <Form.Label className="customer-profile__label">Business Name</Form.Label>
          {isEditing ? (
            <Form.Input
              type="text"
              placeholder=""
              className="customer-profile__input"
              name="businessName"
              value={values.businessName || ''}
              isValid={isValidated && Boolean(!errors.businessName)}
              isInValid={isValidated && Boolean(errors.businessName)}
              onChange={handleChange}
            />
          ) : (
            <Form.Text className="customer-profile__text">
              {data.businessName}
            </Form.Text>
          )}
        </Col>
        {(!isEditing) && (
          <Col xl={4} lg={6} md={12}>
            <Form.Label className="customer-profile__label">Address</Form.Label>
            <Form.Text className="customer-profile__text">
              {formatAddressLine(data)}
            </Form.Text>
          </Col>
        )}
        <Col xl={4} lg={6} md={12}>
          <Form.Label className="customer-profile__label">
            Email {isEditing && <span className="color-danger">*</span>}
          </Form.Label>
          {isEditing ? (
            <Form.Input
              type="email"
              placeholder=""
              className="customer-profile__input"
              name="email"
              value={values.email || ''}
              isValid={isValidated && Boolean(!errors.email)}
              isInValid={isValidated && Boolean(errors.email)}
              onChange={handleChange}
              required
            />
          ) : (
            <Form.Text className="customer-profile__text">
              {data.email}
            </Form.Text>
          )}
        </Col>
        <Col xl={4} lg={6} md={12}>
          <Form.Label className="customer-profile__label">Website</Form.Label>
          {isEditing ? (
            <Form.Input
              type="website"
              placeholder=""
              className="customer-profile__input"
              name="website"
              value={values.website || ''}
              isValid={isValidated && Boolean(!errors.website)}
              isInValid={isValidated && Boolean(errors.website)}
              onChange={handleChange}
            />
          ) : (
            <Form.Text className="customer-profile__text">
              {data.website}
            </Form.Text>
          )}
        </Col>
        {isEditing && (
          <Col xl={4} md={12}>
            <Form.Label className="customer-profile__label">
              Address <span className="color-danger">*</span>
            </Form.Label>
            <Form.Group className="mb-2">
              <Form.Input
                id="addressLine1"
                name="addressLine1"
                placeholder="ADDRESS LINE1"
                className="customer-profile__input"
                value={values.addressLine1 || ''}
                isValid={isValidated && Boolean(!errors.addressLine1)}
                isInValid={isValidated && Boolean(errors.addressLine1)}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Input
                id="addressLine2"
                name="addressLine2"
                placeholder="ADDRESS LINE2"
                className="customer-profile__input"
                value={values.addressLine2 || ''}
                isValid={isValidated && Boolean(!errors.addressLine2)}
                isInValid={isValidated && Boolean(errors.addressLine2)}
                onChange={handleChange}
              />
            </Form.Group>
            <Row className="customer-profile__row">
              <Col md={7} className="customer-profile__col">
                <Form.Group className="mb-2">
                  <Form.Input
                    id="city"
                    name="city"
                    placeholder="CITY"
                    className="customer-profile__input"
                    value={values.city || ''}
                    isValid={isValidated && Boolean(!errors.city)}
                    isInValid={isValidated && Boolean(errors.city)}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={5} className="customer-profile__col">
                <Form.Group className="mb-2">
                  {values.country === 'United States' ? (
                    <Form.Select
                      name="state"
                      placeholder="STATE"
                      value={values.state || ''}
                      className="customer-profile__select"
                      isValid={isValidated && Boolean(!errors.state)}
                      isInValid={isValidated && Boolean(errors.state)}
                      onChange={(e) => handleSelectChange('state', e.target.value)}
                      required
                    >
                      <option value="" disabled>State</option>
                      {usaStates.map((s, i) => (
                        <option key={i}>{s.name}</option>
                      ))}
                    </Form.Select>
                  ) : (
                    <Form.Input
                      id="state"
                      name="state"
                      placeholder="STATE"
                      className="customer-profile__input"
                      value={values.state || ''}
                      isValid={isValidated && Boolean(!errors.state)}
                      isInValid={isValidated && Boolean(errors.state)}
                      onChange={handleChange}
                      required
                    />
                  )}
                </Form.Group>
              </Col>
              <Col md={5} className="customer-profile__col">
                <Form.Group className="mb-2">
                  <Form.Input
                    id="zipCode"
                    name="zipCode"
                    placeholder="ZIP CODE"
                    className="customer-profile__input"
                    value={values.zipCode || ''}
                    isValid={isValidated && Boolean(!errors.zipCode)}
                    isInValid={isValidated && Boolean(errors.zipCode)}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={7} className="customer-profile__col">
                <Form.Group className="mb-2">
                  <Form.Select
                    id="country"
                    name="country"
                    placeholder="COUNTRY"
                    className="customer-profile__select"
                    value={values.country || ''}
                    isValid={isValidated && Boolean(!errors.country)}
                    isInValid={isValidated && Boolean(errors.country)}
                    onChange={(e) => handleSelectChange('country', e.target.value)}
                    required
                  >
                    <option value="">Country</option>
                    <option>United States</option>
                    <option>Canada</option>
                    <option>Mexico</option>
                    <option>United Kingdom</option>
                    <option>Australia</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Col>
        )}
        <Col xl={8} md={12}>
          <Form.Label className="customer-profile__label">Notes</Form.Label>
          {isEditing ? (
            <Form.Textarea
              type="notes"
              rows={8}
              placeholder=""
              className="customer-profile__input"
              name="notes"
              value={values.notes || ''}
              isValid={isValidated && Boolean(!errors.notes)}
              isInValid={isValidated && Boolean(errors.notes)}
              onChange={handleChange}
            />
          ) : (
            <Form.Text className="customer-profile__text">
              {data.notes}
            </Form.Text>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Profile;
