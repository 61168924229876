import AvatarIcon from 'assets/image/pages/app/user-actions-avatar.png';

export const reviewsData = [
  {
    img: AvatarIcon,
    name: 'Andrew Smith',
    phone: '+19203930489',
    date: '21/06/2021',
    review: 4,
    text: 'Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s, when an unknown printer took a gallery of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.',
  },
  {
    img: AvatarIcon,
    name: 'John Doe',
    phone: '+19203930489',
    date: '19/06/2021',
    review: 5,
    text: 'Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s, when an unknown printer took a gallery of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.',
  },
  {
    img: AvatarIcon,
    name: 'Andrew Smith',
    phone: '+19203930489',
    date: '14/06/2021',
    review: 2,
    text: 'Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s, when an unknown printer took a gallery of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.',
  },
  {
    img: AvatarIcon,
    name: 'Jennier Smith',
    phone: '+19203930489',
    date: '11/06/2021',
    review: 5,
    text: 'Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s, when an unknown printer took a gallery of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.',
  },
  {
    img: AvatarIcon,
    name: 'Andrew Smith',
    phone: '+19203930489',
    date: '05/06/2021',
    review: 4,
    text: 'Lorem Ipsum has been the industry`s standard dummy text ever since the 1500s, when an unknown printer took a gallery of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.',
  },
];
