import React from 'react';
import moment from 'moment';
import TimePicker from 'react-bootstrap-time-picker';
import {
  WorkingHoursWrapper, CalendarDayPeriodWrapper, CalendarDayTimeWrapper
} from './style';

const items = ['Morning', 'Afternoon', 'Evening'];

const CalendarDayPeriod = ({ date }) => (
  <CalendarDayPeriodWrapper>
    <div className="calendar__period-date">{moment(date).format('MMMM D, YYYY')}</div>
    <div className="calendar__period-content">
      {items.map((period, index) => (
        <div className="calendar__period-content-period" key={index}>
          <i className="fa fa-check-circle" />
          {period}
        </div>
      ))}
    </div>
  </CalendarDayPeriodWrapper>
);

const CalendarDayTime = () => (
  <CalendarDayTimeWrapper>
    {items.map((period, index) => (
      <div className="calendar__time-content" key={index}>
        <span className="calendar__time-content-period">{period}</span>
        <TimePicker step={30} className="calendar__time-select" />
        <span className="calendar__time-content-to">To</span>
        <TimePicker step={30} className="calendar__time-select" />
      </div>
    ))}
  </CalendarDayTimeWrapper>
);

const WorkingHours = ({ date }) => {
  return (
    <WorkingHoursWrapper>
      <div className="working-hours__title">Working Hours</div>
      <CalendarDayPeriod date={date} />
      <CalendarDayTime />
    </WorkingHoursWrapper>
  );
};

export default WorkingHours;
