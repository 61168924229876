import DashboardIcon from 'assets/image/icons/onboarding/dashboard.svg';
import AccountIcon from 'assets/image/icons/onboarding/account.svg';
import VirtualReceptionistIcon from 'assets/image/icons/onboarding/virtual-receptionist.svg';
import CallsIcon from 'assets/image/icons/onboarding/calls.svg';
import BookingCalendarIcon from 'assets/image/icons/onboarding/booking-calendar.svg';
import CustomersIcon from 'assets/image/icons/onboarding/customers.svg';
import BillingIcon from 'assets/image/icons/onboarding/billing.svg';
import ReviewsIcon from 'assets/image/icons/onboarding/reviews.svg';

const onboardingData = {
  items: [
    {
      id: '1',
      to: '/dashboard',
      Icon: DashboardIcon,
      title: 'Dashboard',
    },
    {
      id: '2',
      to: '/settings',
      Icon: AccountIcon,
      title: 'My Account',
    },
    {
      id: '3',
      to: '/virtual-receptionist',
      Icon: VirtualReceptionistIcon,
      title: 'Virtual Receptionist',
    },
    {
      id: '4',
      to: '/interactions',
      Icon: CallsIcon,
      title: 'Interactions',
    },
    {
      id: '5',
      to: '/booking-calendar',
      Icon: BookingCalendarIcon,
      title: 'Booking Calendar',
    },
    {
      id: '6',
      to: '/customers',
      Icon: CustomersIcon,
      title: 'My Customers',
    },
    {
      id: '7',
      to: '/billings',
      Icon: BillingIcon,
      title: 'Billing',
    },
    {
      id: '8',
      to: '/reviews',
      Icon: ReviewsIcon,
      title: 'My Reviews',
    },
  ],
};

export default onboardingData;
