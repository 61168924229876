import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import TimePicker from 'react-bootstrap-time-picker';
import { Form, Button, Modal } from 'components';
import { useGoogleMaps } from 'components/hooks';
import { AppointmentAddWrapper } from './style';
import DateIcon from 'assets/image/icons/app/date.svg';

const CalendarAddForm = ({
  initialEvent,
  customerList,
  handleClose,
  onSubmit,
}): React.ReactElement<any,any> => {
  const [eventDetail, updateEventDetail] = useState(initialEvent);
  
  const handleChange = (key) => (e) => {
    updateEventDetail({
      ...eventDetail,
      [key]: e.target.value
    });
  };

  const handleSelectChange = (key) => (value) => {
    updateEventDetail({
      ...eventDetail,
      [key]: value
    });
  };

  const { autoCompleteRef } = useGoogleMaps(handleSelectChange('location'));

  const handleOrganizerChange = (e) => {
    const customerId = e.target.value;
    if (!customerId) return;
    if (customerId === 'goToCustomer') {
      navigate('/customers?editing=true');
    } else {
      const organizer = customerList.find(
        (customer: any) => parseInt(customer.id) === parseInt(customerId)
      );
      if (organizer) {
        updateEventDetail({
          ...eventDetail,
          organizer: {
            id: organizer.id,
            commonName: organizer.name,
            email: organizer.name,
            phone: organizer.phone
          }
        });
      }
    }
  };

  const handleSubmit = () => {
    onSubmit(eventDetail);
  };

  return (
    <Modal
      show={true}
      onHide={handleClose}
      title="Add a New Appointment"
      footer={
        <>
          <Button variant="primary" round onClick={handleSubmit}>
            Save
          </Button>
          <Button variant="link" round onClick={handleClose}>
            Cancel
          </Button>
        </>
      }
    >
      <AppointmentAddWrapper>
        <div className="appointment-add__content">
          <div className="appointment-add__content-title">
            Schedule
          </div>
          <Row className="mb-4">
            <Col md={6} className="mb-2 mb-md-0">
              <div className="appointment-add__content-item">
                <div className="appointment-add__content-item-title">
                  Date
                </div>
                <div className="appointment-add__content-item-content">
                  <DatePicker
                    selected={eventDetail.date}
                    onChange={handleSelectChange('date')}
                  />
                  <DateIcon
                    className="appointment-add__content-item-content-icon"
                  />
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="appointment-add__content-item">
                <div className="appointment-add__content-item-title">
                  Time
                </div>
                <div className="appointment-add__content-item-content">
                  <TimePicker
                    step={30}
                    className="appointment-add__content-select"
                    value={eventDetail.time}
                    onChange={handleSelectChange('time')}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <div className="appointment-add__content-title">
            Details
          </div>
          <div className="appointment-add__content-item">
            <div className="appointment-add__content-item-title">
              Customer
            </div>
            <div className="appointment-add__content-item-content">
              <Form.Select
                className="appointment-add__content-select"
                onChange={handleOrganizerChange}
                value={eventDetail.organizer.id || 'default'}
              >
                <option value="default" disabled hidden>Please Choose</option>
                {customerList.map(customer =>
                  <option key={customer.id} value={customer.id}>
                    {customer.name}
                  </option>
                )}
                {eventDetail.organizer?.id && (
                  <option
                    value={eventDetail.organizer?.id}
                  >
                    {eventDetail.organizer?.name}
                  </option>
                )}
                <option value='goToCustomer'>
                  Add Customer +
                </option>
              </Form.Select>
            </div>
          </div>
          <div className="appointment-add__content-item">
            <div className="appointment-add__content-item-title">
              Address
            </div>
            <div className="appointment-add__content-item-content">
              <Form.Input
                ref={autoCompleteRef}
                name='location'
                className="appointment-add__content-select"
                placeholder="Location"
                value={eventDetail.location}
                onChange={handleChange('location')}
              />
            </div>
          </div>
          <div className="appointment-add__content-item">
            <div className="appointment-add__content-item-title">
              Phone No.
            </div>
            <div className="appointment-add__content-item-content">
              <Form.Input
                className="appointment-add__content-select"
                placeholder="(000) 000-0000"
                value={eventDetail.phone}
                onChange={handleChange('phone')}
              />
            </div>
          </div>
          <div className="appointment-add__content-item mb-4">
            <div className="appointment-add__content-item">
              <div className="appointment-add__content-item-title">
                Service
              </div>
              <div className="appointment-add__content-item-content">
                <Form.Input
                  className="appointment-add__content-select"
                  placeholder="Service"
                  value={eventDetail.summary}
                  onChange={handleChange('summary')}
                />
              </div>
            </div>
          </div>
          <div className="appointment-add__content-title">
            Options
          </div>
          <div className="appointment-add__content-item">
            <div className="appointment-add__content-item-title">
              Reminder
            </div>
            <div className="appointment-add__content-item-content">
              <Form.Select
                className="appointment-add__content-select"
                size="sm"
              >
                <option>1 hour before</option>
              </Form.Select>
            </div>
          </div>
        </div>
      </AppointmentAddWrapper>
    </Modal>
  );
};

export default CalendarAddForm;
