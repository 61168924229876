import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { Router, RouteComponentProps } from '@reach/router';
import { useRecoilValueLoadable, useRecoilState } from 'recoil';
import { Loader } from 'components';
import { useToast } from 'components/hooks';
import { AuthUserState } from 'data/auth';
import { ErrorState } from 'data/error';
import authRoutes from 'data/authRoutes';
import { EErrorTypes } from 'interfaces';
import NotFound from './404';

const RouterPage = (
  props: { Component: React.ReactElement } & RouteComponentProps
) => props.Component;

const AuthGate: React.VFC = (props: any) => {
  const userLoadable = useRecoilValueLoadable(AuthUserState);
  const [errorState, updateErrorState] = useRecoilState(ErrorState);
  const toast = useToast();

  if (userLoadable?.state === 'hasValue') {
    const user = userLoadable.contents;
    if (user.authenticated) {
      if (!user.emailVerified) {
        if (props.location.pathname !== '/verify-email') {
          navigate('/verify-email');
        }
      }
    } else {
      if (!props.location.pathname.includes('verify-token')) {
        navigate('/login');
      }
    }
  }

  useEffect(() => {
    const globalError = errorState[EErrorTypes.Global];
    if (globalError) {
      toast({
        type: 'error',
        title: 'Error!',
        text: globalError.message,
        callback: () => updateErrorState({ [EErrorTypes.Global]: null })
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorState]);

  return (
    <>
      {userLoadable?.state !== 'hasValue' ?
        <Loader fillContainer /> :
        <Router>
          <NotFound default />
          {authRoutes.map(({ name, path, Component }) =>
            <RouterPage Component={<Component />} path={path} key={name} />
          )}
        </Router>
      }
    </>
  );
};

export default AuthGate;
