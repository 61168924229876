export const billingHistoryTableData = [
  {
    date: '21/06/2021 at 12:15 PM',
    invoice_number: 'AT - 9303',
    amount: '$19.90',
    status: 'PAID'
  },
  {
    date: '21/06/2021 at 12:15 PM',
    invoice_number: 'AT - 9303',
    amount: '$19.90',
    status: 'FAILED'
  },
  {
    date: '21/06/2021 at 12:15 PM',
    invoice_number: 'AT - 9303',
    amount: '$19.90',
    status: 'PAID'
  },
  {
    date: '21/06/2021 at 12:15 PM',
    invoice_number: 'AT - 9303',
    amount: '$19.90',
    status: 'PAID'
  },
  {
    date: '21/06/2021 at 12:15 PM',
    invoice_number: 'AT - 9303',
    amount: '$19.90',
    status: 'FAILED'
  },
  {
    date: '21/06/2021 at 12:15 PM',
    invoice_number: 'AT - 9303',
    amount: '$19.90',
    status: 'PAID'
  },
  {
    date: '21/06/2021 at 12:15 PM',
    invoice_number: 'AT - 9303',
    amount: '$19.90',
    status: 'PAID'
  },
  {
    date: '21/06/2021 at 12:15 PM',
    invoice_number: 'AT - 9303',
    amount: '$19.90',
    status: 'PAID'
  },
  {
    date: '21/06/2021 at 12:15 PM',
    invoice_number: 'AT - 9303',
    amount: '$19.90',
    status: 'PAID'
  },
  {
    date: '21/06/2021 at 12:15 PM',
    invoice_number: 'AT - 9303',
    amount: '$19.90',
    status: 'PAID'
  },
];
