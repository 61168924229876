import React from 'react';
import { Button, Modal, Form } from 'components';
import { useIsMobile, useForm } from 'components/hooks';
import { BusinessNameWrapper } from './style';

function validate (values) {
  const errors: any = {};
  if (!values.name) {
    errors.name = 'Business name is required';
  }

  return errors;
};

type Props = {
  show: boolean,
  closeModal: () => void,
  onSubmit: () => void,
}
const BusinessNameModal: React.FC<Props> = ({
  show, closeModal, onSubmit,
}) => {
  const isMobile = useIsMobile();
  const {
    values,
    errors,
    isValidated,
    handleChange,
    handleSubmit,
  } = useForm(onSubmit, validate);

  return (
    <Modal
      className="modal-xs"
      show={show}
      onHide={closeModal}
      backdrop="static"
    >
      <BusinessNameWrapper>
        {isMobile && (
          <div className="business-name__header">
            <h2 className="business-name__header-title">Setting Up</h2>
          </div>
        )}
        <Form noValidate onSubmit={handleSubmit}>
          <div className="business-name__form">
            <h4 className="business-name__title">Welcome to Voicio!</h4>
            <p className="business-name__text">
              {isMobile ? (
                <>
                  Before we can assign your virtual assistant, tell us what is
                  the name of your company? This can be your legal name or a
                  doing-business-as.
                </>
              ) : (
                <>
                  We`re ready to assign you a virtual assistant.
                  <br />
                  What is the name of your business?
                </>
              )}
            </p>
            <Form.Group className="mb-5">
              <Form.Input
                name="name"
                placeholder="Jason Birkett Plumbing"
                className="text-center"
                value={values.name || ''}
                isValid={isValidated && Boolean(!errors.name)}
                isInValid={isValidated && Boolean(errors.name)}
                error={errors.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </div>
          <Button
            className="business-name__action"
            variant="primary"
            round
            onClick={handleSubmit}
          >
            Next
          </Button>
        </Form>
      </BusinessNameWrapper>
    </Modal>
  );
};

export default BusinessNameModal;
