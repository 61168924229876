import React, { useState } from 'react';
import ScheduleSelector from 'react-schedule-selector';
import { Button, Form, ProgressBar } from 'components';
import { useIsMobile } from 'components/hooks';
import { HeaderButton } from './style';
import PhoneIcon from 'assets/image/icons/app/telephone.svg';
import BackIcon from 'assets/image/icons/app/back.svg';
import NextIcon from 'assets/image/icons/app/next.svg';

const Step2 = ({ navigation = {} }) => {
  const [schedules, setSchedules] = useState([]);
  const isMobile = useIsMobile();
  const { previous, next } = navigation;

  const handleChange = (newSchedule): void => {
    setSchedules(newSchedule);
  };

  return (
    <div className="advanced-questions__step mt-5 mb-4">
      <div className="advanced-questions__step-header">
        {isMobile && (
          <HeaderButton onClick={previous}>
            <BackIcon />{'  '}Back
          </HeaderButton>
        )}
        <h2>{isMobile ? 'Business Hours' : 'What are your business hours?'}</h2>
        {isMobile && (
          <HeaderButton onClick={next}>
            Next{'  '}<NextIcon />
          </HeaderButton>
        )}
        {!isMobile && (
          <div className="advanced-questions__step-phone-action">
            <PhoneIcon /> Finish this over the phone
          </div>
        )}
      </div>
      <ProgressBar className="full" steps={5} focus={1} />
      <div className="advanced-questions__step-content">
        <h2 className="advanced-questions__step-subtitle mb-4">
          <span>Business Days & Hours</span>
          <Button
            variant="secondary"
            pt={'6px'}
            pb={'6px'}
            pl={'12px'}
            pr={'12px'}
            sizeX={'174px'}
            sizeY={'29px'}
            round={isMobile}
          >
            {isMobile ? 'Emergency Hours' : 'Paint Emergency Hours'}
          </Button>
        </h2>
        <div className="advanced-questions__step-content-schedule">
          <ScheduleSelector
            selection={schedules}
            numDays={7}
            minTime={8}
            maxTime={21}
            hourlyChunks={1}
            unselectedColor={'#cef0f2'}
            selectedColor={'#42b4c1'}
            hoveredColor={'#60d6e3'}
            dateFormat={isMobile ? 'ddd' : 'dddd'}
            timeFormat={'h:mm a'}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="advanced-questions__step-content mt-2">
        <h2 className="advanced-questions__step-subtitle mb-3">
          CALL OUT FEE
        </h2>
        <div className="advanced-questions__step-content-field mb-2">
          <span>What call out fee do you charge</span>
          <Form.Select className="advanced-questions__step-content-select">
            <option>$0.00</option>
            <option>$10.00</option>
            <option>$20.00</option>
            <option>$30.00</option>
          </Form.Select>
        </div>
        <div className="advanced-questions__step-content-field">
          <span>Emergency multiplier</span>
          <Form.Select className="advanced-questions__step-content-select">
            <option>Time and Half</option>
          </Form.Select>
        </div>
        <div className="advanced-questions__step-content-description">
          Callouts that are deemed emergencies<br/>
          can be billed at a multiplied rate.
        </div>
      </div>
      {isMobile ? (
        <div className="advanced-questions__step-phone-action absolute">
          <PhoneIcon /> Finish this over the phone
        </div>
      ) : (
        <div className="advanced-questions__step-footer">
          <div></div>
          <Button
            large
            onClick={next}
            sizeX={'137px'}
          >
            Next
          </Button>
        </div>
      )}
    </div>
  );
};

export default Step2;
