import styled from 'styled-components/macro';

export const OverviewWrapper = styled.div.attrs(props => ({
  className: `overview ${props.className || ''}`,
}))`
  background-color: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  padding: 18px 34px;

  @media (max-width: 575px) {
    background-color: transparent;
    box-shadow: none;
    padding: 22px 0;
  }

  .overview {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      &-title {
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        color: #1D1D1D;
        margin-bottom: 0;
      }
    }

    &__chart {
      position: relative;
      height: 256px;
    }

    &__footer {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #8A9DA4;
      text-align: right;
      margin-top: 14px;
    }
  }
`;
