import React, { useState } from 'react';
import { Form, Modal, Button } from 'components';

type Props = {
  show: boolean,
  closeModal: () => void,
  customer: any,
}
const ProfileForm: React.FC<Props> = ({ show, closeModal, customer }) => {
  const [editableCustomer, updateCustomer] = useState(customer);

  const handleChange = (key: string) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      updateCustomer({
        ...editableCustomer,
        [key]: e.target.value
      });
    };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <Modal
      show={show}
      onHide={closeModal}
      title="Edit Profile"
      footer={
        <>
          <Button variant="primary" round>
            Save
          </Button>
          <Button variant="link" onClick={closeModal}>
            Close
          </Button>
        </>
      }
    >
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Input
            type="text"
            placeholder="Name"
            value={customer.name}
            onChange={handleChange('name')}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Input
            type="phone"
            placeholder="Phone No."
            className="customer-profile__input"
            value={customer.phone}
            onChange={handleChange('phone')}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Input
            type="email"
            placeholder="Email"
            className="customer-profile__input"
            value={customer.email}
            onChange={handleChange('email')}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Input
            type="address"
            placeholder="Address"
            className="customer-profile__input"
            value={customer.address}
            onChange={handleChange('address')}
            required
          />
        </Form.Group>
      </Form>
    </Modal>
  );
};

export default ProfileForm;
