import React from 'react';
import { Tab } from 'react-bootstrap';
import classnames from 'classnames';
import { Tabs } from 'components';
import { useIsMobile } from 'components/hooks';
import Period from './period';
import { DayWrapper } from './style';

const Setting = () => {
  const isMobile = useIsMobile();

  return (
    <Tabs
      defaultActiveKey="sunday"
      className={classnames({
        'justify-content-center': !isMobile,
      })}
    >
      <Tab eventKey="sunday" title={isMobile ? 'Sun' : 'Sunday'}>
        <DayWrapper>
          <Period title="Morning" checked />
          <Period title="Afternoon" />
          <Period title="Evening" />
          <Period title="All Day" />
          <Period title="Not Available" />
        </DayWrapper>
      </Tab>
      <Tab eventKey="monday" title={isMobile ? 'Mon' : 'Monday'}>
        <DayWrapper>
          <Period title="Morning" />
          <Period title="Afternoon" />
          <Period title="Evening" />
          <Period title="All Day" />
          <Period title="Not Available" />
        </DayWrapper>
      </Tab>
      <Tab eventKey="tuesday" title={isMobile ? 'Tue' : 'Tuesday'}>
        <DayWrapper>
          <Period title="Morning" />
          <Period title="Afternoon" />
          <Period title="Evening" />
          <Period title="All Day" />
          <Period title="Not Available" />
        </DayWrapper>
      </Tab>
      <Tab eventKey="wednesday" title={isMobile ? 'Wed' : 'Wednesday'}>
        <DayWrapper>
          <Period title="Morning" />
          <Period title="Afternoon" />
          <Period title="Evening" />
          <Period title="All Day" />
          <Period title="Not Available" />
        </DayWrapper>
      </Tab>
      <Tab eventKey="thursday" title={isMobile ? 'Thu' : 'Thursday'}>
        <DayWrapper>
          <Period title="Morning" />
          <Period title="Afternoon" />
          <Period title="Evening" />
          <Period title="All Day" />
          <Period title="Not Available" />
        </DayWrapper>
      </Tab>
      <Tab eventKey="friday" title={isMobile ? 'Fri' : 'Friday'}>
        <DayWrapper>
          <Period title="Morning" />
          <Period title="Afternoon" />
          <Period title="Evening" />
          <Period title="All Day" />
          <Period title="Not Available" />
        </DayWrapper>
      </Tab>
      <Tab eventKey="saturday" title={isMobile ? 'Sat' : 'Saturday'}>
        <DayWrapper>
          <Period title="Morning" />
          <Period title="Afternoon" />
          <Period title="Evening" />
          <Period title="All Day" />
          <Period title="Not Available" />
        </DayWrapper>
      </Tab>
    </Tabs>
  );
};

export default Setting;
