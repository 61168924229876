import React, { useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import classnames from 'classnames';

import { Table, Searchbox, Modal, AudioPlayer, Button } from 'components';
import { useIsMobile, useModal } from 'components/hooks';
import CallLogItem from './item';
import { useTableColumns } from './columns';
import { ListWrapper } from 'templates/app/style';
import { LogDetailWrapper } from './style';
import { EQueryKeys } from 'interfaces';

import useConnector from 'components/hooks/useConnector';

const CallLogs: React.VFC<any> = ({ active }) => {
  const [currentLog, updateCurrentLog] = useState(null);
  const isMobile = useIsMobile();
  const {isModalOpen, showModal, hideModal} = useModal();

  const getCustomerLog = (customer) => () => {
    updateCurrentLog(customer);
    showModal();
  };

  const columns = useTableColumns({ getCustomerLog, });
  const { data } = useConnector(EQueryKeys.CallLogs, { active } );

  return (
    <ListWrapper>
      <div className="list__content">
        {isMobile ? (
          <>
            <Searchbox
              className="list__content-filter-input"
              placeholder="Search..."
            />
            <div className="list__content-body">
              <Scrollbars universal autoHide>
                {data.map((item, index) => (
                  <CallLogItem
                    item={item}
                    key={index}
                    getCustomerLog={getCustomerLog}
                  />
                ))}
              </Scrollbars>
            </div>
          </>
        ) : (
          <Table
            title="Call Logs"
            columns={columns}
            data={data}
            filterable
            paginatable
            selectable
            rowsPerPage={11}
          />
        )}
      </div>

      {currentLog && (
        <Modal
          className="modal-mini"
          show={isModalOpen}
          onHide={hideModal}
          title={currentLog.type === 'incoming-call' ? 'Incoming Call' : 'Text Message'}
        >
          <LogDetailWrapper>
            <div className="call-log__avatar">
              <img src={currentLog.img} alt="Avatar" />
            </div>
            <p className="call-log__name">{currentLog.name}</p>
            <p className="call-log__phone">{currentLog.phone}</p>
            {currentLog.audio && (
              <AudioPlayer track={currentLog.audio} />
            )}
            {currentLog.text && (
              <div className="call-log__text">
                <p dangerouslySetInnerHTML={{ __html: currentLog.text }} />
              </div>
            )}
            <Button
              round
              className={classnames('call-log__callback', {
                'callback': currentLog.type === 'incoming-call',
                'reply': currentLog.type === 'email',
              })}
            >
              Call Back
            </Button>
            <div className="call-log__footer">
              <span>{currentLog.date}</span>
              <span className="call-log__delete">Delete</span>
            </div>
          </LogDetailWrapper>
        </Modal>
      )}
    </ListWrapper>
  );
};

export default CallLogs;
