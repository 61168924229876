import React, { useState } from 'react';
import { navigate } from 'gatsby';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import TimePicker from 'react-bootstrap-time-picker';
import { Form, Button, SideModal } from 'components';
import { useModal } from 'components/hooks';
import QuickMessage from './quickMessage';
import { AppointmentEditWrapper } from './style';
import TimeIcon from 'assets/image/icons/app/time.svg';
import PencilIcon from 'assets/image/icons/app/pencil.svg';
import CheckIcon from 'assets/image/icons/app/check.svg';
import SendIcon from 'assets/image/icons/app/send.svg';
import UserIcon from 'assets/image/icons/app/user.svg';
import PhoneIcon from 'assets/image/icons/app/phone.svg';
import LocationIcon from 'assets/image/icons/app/location.svg';
import ServiceIcon from 'assets/image/icons/app/service.svg';
import DateIcon from 'assets/image/icons/app/date.svg';

const CalendarEditForm = ({
  initialEvent,
  customerList,
  handleClose,
  onSubmit,
  onDelete,
}): React.ReactElement<any,any> => {
  const [eventDetail, updateEventDetail] = useState(initialEvent);
  const [isDateEditing, updateDateEditing] = useState(false);
  const [isEditing, updateEditing] = useState(false);
  const { isModalOpen, showModal, hideModal } = useModal();

  const handleChange = (key) => (e) => {
    updateEventDetail({
      ...eventDetail,
      [key]: e.target.value
    });
  };

  const handleOrganizerChange = (e) => {
    const customerId = e.target.value;
    if (!customerId) return;
    if (customerId === 'goToCustomer') {
      navigate('/customers?editing=true');
    } else {
      const organizer = customerList.find(
        (customer: any) => parseInt(customer.id) === parseInt(customerId)
      );
      if (organizer) {
        updateEventDetail({
          ...eventDetail,
          organizer: {
            id: organizer.id,
            commonName: organizer.name,
            email: organizer.name,
            phone: organizer.phone
          }
        });
      }
    }
  };

  const handleSubmit = () => {
    updateDateEditing(false);
    updateEditing(false);
    onSubmit(eventDetail);
  };

  const handleDelete = () => {
    updateDateEditing(false);
    onDelete(eventDetail);
    updateEditing(false);
  };

  const sendMessage = () => {
    showModal();
  };

  return (
    <>
      <SideModal
        right
        show={true}
        onHide={handleClose}
        title="Appointment"
        footer={
          <>
            <div className="d-flex w-100">
              <Button
                variant="primary"
                className="mb-1"
                round
                onClick={sendMessage}
              >
                Send a Message
              </Button>
              <Button
                variant="default"
                className="mb-1 ms-2"
                round
                style={{ width: 40, paddingLeft: 11, paddingRight: 11 }}
              >
                <SendIcon />
              </Button>
            </div>
            <hr className="dashed mt-4 mb-4" />
            <Button variant="primary" round onClick={handleSubmit}>
              Save
            </Button>
            <Button variant="link" round onClick={handleDelete}>
              Delete
            </Button>
          </>
        }
      >
        <AppointmentEditWrapper>
          {isDateEditing ? (
            <div className="appointment-edit__datetime">
              <div className="appointment-edit__datetime-body">
                <div className="appointment-edit__datetime-body-datepicker mb-1">
                  <DatePicker
                    selected={eventDetail.date}
                    onChange={handleChange('date')}
                  />
                  <DateIcon
                    className="appointment-edit__datetime-body-datepicker-icon"
                  />
                </div>
                <TimePicker
                  step={30}
                  className="appointment-edit__content-select"
                  value={eventDetail.time}
                  onChange={handleChange('time')}
                />
              </div>
              <div
                className="appointment-edit__datetime-action save"
                onClick={() => updateDateEditing(false)}
              >
                <CheckIcon />
              </div>
            </div>
          ) : (
            <div className="appointment-edit__datetime">
              <div className="appointment-edit__datetime-body">
                <div className="appointment-edit__datetime-body-date">
                  {moment(eventDetail.start).format('dddd, MMMM Do YYYY')}
                </div>
                <div className="appointment-edit__datetime-body-time">
                  <TimeIcon />
                  {` ${moment(eventDetail.start).format('hh:MM:A')} -
                  ${moment(eventDetail.end).format('hh:MM:A')}`
                  }
                </div>
              </div>
              <div
                className="appointment-edit__datetime-action"
                onClick={() => updateDateEditing(true)}
              >
                <PencilIcon />
              </div>
            </div>
          )}
          <div className="appointment-edit__content">
            <div className="appointment-edit__content-title">
              Details
            </div>
            {isEditing ? (
              <>
                <div className="appointment-edit__content-item">
                  <div className="appointment-edit__content-item-icon">
                    <UserIcon />
                  </div>
                  <div className="appointment-edit__content-item-body">
                    <div className="appointment-edit__content-item-body-title">
                      Customer
                    </div>
                    <div className="appointment-edit__content-item-body-content">
                      <Form.Select
                        className="appointment-edit__content-select"
                        onChange={handleOrganizerChange}
                        value={eventDetail.organizer.id || 'default'}
                      >
                        <option value="default" disabled hidden>Please Choose</option>
                        {customerList.map(customer =>
                          <option key={customer.id} value={customer.id}>
                            {customer.name}
                          </option>
                        )}
                        {eventDetail.organizer?.id && (
                          <option
                            value={eventDetail.organizer?.id}
                          >
                            {eventDetail.organizer?.name}
                          </option>
                        )}
                        <option value='goToCustomer'>
                          Add Customer +
                        </option>
                      </Form.Select>
                    </div>
                  </div>
                </div>
                <div className="appointment-edit__content-item">
                  <div className="appointment-edit__content-item-icon">
                    <LocationIcon />
                  </div>
                  <div className="appointment-edit__content-item-body">
                    <div className="appointment-edit__content-item-body-title">
                      Address
                    </div>
                    <div className="appointment-edit__content-item-body-content">
                      <Form.Textarea
                        className="appointment-edit__content-note"
                        placeholder="Location"
                        value={eventDetail.location}
                        onChange={handleChange('location')}
                      />
                    </div>
                  </div>
                </div>
                <div className="appointment-edit__content-item">
                  <div className="appointment-edit__content-item-icon">
                    <ServiceIcon />
                  </div>
                  <div className="appointment-edit__content-item-body">
                    <div className="appointment-edit__content-item-body-title">
                      Service
                    </div>
                    <div className="appointment-edit__content-item-body-content">
                      <Form.Input
                        className="appointment-edit__content-select"
                        placeholder="Service"
                        value={eventDetail.summary}
                        onChange={handleChange('summary')}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  className="appointment-edit__content-action"
                  onClick={() => updateEditing(true)}
                >
                  <PencilIcon />
                </div>
                <div className="appointment-edit__content-item">
                  <div className="appointment-edit__content-item-icon">
                    <UserIcon />
                  </div>
                  <div className="appointment-edit__content-item-body">
                    <div className="appointment-edit__content-item-body-title">
                      Name
                    </div>
                    <div className="appointment-edit__content-item-body-content">
                      {eventDetail.organizer?.name}
                    </div>
                  </div>
                </div>
                <div className="appointment-edit__content-item">
                  <div className="appointment-edit__content-item-icon">
                    <PhoneIcon />
                  </div>
                  <div className="appointment-edit__content-item-body">
                    <div className="appointment-edit__content-item-body-title">
                      Phone
                    </div>
                    <div className="appointment-edit__content-item-body-content">
                      {eventDetail.organizer?.phone}
                    </div>
                  </div>
                </div>
                <div className="appointment-edit__content-item">
                  <div className="appointment-edit__content-item-icon">
                    <LocationIcon />
                  </div>
                  <div className="appointment-edit__content-item-body">
                    <div className="appointment-edit__content-item-body-title">
                      Address
                    </div>
                    <div className="appointment-edit__content-item-body-content">
                      {eventDetail.location}
                    </div>
                  </div>
                </div>
                <div className="appointment-edit__content-item">
                  <div className="appointment-edit__content-item-icon">
                    <ServiceIcon />
                  </div>
                  <div className="appointment-edit__content-item-body">
                    <div className="appointment-edit__content-item-body-title">
                      Service
                    </div>
                    <div className="appointment-edit__content-item-body-content">
                      {eventDetail.summary}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="appointment-edit__content">
            <div className="appointment-edit__content-title">
              Options
            </div>
            <div className="appointment-edit__content-option">
              <div className="appointment-edit__content-option-title">
                Reminder
              </div>
              <div className="appointment-edit__content-option-content">
                <Form.Select
                  className="appointment-edit__content-select"
                  size="sm"
                >
                  <option>1 hour before</option>
                </Form.Select>
              </div>
            </div>
            <div className="appointment-edit__content-option">
              <div className="appointment-edit__content-option-title">
                Note
              </div>
              <div className="appointment-edit__content-option-content">
                <Form.Textarea
                  className="appointment-edit__content-note"
                  size="sm"
                >
                </Form.Textarea>
              </div>
            </div>
          </div>
        </AppointmentEditWrapper>
      </SideModal>

      {isModalOpen && (
        <QuickMessage handleClose={hideModal} />
      )}
    </>
  );
};

export default CalendarEditForm;
