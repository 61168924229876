import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link, BrandLogo } from 'components';
import Footer from './style';
// import GooglePlay from 'assets/image/pages/footer/google-play.png';
// import AppStore from 'assets/image/pages/footer/app-store.png';

// const socialLinks = (
//   <Footer.SocialShare>
//     <Footer.SocialShareItem>
//       <a href="#">
//         <i className="fab fa-facebook-f" />
//       </a>
//     </Footer.SocialShareItem>
//     <Footer.SocialShareItem>
//       <a href="#">
//         <i className="fab fa-twitter" />
//       </a>
//     </Footer.SocialShareItem>
//     <Footer.SocialShareItem>
//       <a href="#">
//         <i className="fab fa-instagram" />
//       </a>
//     </Footer.SocialShareItem>
//     <Footer.SocialShareItem>
//       <a href="#">
//         <i className="fab fa-linkedin" />
//       </a>
//     </Footer.SocialShareItem>
//   </Footer.SocialShare>
// );

const FooterSection: React.VFC = () => {
  return (
    <Footer>
      <Row>
        <Col md={12} lg={3}>
          <Footer.Logo>
            <Link to={'/'}>
              <BrandLogo className="footer-logo" />
            </Link>
          </Footer.Logo>
          {/* <div className="d-desktop">
            {socialLinks}
          </div> */}
        </Col>
        {/* <Col md={12} lg={9}>
          <Row>
            <Col lg={3}>
              <Footer.Block>
                <h5>About Voicio</h5>
                <ul>
                  <li>
                    <Link to="/" tag="a">
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/" tag="a">
                      Careers at Voicio
                    </Link>
                  </li>
                  <li>
                    <Link to="/" tag="a">
                      Customer Support
                    </Link>
                  </li>
                </ul>
              </Footer.Block>
            </Col>
            <Col lg={3}>
              <Footer.Block>
                <h5>For Business</h5>
                <ul>
                  <li>
                    <Link to="/" tag="a">
                      For Partners
                    </Link>
                  </li>
                  <li>
                    <Link to="/" tag="a">
                      Pricing
                    </Link>
                  </li>
                  <li>
                    <Link to="/" tag="a">
                      Support for Partners
                    </Link>
                  </li>
                </ul>
              </Footer.Block>
            </Col>
            <Col lg={3}>
              <Footer.Block>
                <h5>Legal</h5>
                <ul>
                  <li>
                    <Link to="/" tag="a">
                      Booking Terms
                    </Link>
                  </li>
                  <li>
                    <Link to="/" tag="a">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/" tag="a">
                      Website Terms
                    </Link>
                  </li>
                </ul>
              </Footer.Block>
            </Col>
            <Col lg={3}>
              <Footer.Block>
                <h5>Download</h5>
                <ul>
                  <li>
                    <a href="#">
                      <img src={GooglePlay} />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={AppStore} />
                    </a>
                  </li>
                </ul>
              </Footer.Block>
            </Col>
          </Row>
        </Col> */}
      </Row>
      {/* <div className="d-mobile">
        {socialLinks}
      </div>
       */}
      <Row>
        <Col>
          <Footer.CopyRight>
            {/* <span className="language">
              <i className="fas fa-globe me-2" />
              English
            </span> */}
            <span>
              © {(new Date().getFullYear())} Voicio. All Rights Reserved.
            </span>
          </Footer.CopyRight>
        </Col>
      </Row>
    </Footer>
  );
};

export default FooterSection;
