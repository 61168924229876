import styled from 'styled-components/macro';

export const BillingWrapper = styled.div.attrs(props => ({
  className: `billing-wrapper ${props.className || ''}`,
}))`
  padding: 21px 36px 32px 36px;

  @media (max-width: 575px) {
    transition: all 0.35s;
    padding: 26px 24px;
    height: 100%;
  }

  .billing {
    &__input, &__select {
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: #000000;
      padding: 8px 13px 7px 13px;
      margin-bottom: 8px;
      box-shadow: none;

      &:focus {
        box-shadow: 0px 1px 3px rgba(0, 115, 123, 0.25);
      }

      @media (max-width: 575px) {
        font-size: 19px;
        line-height: 22px;
        padding: 14px 17px 12px 17px;
      }
    }
    &__label {
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      color: #1D1D1D;
    }
  }

  &.billing-billingHistory {
    padding: 0;
  }
`;

export const QuestionWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #8A9DA4;
  margin-top: 12px;

  a, a:hover {
    color: #8A9DA4;
    text-decoration: underline;
    margin-left: 10px;

    &:hover {
      opacity: 0.8;
    }
  }
`;

export const PlanWrapper = styled.div.attrs(props => ({
  className: `plan-wrapper ${props.className || ''}`,
}))`
  position: relative;
  background-color: #F3F7FA;
  border-radius: 8px;
  padding: 17px 30px 17px 35px;

  @media (max-width: 575px) {
    background-color: #E2ECF3;
    padding: 21px 27px;
  }

  .plan-content {
    &__action {
      position: absolute;
      top: -20px;
      right: 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      text-align: right;
      text-decoration: underline;
      color: #04B3C0;

      &:hover {
        opacity: 0.8;
      }

      @media (max-width: 575px) {
        display: none;
      }
    }
    &__title {
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      color: #04B3C0;
      margin-bottom: 5px;

      @media (max-width: 575px) {
        font-size: 22px;
        line-height: 27px;
      }
    }
    &__description {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #667778;

      @media (max-width: 575px) {
        font-size: 15px;
        line-height: 18px;
        color: #000000;
      }
    }
  }
`;

export const CardWrapper = styled.div.attrs(props => ({
  className: `card-wrapper ${props.className || ''}`,
}))`
  display: flex;
  align-items: center;
  background-color: #F3F7FA;
  border-radius: 8px;
  padding: 25px 20px 25px 21px;
  margin-top: 15px;

  @media (max-width: 575px) {
    margin-top: 11px;
    margin-bottom: 16px;
    background-color: #E2ECF3;
    padding: 22px 26px;
  }

  .card-content {
    margin-left: 24px;

    &__number {
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 1.5px;
      color: #000000;
      margin-bottom: 8px;

      @media (max-width: 575px) {
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: 1.8px;
        margin-bottom: 2px;
      }
    }
    &__name {
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
      margin-bottom: 4px;

      @media (max-width: 575px) {
        font-size: 18px;
        line-height: 21px;
        margin-bottom: 2px;
      }
    }
    &__date {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #667778;

      @media (max-width: 575px) {
        font-size: 15px;
        line-height: 18px;
      }
    }
  }
`;
