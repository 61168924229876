import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { Avatar } from './components';
import { TextWrapper } from './style';
import { ItemWrapper } from 'templates/app/style';

type TProps = {
  setTab: (props: string) => void,
  deactivateAccount: () => void,
}
const Tabs: React.FC<TProps> = ({ setTab, deactivateAccount }) => {
  return (
    <>
      <Avatar />
      <ItemWrapper onClick={() => setTab('personal')}>
        <span>Edit Profile</span>
        <i className="fa fa-chevron-right" />
      </ItemWrapper>
      <ItemWrapper onClick={() => setTab('company')}>
        <span>Company Information</span>
        <i className="fa fa-chevron-right" />
      </ItemWrapper>
      <ItemWrapper onClick={() => setTab('password')}>
        <span>Change Password</span>
        <i className="fa fa-chevron-right" />
      </ItemWrapper>
      <Row className="mt-5 ps-1 pe-1">
        <Col xs={12} className="d-inline-flex justify-content-between align-items-center">
          <span className="account-setting__label">Deactivate Account</span>
          <Form.Switch
            className="me-1"
            type="switch"
            checked={true}
            onChange={deactivateAccount}
          />
        </Col>
        <Col xs={12} className="mt-2">
          <TextWrapper>
            Keep in mind tha when you delete your <b>Voicio account</b>, all
            data will also deleted, including your customers.
          </TextWrapper>
        </Col>
      </Row>
    </>
  );
};

export default Tabs;
