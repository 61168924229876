import React from 'react';
import { Button } from 'components';
import { useIsMobile } from 'components/hooks';
import { AvatarWrapper } from '../style';
import AvatarIcon from 'assets/image/pages/app/avatar.png';
import PencilIcon from 'assets/image/icons/app/pencil.svg';
import SupportIcon from 'assets/image/icons/app/support.svg';
import MessageIcon from 'assets/image/icons/app/chat.svg';
import SendIcon from 'assets/image/icons/app/send.svg';
import TrashIcon from 'assets/image/icons/app/trash.svg';

type Props = {
  isEditing: boolean
  updateEditing: (props: boolean) => void,
  handleSubmit: (event: any) => void,
  initiate: () => void,
}
const Avatar: React.FC<Props> = ({
  isEditing,
  updateEditing,
  handleSubmit,
  initiate,
}) => {
  const isMobile = useIsMobile();

  const initiateForm = () => {
    updateEditing(false);
    initiate();
  };

  return (
    <AvatarWrapper>
      <div className="customer-avatar__avatar">
        <img src={AvatarIcon} alt="Avatar" />
        <div className="customer-avatar__status online"></div>
      </div>
      {!isMobile && (
        <>
          {isEditing ? (
            <>
              <Button
                className="customer-avatar__save"
                onClick={handleSubmit}
              >
                Save
              </Button>
              <Button
                variant="danger"
                className="customer-avatar__save delete"
                onClick={handleSubmit}
              >
                <TrashIcon className="customer-avatar__save-icon" /> Delete
              </Button>
              <Button
                variant="link"
                className="customer-avatar__save"
                onClick={initiateForm}
              >
                Cancel
              </Button>
            </>
          ) : (
            <>
              <div
                className="customer-avatar__action"
                onClick={() => updateEditing(true)}
              >
                <PencilIcon />&nbsp;&nbsp;Edit Profile
              </div>
              <div className="customer-avatar__action">
                <SupportIcon />&nbsp;&nbsp;Call Customer
              </div>
              <div className="customer-avatar__action">
                <MessageIcon />&nbsp;&nbsp;Text Customer
              </div>
              <div className="customer-avatar__action">
                <SendIcon />&nbsp;&nbsp;Directions
              </div>
              <div className="customer-avatar__action danger">
                <TrashIcon />&nbsp;&nbsp;Delete
              </div>
            </>
          )}
        </>
      )}
    </AvatarWrapper>
  );
};

export default Avatar;
