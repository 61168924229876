import React, { useMemo } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Table } from 'components';
import { useIsMobile } from 'components/hooks';
import { billingHistoryTableData } from 'data/pages/app/billing';
import { ListWrapper } from 'templates/app/style';
import BillingHistoryItem from './billingHistoryItem';

const useTableColumns = () => {
  return useMemo(() => {
    const columns = [
      {
        Header: 'Date',
        accessor: 'date'
      },
      {
        Header: 'Invoice Number',
        accessor: 'invoice_number',
      },
      {
        Header: 'Amount',
        accessor: 'amount',
      },
      {
        Header: '',
        Cell: () => {
          return (
            <a href="#" className="text-decoration-underline">PDF</a>
          );
        },
        accessor: 'action',
        disableSortBy: true,
      },
    ];
    return columns;
  }, []);
};

const BillingHistory = () => {
  const columns = useTableColumns();
  const isMobile = useIsMobile();

  return (
    <ListWrapper>
      <div className="list__content">
        {isMobile ? (
          <div className="list__content-body no-searchbar">
            <Scrollbars universal autoHide>
              {billingHistoryTableData.map((data, index) => (
                <BillingHistoryItem item={data} key={index} />
              ))}
            </Scrollbars>
          </div>
        ) : (
          <Table
            columns={columns}
            data={billingHistoryTableData}
            paginatable
            rowsPerPage={5}
          />
        )}
      </div>
    </ListWrapper>
  );
};

export default BillingHistory;
