import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';

import { useIsMobile } from 'components/hooks';
import { Table, Modal, Button, Searchbox } from 'components';
import AIVoicesItem from './item';
import { useTableColumns } from './columns';
import { ListWrapper } from 'templates/app/style';
import { aiVoicesTableData } from 'data/pages/app/virtualReceptionist';

type TProps = {
  showNewModal: boolean,
  closeNewModal: () => void,
}
const AIVoices: React.FC<TProps> = ({
  showNewModal,
  closeNewModal,
}) => {
  const columns = useTableColumns();
  const [shouldShow, setShouldShow] = useState(showNewModal);
  const isMobile = useIsMobile();

  const handleClose = (): void => {
    closeNewModal();
    setShouldShow(false);
  };

  useEffect(() => {
    setShouldShow(showNewModal);
  }, [showNewModal]);

  return (
    <ListWrapper>
      <div className="list__content">
        {isMobile ? (
          <>
            <Searchbox
              className="list__content-filter-input"
              placeholder="Search..."
            />
            <div className="list__content-body">
              <Scrollbars universal autoHide>
                {aiVoicesTableData.map((data, index) => (
                  <AIVoicesItem item={data} key={index} />
                ))}
              </Scrollbars>
            </div>
          </>
        ) : (
          <Table
            title="A.I. Voices"
            columns={columns}
            data={aiVoicesTableData}
            filterable
            paginatable
            selectable
            rowsPerPage={11}
          />
        )}
      </div>

      <Modal
        show={shouldShow}
        onHide={handleClose}
        title="A.I. Voices"
        footer={
          <>
            <Button variant="primary" round onClick={handleClose}>
              Save
            </Button>
            <Button variant="link" onClick={handleClose}>
              Close
            </Button>
          </>
        }
      >
        <Form>
          <Form.Group>
            <Form.Label>Your Message</Form.Label>
            <Form.Control as="textarea" rows={3} />
            <Form.Text>
              0 of 150
            </Form.Text>
          </Form.Group>
        </Form>
      </Modal>
    </ListWrapper>
  );
};

export default AIVoices;
