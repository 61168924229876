import React from 'react';
import moment from 'moment';
import { useIsMobile } from 'components/hooks';
import { HeaderWrapper } from './style';
import HandIcon from 'assets/image/icons/onboarding/hand.svg';
import CallsIcon from 'assets/image/icons/sidebar/calls.svg';

const Header: React.VFC<any> = ({ user, phoneNumber, ...props }) => {
  const isMobile = useIsMobile();

  const getGreeting = () => {
    const currentHour = moment().hour();
    if (currentHour >= 3 && currentHour < 12) {
      return 'Good Morning';
    } else if (currentHour >= 12 && currentHour < 15) {
      return 'Good Afternoon';
    } else if (currentHour >= 16 && currentHour < 3) {
      return 'Good Evening';
    } else {
      return 'Good Evening';
    }
  };

  return (
    <HeaderWrapper className="mb-4" {...props}>
      <div className="d-flex align-items-center">
        {!isMobile && <img src={user.avatar} alt="Avatar" />}
        {isMobile ? (
          <div>
            <h3>
              Hello {user.first_name}
              <HandIcon />
            </h3>
            <p>Welcome Back!</p>
          </div>
        ) : (
          <div>
            <h3>
              {getGreeting()}, <span>{user.first_name}</span>{' '}
              <HandIcon />
            </h3>
            <p><CallsIcon className="me-2" />{phoneNumber}</p>
          </div>
        )}
      </div>
    </HeaderWrapper>
  );
};

export default Header;
