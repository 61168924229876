import React, { useState } from 'react';
import Calender from 'react-calendar';
import WorkingHours from './workingHours';
import { SettingWrapper } from './style';

const AdvancedSetting = () => {
  const [value, onChange] = useState(new Date());

  return (
    <SettingWrapper>
      <Calender
        onChange={onChange}
        value={value}
        calendarType={'US'}
        prevLabel={<i className="fa fa-long-arrow-alt-left" />}
        nextLabel={<i className="fa fa-long-arrow-alt-right" />}
      />
      <WorkingHours date={value} />
    </SettingWrapper>
  );
};

export default AdvancedSetting;
