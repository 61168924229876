import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Rating, Button } from 'components';
import { useIsMobile } from 'components/hooks';
import { ListItemWrapper } from './style';
import ReplyIcon from 'assets/image/icons/app/reply.svg';

type Props = {
  item: any,
}
const ReviewItem: React.FC<Props> = ({ item, ...props }) => {
  const { img, name, phone, date, review, text } = item;
  const isMobile = useIsMobile();

  return (
    <ListItemWrapper {...props}>
      <Row>
        <Col lg={3}>
          <div className="d-flex align-items-center">
            <div className="me-2 avatar-wrapper online">
              <img src={img} alt={name} />
            </div>
            <div>
              <h3>{name}</h3>
              <span>{phone}</span>
            </div>
            {isMobile && (
              <div className="icon-wrapper">
                <ReplyIcon />
              </div>
            )}
          </div>
        </Col>
        <Col lg={7}>
          <div className="info-wrapper">
            <Rating star={review} />
            <small className="ms-2">{date}</small>
          </div>
          <p>{text}</p>
        </Col>
        {!isMobile && (
          <Col lg={2} className="text-end">
            <Button variant="primary" round>
              Reply
            </Button>
          </Col>
        )}
      </Row>
    </ListItemWrapper>
  );
};

export default ReviewItem;
