import React, { useState } from 'react';
import { useIsDesktop } from 'components/hooks';
import ChannelsPanel from './components/channelsPanel';
import MessagesPanel from './components/messagesPanel';
import { ChatWrapper } from './style';
import { channelsData } from 'data/pages/app/chat';

const Chat: React.VFC = ({ ...props }) => {
  const [channels, setChannels] = useState(channelsData);
  const [channel, setChannel] = useState(null);
  const isDesktop = useIsDesktop();

  const handleChannelSelect = (id: string): void => {
    const newChannel = channels.find(c => c.id === id);
    setChannel(newChannel);
  };

  const handleChannelSearch = (str: string): void => {
    const newChannels = str
      ? channels.filter(c => c.name.toLowerCase().includes(str.toLowerCase()))
      : channelsData;
    setChannels(newChannels);
  };

  const handleSendMessage = (channelId: string, text: string): void => {
    console.log(channelId, text);
  };

  return (
    <ChatWrapper {...props}>
      {(!isDesktop || isDesktop && !channel) && (
        <ChannelsPanel
          channels={channels}
          selectedChannel={channel}
          onSelectChannel={handleChannelSelect}
          onSearchChannel={handleChannelSearch}
        />
      )}
      {(!isDesktop || isDesktop && channel) && (
        <MessagesPanel
          onSendMessage={handleSendMessage}
          onClearChannel={() => setChannel(null)}
          channel={channel}
        />
      )}
    </ChatWrapper>
  );
};

export default Chat;
