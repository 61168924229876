import React from 'react';
import { Button, ProgressBar } from 'components';
import { useIsMobile } from 'components/hooks';
import { HeaderButton } from './style';
import { BillingInfo, PaymentMethod } from 'templates/app/billing/components';
import PhoneIcon from 'assets/image/icons/app/telephone.svg';
import BackIcon from 'assets/image/icons/app/back.svg';

const Step6 = ({ navigation = {}, closeModal }) => {
  const isMobile = useIsMobile();
  const { previous } = navigation;

  return (
    <div className="advanced-questions__step mt-5 mb-4">
      <div className="advanced-questions__step-header">
        {isMobile && (
          <HeaderButton onClick={previous}>
            <BackIcon />{'  '}Back
          </HeaderButton>
        )}
        <h2>{isMobile ? 'Billing' : 'Billing Information'}</h2>
        {isMobile && (
          <HeaderButton className="primary" onClick={closeModal}>
            Save
          </HeaderButton>
        )}
        {!isMobile && (
          <div className="advanced-questions__step-phone-action">
            <PhoneIcon /> Finish this over the phone
          </div>
        )}
      </div>
      <ProgressBar className="full" steps={5} focus={5} />
      <div className="advanced-questions__step-content mb-4">
        <h2 className="advanced-questions__step-subtitle mb-4">
          Billing Info
        </h2>
        <div className="ps-3 pe-3 ps-sm-2 pe-sm-2">
          <BillingInfo />
        </div>
      </div>
      <div className="advanced-questions__step-content">
        <h2 className="advanced-questions__step-subtitle mb-4">
          Payment Method
        </h2>
        <div className="ps-3 pe-3 ps-sm-2 pe-sm-2">
          <PaymentMethod />
        </div>
      </div>
      {isMobile ? (
        <div className="advanced-questions__step-phone-action absolute">
          <PhoneIcon /> Finish this over the phone
        </div>
      ) : (
        <div className="advanced-questions__step-footer mt-5">
          <span
            className="advanced-questions__step-footer-back"
            onClick={previous}
          >
            Back
          </span>
          <Button
            large
            onClick={closeModal}
            sizeX={'137px'}
          >
            Finish
          </Button>
        </div>
      )}
    </div>
  );
};

export default Step6;
