import React, { useState, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Table, Modal, Button, Searchbox } from 'components';
import { useIsMobile, useRecorder } from 'components/hooks';
import PersonalGreetingItem from './item';
import { useTableColumns } from './columns';
import { ListWrapper } from 'templates/app/style';
import { RecorderWrapper } from './style';
import useConnector from 'components/hooks/useConnector';
import { EQueryKeys } from 'interfaces';

import RecordIcon from 'assets/image/icons/app/record.svg';
import StopIcon from 'assets/image/icons/app/stop.svg';
import PauseIcon from 'assets/image/icons/app/pause.svg';
import ResumeIcon from 'assets/image/icons/app/resume.svg';

type TProps = {
  showNewModal: boolean,
  closeNewModal: () => void,
  active: boolean
}
const PersonalGreeting: React.FC<TProps> = ({
  showNewModal,
  closeNewModal,
  active = false
}) => {
  const columns = useTableColumns();
  const [shouldShow, setShouldShow] = useState(showNewModal);
  const isMobile = useIsMobile();
  const { data } = useConnector(EQueryKeys.Greetings,
    { active }
  );
  const {
    recorderState,
    startRecording,
    cancelRecording,
    pauseRecording,
    resumeRecording,
    saveRecording
  } = useRecorder();

  useEffect(() => {
    setShouldShow(showNewModal);
  }, [showNewModal]);

  const handleClose = (): void => {
    closeNewModal();
    setShouldShow(false);
  };

  const checkTime = (i: number): string => {
    return (i < 10) ? `0${i}` : `${i}`;
  };

  return (
    <ListWrapper>
      <div className="list__content">
        {isMobile ? (
          <>
            <Searchbox
              className="list__content-filter-input"
              placeholder="Search..."
            />
            <div className="list__content-body">
              <Scrollbars universal autoHide>
                {data.map((item, index) => (
                  <PersonalGreetingItem item={item} key={index} />
                ))}
              </Scrollbars>
            </div>
          </>
        ) : (
          <Table
            title="Personal Greeting"
            columns={columns}
            data={data}
            filterable
            paginatable
            selectable
            rowsPerPage={11}
          />
        )}
      </div>

      <Modal
        className="modal-mini"
        show={shouldShow}
        onHide={handleClose}
        title="New Personal Greeting"
      >
        <RecorderWrapper>
          {recorderState.audio && (
            <div className="recorder__top-actions">
              <Button variant="primary" round>
                Upload
              </Button>
              <Button variant="default" round onClick={cancelRecording}>
                Clear
              </Button>
            </div>
          )}
          <div className="recorder__time">
            {checkTime(recorderState.recordingMinutes)}:
            {checkTime(recorderState.recordingSeconds)}
          </div>
          <div className="recorder__helptext">
            {!recorderState.isRecording ?
              'Press the microphone to record' :
              'Press the red record button to stop recording'
            }
          </div>
          <div className="recorder__actions">
            {!recorderState.isRecording && (
              <RecordIcon
                className="recorder__action"
                onClick={startRecording}
              />
            )}
            {recorderState.isRecording && (
              <>
                <StopIcon className="recorder__action" onClick={saveRecording} />
                {!recorderState.isActiveRecording ? (
                  <ResumeIcon
                    className="recorder__action"
                    onClick={resumeRecording}
                  />
                ) : (
                  <PauseIcon
                    className="recorder__action"
                    onClick={pauseRecording}
                  />
                )}
              </>
            )}
          </div>
        </RecorderWrapper>
      </Modal>
    </ListWrapper>
  );
};

export default PersonalGreeting;
