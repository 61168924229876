import React from 'react';
import { Form } from 'react-bootstrap';
import { ListItemWrapper } from 'templates/app/style';
import PersonalGreetingIcon from 'assets/image/icons/app/personal-greeting.svg';

type TProps = {
  item: any,
}
const PersonalGreetingItem: React.FC<TProps> = ({ item }) => {
  const { id, name, date, type } = item;

  return (
    <ListItemWrapper
      className="d-flex align-items-center justify-content-between"
    >
      <div className="d-flex align-items-center">
        <div className="me-2 icon-wrapper purple">
          <PersonalGreetingIcon />
        </div>
        <div>
          <h3>{name}</h3>
          <span>{date}</span>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <Form.Switch
          id={`switch-${id}`}
          className="me-1"
          type="switch"
          checked={type}
          onChange={() => {}}
        />
        <i className="fa fa-chevron-right" />
      </div>
    </ListItemWrapper>
  );
};

export default PersonalGreetingItem;
