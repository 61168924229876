import React from 'react';
import { Line } from 'react-chartjs-2';
import { DateRange } from 'components';
import { overviewData } from 'data/pages/app/dashboard';
import { OverviewWrapper } from './style';

const options = {
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    line: {
      tension: 0.6,
    },
    point: {
      radius: 4,
    }
  },
  hover: {
    mode: 'nearest',
    intersect: false
  },
  tooltips: {
    custom: false,
    mode: 'nearest',
    intersect: false
  },
  plugins: {
    legend: {
      position: 'top',
      labels: {
        boxWidth: 10,
        boxHeight: 10,
        usePointStyle: true,
        color: '#6C7A7B',
      }
    }
  },
  scales: {
    xAxis: {
      grid: {
        display: false,
        drawBorder: false,
      },
    },
    yAxis: {
      grid: {
        borderDash: [4, 4],
        borderDashOffset: 2,
        borderColor: '#DEE3E3'
      },
    },
  },
};

const Overview: React.VFC = ({ ...props }) => {
  return (
    <OverviewWrapper className="mb-4" {...props}>
      <div className="overview__header">
        <span className="overview__header-title">Overview</span>
        <DateRange isLabelEnabled={false} />
      </div>
      <div className="overview__chart">
        <Line data={overviewData} options={options} />
      </div>
      <div className="overview__footer">
        5 new bookings x $100 average revenue per booking = $500.00 Voicio made
        you $500.00 today
      </div>
    </OverviewWrapper>
  );
};

export default Overview;
