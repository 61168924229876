import styled from 'styled-components/macro';
import { ListWrapper, ListItemWrapper } from 'templates/app/style';

export const CustomersWrapper = styled(ListWrapper)`
  padding: 0;

  @media (max-width: 575.98px) {
    padding: 15px 24px;
  }
`;

export const CustomerListItemWrapper = styled(ListItemWrapper)`
  .badge {
    font-weight: 700;
    font-size: 14.4px;
    line-height: 17px;
    letter-spacing: 1.4px;
    color: #FFFFFF;
    border-radius: 30px;
    padding: 2px 11px 1px 11px;
    margin-right: 24px;
  }
`;

export const CustomerBoxItemWrapper = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  margin-bottom: 28px;

  .customer-box-item {
    &__header {
      background: #DFEFF0;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      display: flex;
      align-items: center;
      padding: 13px 17px 10px 17px;

      h3 {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #1D1D1D;
        margin: 0;
      }
    }

    &__avatar {
      max-width: 40px;
      margin-right: 10px;
    }

    &__body {
      padding: 20px 17px;

      &-item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 10px;

        &:last-child {
          margin: 0;
        }

        svg {
          margin-right: 11px;
        }

        &-text {
          span {
            font-weight: 700;
            font-size: 13px;
            line-height: 15px;
            letter-spacing: 0.369485px;
            text-transform: uppercase;
            color: #8A9DA4;
            margin-bottom: 7px;
          }

          p {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #000000;
            margin: 0;
          }
        }
      }
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 27px;
      border-top: 1px solid #DCE7EF;

      &-action {
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }

        svg path {
          fill: #04B3C0;
        }

        &.disabled {
          cursor: default;

          svg path {
            fill: #C4C4C4;
          }
        }
      }
    }
  }
`;

export const CustomerDetailWrapper = styled.div`
  display: flex;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  padding: 28px 34px;

  .customer-detail {
    &__avatar {
      width: 131px;
      margin-right: 40px;

      img {
        width: 100%;
        border-radius: 46px;
      }

      &-action {
        width: 100%;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #8A9DA4;
        text-align: center;
        border: 1px solid #DCE7EF;
        border-radius: 10px;
        padding: 9px 20px 7px;
        margin-top: 17px;
        cursor: pointer;
      }
    }

    &__form {
      flex: 1;

      &-label {
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        color: #8A9DA4
      }

      &-input {
        padding: 4px 18px 4px 12px;
        font-size: 16px;
        line-height: 24px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);

        &::placeholder {
          font-weight: 700;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0.3px;
          text-transform: uppercase;
          color: rgba(138, 157, 164, 0.2);
        }

        &.notes {
          min-height: 166px;
        }
      }

      &-select {
        padding: 10px 14px 8px 8px;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        color: #1D1D1D;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);

        option:disabled {
          font-weight: 700;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0.3px;
          text-transform: uppercase;
          color: rgba(138, 157, 164, 0.2);
        }
      }

      &-row {
        margin: 0 -6px;
      }

      &-col {
        padding: 0 6px;
      }

      &-body {
        border-top: 1px dashed #D1DADA;
        padding-top: 24px;
        padding-bottom: 20px;
        margin-top: 26px;

        p {
          font-weight: 500;
          font-size: 13px;
          line-height: 15px;
          color: #AEB9B9;
        }
      }

      &-footer {
        display: flex;
        justify-content: flex-end;
        border-top: 1px dashed #D1DADA;
        padding-top: 28px;
      }
    }
  }
`;