import React, { useState, useMemo } from 'react';
import { navigate } from 'gatsby';
import classnames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import { Table, SideModal, MessageItem } from 'components';
import { useIsMobile, useModal } from 'components/hooks';
import { ListItemWrapper } from 'templates/app/style';
import { SectionTitle, SectionAction, InteractionDetailWrapper } from '../style';
import { chatData } from 'data/pages/app/interactions';
import VoicioIcon from 'assets/image/icons/app/voicio.svg';
import TranscriptIcon from 'assets/image/icons/app/transcript.svg';
import MessageIcon from 'assets/image/icons/app/chat.svg';


const useTableColumns = ({ getInteraction }) => {
  return useMemo(() => {
    const columns = [
      {
        Header: 'DATE',
        accessor: 'date',
        Cell: ({ row }) => {
          const { original } = row;
          return original.archived ? original.date : (
            <strong>{original.date}</strong>
          );
        },
      },
      {
        Header: 'TYPE',
        Cell: ({ row }) => {
          const { original } = row;
          const Icon = original.type;
          return <Icon />;
        },
        accessor: 'type',
      },
      {
        Header: 'NAME',
        accessor: 'name',
        Cell: ({ row }) => {
          const { original } = row;
          return (
            <div className="d-flex align-items-center">
              <img src={original.img} alt="Avatar" className="me-2" />
              {original.archived ? original.name : (
                <strong>{original.name}</strong>
              )}
            </div>
          );
        },
      },
      {
        Header: 'OUTCOME',
        accessor: 'outcome',
        Cell: ({ row }) => {
          const { original } = row;
          return original.archived ? original.outcome : (
            <strong>{original.outcome}</strong>
          );
        },
      },
      {
        Header: 'ACTIVITY',
        accessor: 'activity',
        Cell: ({ row }) => {
          const { original } = row;
          return original.archived ? original.activity : (
            <strong>{original.activity}</strong>
          );
        },
      },
      {
        Header: '',
        Cell: ({ data, row }) => {
          const { original } = row;
          return (
            <div
              onClick={getInteraction(data[row.index])}
              style={{cursor: 'pointer'}}
            >
              {original.activity === 'Call' ? (
                <TranscriptIcon />
              ) : (
                <MessageIcon />
              )}
            </div>
          );
        },
        accessor: 'action',
        disableSortBy: true,
      },
    ];
    return columns;
  }, [getInteraction]);
};


type ItemProps = {
  item: any,
  getInteraction: React.MouseEventHandler<HTMLElement>
}
const Item: React.FC<ItemProps> = ({ item, getInteraction, ...props }) => {
  const { img, name, outcome, type: Icon, archived } = item;

  return (
    <ListItemWrapper
      className={classnames('d-flex align-items-center', {
        archived,
        'justify-content-between': true
      })}
      {...props}
    >
      <div className="d-flex align-items-center">
        <div className="me-2 avatar-wrapper online">
          <img src={img} alt="A" />
        </div>
        <div>
          <h3>{name}</h3>
          <span>{outcome}</span>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className="me-3 icon-wrapper blue">
          <Icon />
        </div>
        <i
          className="fa fa-chevron-right"
          onClick={() => getInteraction(item)}
        />
      </div>
    </ListItemWrapper>
  );
};


type Props = {
  data: any,
}
const Interactions: React.FC<Props> = ({ data }) => {
  const [currentInteraction, updateCurrentInteraction] = useState(null);
  const {isModalOpen, showModal, hideModal} = useModal();

  const isMobile = useIsMobile();

  const getInteraction = (interaction) => () => {
    if (interaction.activity === 'Call') {
      updateCurrentInteraction(interaction);
      showModal();
    } else {
      navigate('/app/chat');
    }
  };

  const columns = useTableColumns({ getInteraction, });

  const result = useMemo(() => {
    return chatData.reduce((r, obj) => {
      r[obj.date] = r[obj.date] || [];
      r[obj.date].push(obj);
      return r;
    }, Object.create(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatData]);

  return (
    <>
      {!isMobile && (
        <div className="d-flex justify-content-between align-items-center mt-5">
          <SectionTitle className="mb-1">Interactions</SectionTitle>
          <SectionAction>See all</SectionAction>
        </div>
      )}
      {isMobile ? (
        <div className="main-content-body">
          <div className="main-content-wrapper">
            <div className="customer-job-lists">
              <Scrollbars universal autoHide>
                {data.map((item, index) => (
                  <Item
                    key={index}
                    item={item}
                    getInteraction={getInteraction}
                  />
                ))}
              </Scrollbars>
            </div>
          </div>
        </div>
      ) : (
        <Table columns={columns} data={data} />
      )}

      {currentInteraction && (
        <SideModal
          right
          show={isModalOpen}
          onHide={hideModal}
          title={
            <div className="d-flex align-items-center">
              <img src={currentInteraction.img} alt="Avatar" width="35" className="me-2" />
              <span>{currentInteraction.name}</span>
            </div>
          }
        >
          <InteractionDetailWrapper>
            {Object.entries(result).map(([key, value]) => (
              <div className="interaction-detail-item" key={key}>
                <div className="interaction-detail-item__date">{key}</div>
                {value.map((msg, index) => (
                  <MessageItem
                    key={index}
                    Avatar={msg.type === 'voicio' ? VoicioIcon : null}
                    left={msg.type === 'voicio'}
                    message={msg.message}
                    isFeedbackSuggestable={msg.type === 'voicio'}
                  />
                ))}
                <div className="interaction-detail-item__hr"></div>
              </div>
            ))}
          </InteractionDetailWrapper>
        </SideModal>
      )}
    </>
  );
};

export default Interactions;
