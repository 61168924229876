import React from 'react';
import { ListItemWrapper } from 'templates/app/style';
import IncomingCallIcon from 'assets/image/icons/app/incoming-call.svg';
import EmailIcon from 'assets/image/icons/app/email.svg';

type TProps = {
  item: any,
  getCustomerLog: React.MouseEventHandler<HTMLElement>
}
const CallLogItem: React.FC<TProps> = ({ item, getCustomerLog }) => {
  const showIcon = (type: string) => {
    switch (type) {
      case 'incoming-call':
        return <IncomingCallIcon />;

      case 'email':
        return <EmailIcon />;

      default:
        return null;
    }
  };

  return (
    <ListItemWrapper
      className="d-flex align-items-center justify-content-between"
    >
      {/* <div className="d-flex align-items-center">
        <div className="me-2 avatar-wrapper online">
          <img src={img} alt="A" />
        </div>
        <div>
          <h3>{name}</h3>
          <span>{phone}</span>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className="me-3 icon-wrapper blue">
          {showIcon(type)}
        </div>
        <i
          className="fa fa-chevron-right"
          onClick={() => getCustomerLog(item)}
        />
      </div> */}
    </ListItemWrapper>
  );
};

export default CallLogItem;
