import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button, Form } from 'components';
import { useIsMobile } from 'components/hooks';

const Password: React.VFC = () => {
  const isMobile = useIsMobile();

  return (
    <Row className="g-2 align-items-end">
      <Col lg={3} md={6}>
        <Form.Label>Old Password</Form.Label>
        <Form.Input
          type="password"
          placeholder=""
          className="account-setting__input"
        />
      </Col>
      <Col lg={3} md={6}>
        <Form.Label>New Password</Form.Label>
        <Form.Input
          type="password"
          placeholder=""
          className="account-setting__input"
        />
      </Col>
      <Col lg={3} md={6}>
        <Form.Label>Confirm Password</Form.Label>
        <Form.Input
          type="password"
          placeholder=""
          className="account-setting__input"
        />
      </Col>
      {!isMobile && (
        <Col lg={3} md={6}>
          <Button type="button" className="mb-2">Change</Button>
        </Col>
      )}
    </Row>
  );
};

export default Password;
