import styled from 'styled-components/macro';

export const HeaderWrapper = styled.div.attrs(props => ({
  className: `calendar-header-wrapper ${props.className || ''}`,
}))`
  display: flex;
  align-items: center;
  flex-direction: row;
  position: absolute;
  top: 19px;
  left: 303px;
  z-index: 1021;

  .calendar-header {
    &__title {
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      color: #1D1D1D;
      margin: 0 7px 0 0;
    }

    &__btn {
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
      color: #8A9DA4;
      margin-left: 16px;
      cursor: pointer;

      &-active {
        color: #04B3C0;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  @media (max-width: 575px) {
    display: none;
  }
`;

export const CalendarWrapper = styled.div.attrs(props => ({
  className: `calendar-wrapper ${props.className || ''}`,
}))`
  @media (max-width: 575px) {
    height: 100%;
  }

  .fc {
    @media (max-width: 575px) {
      height: 100%;
    }

    .fc-toolbar {
      &.fc-header-toolbar {
        padding: 8px 19px;
        margin: 0;

        @media (max-width: 575px) {
          border-top: 1px solid #DCE7EF;
          padding: 8px;
        }

        .fc-toolbar-chunk {
          display: flex;
          align-items: center;
          flex-direction: row;
        }

        .fc-toolbar-title {
          background-color: #FFF;
          border: 1px solid #D1DADA;
          border-left: none;
          border-right: none;
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: #1D1D1D;
          padding: 6.5px 19px;
          margin: 0 -10px 0 0;
        }

        .fc-button-primary {
          background-color: #FFF;
          border: 1px solid #D1DADA;
          border-radius: 5px;
          color: #1D1D1D;
          padding: 4px 4px;
          min-height: 33px;
          min-width: 33px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          outline: none !important;
          box-shadow: none !important;
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: #1D1D1D;

          &:not(:first-child) {
            margin-left: 10px;
          }

          &:hover, &:active, &:focus {
            opacity: 0.8;
          }

          &.fc-prev-button {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
          &.fc-next-button {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
          &.fc-filter-button {
            padding: 4px 15px;

            @media (max-width: 575px) {
              display: none;
            }
          }
          &.fc-refresh-button {
            @media (max-width: 575px) {
              display: none;
            }
          }
          &.fc-filter-mobile-button {
            display: none;

            @media (max-width: 575px) {
              display: flex;
            }
          }
          &.fc-setting-button {
            padding: 4px 15px;

            @media (max-width: 575px) {
              display: none;
            }
          }
          &.fc-add-button {
            background: #04B3C0;
            border: 1px solid #04B3C0;
            color: #FFF;

            @media (max-width: 575px) {
              display: none;
            }
          }
          &.fc-view-button {
            display: none;

            @media (max-width: 575px) {
              display: flex;
            }
          }
          .fc-icon-fontawesome {
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
            font-family: "Font Awesome 5 Free" !important;
            font-weight: 600;
            font-size: 16px;

            &.fc-icon-sync:before {
              content: "\f2f1";
            }
            &.fc-icon-plus:before {
              content: "\f067";
            }
            &.fc-icon-filter:before {
              content: "\f0b0";
            }
            &.fc-icon-view:before {
              content: "\f133";
            }
          }
        }
      }
    }
    .fc-col-header {
      border-bottom: 1px solid #DCE7EF;
    }
    .fc-col-header-cell {
      border: none;
      border-top: 1px solid #DCE7EF;
      padding: 3px 0;

      @media (max-width: 575px) {
        width: 78px;
        max-width: 78px;
      }

      &-cushion {
        width: 100%;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #667778;
        padding-top: 10px;
        padding-bottom: 10px;

        @media (max-width: 575px) {
          font-size: 36px;
          line-height: 42px;
        }

        &-week {
          @media (max-width: 575px) {
            display: block;
            font-size: 16px;
            line-height: 16px;
          }
        }
      }

      &.fc-day-today {
        .fc-col-header-cell-cushion {
          background: rgba(4, 179, 192, 0.19);
          border-radius: 5px;
          font-weight: 600;
          font-size: 15px;
          line-height: 18px;
          color: #04B3C0;

          @media (max-width: 575px) {
            font-size: 36px;
            line-height: 42px;
          }
        }
      }
      &.fc-day-sun {
        .fc-col-header-cell-cushion {
          color: #EF4848;
        }
        &.fc-day-today {
          .fc-col-header-cell-cushion {
            color: #1F5673;
          }
        }
      }
    }
    .fc-timegrid {
      .fc-timegrid-slot {
        border-color: #DCE7EF;
        height: 3em;

        &-label-cushion {
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          text-align: right;
          color: #696969;
          padding: 0 10px;

          @media (max-width: 575px) {
            color: #1D1D1D;
          }
        }
      }
      .fc-timegrid-axis {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        min-height: 45px;
        border: none;

        &-frame {
          -webkit-font-smoothing: antialiased;
          display: inline-block;
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          line-height: 1;
          font-family: "Font Awesome 5 Free" !important;
          font-weight: 600;
          font-size: 16px;

          &:before {
            content: "\f017";
          }
        }
      }
      .fc-timegrid-col.fc-day-today {
        background: none;
      }
    }
    .fc-daygrid {
      .fc-daygrid-day-number {
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        color: #1D1D1D;
      }
      .fc-daygrid-day.fc-day-today {
        background: rgba(4, 179, 192, 0.12);
      }
    }
    .fc-event {
      padding: 0;

      .fc-event-main {
        padding: 0;
      }
      .fc-event-detail {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        background-color: #FFFFFF;
        padding: 10px 13px 7px 13px;
        border-radius: 3px;
        overflow: hidden;
        overflow-y: auto;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);

        @media (max-width: 575px) {
          padding: 8px 6px;
          justify-content: flex-start;
          color: #FFFFFF;
        }

        &__title {
          color: #1D1D1D;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 2px;

          @media (max-width: 575px) {
            font-size: 12px;
            line-height: 14px;
          }
        }
        &__service,
        &__time {
          color: #8A9DA4;
          font-weight: 400;
          font-size: 13px;
          line-height: 15px;
        }
        &__time {
          @media (max-width: 575px) {
            display: none;
          }
        }
        &__actions {
          position: absolute;
          right: 4px;
          top: 4px;
          display: none;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
          color: #04B3C0;
          cursor: pointer;

          @media (max-width: 575px) {
            color: #FFFFFF;
          }

          &-edit {
            background-color: #FFFFFF;
            border-radius: 4px;
            width: 18px;
            height: 18px;
            display: inline-flex;
            justify-content: center;
            align-items: center;

            &:hover, &:active, &:focus {
              opacity: 0.8;
            }

            @media (max-width: 575px) {
              background-color: transparent;
            }
          }
        }

        &:hover {
          .fc-event-detail__actions {
            display: flex;
          }
        }
      }
      &.fc-timegrid-event {
        border: none;
      }
      &.fc-daygrid-event {
        border: none;
        .fc-event-detail {
          align-items: center;
          flex-direction: row;
          padding: 5px 8px;

          &__info {
            max-width: calc(100% - 35px);
            padding-right: 5px;
          }
          &__title {
            width: 100%;
            font-weight: 400;
            font-size: 13px;
            line-height: 15px;
            margin-bottom: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          &__service {
            display: none;
          }
        }
      }
      &.fc-list-event {
        .fc-event-detail {
          &__info {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: row;
          }
          &__service {
            margin-left: 10px;
          }
        }
        .fc-list-event-graphic {
          display: none;
        }
      }
    }
    .fc-timegrid-now-indicator-line {
      border: 2px solid #04B3C0;
    }
    .fc-view-harness-active {
      @media (max-width: 575px) {
        width: auto;
        background: #F3F7FA;
      }

      .fc-view {
        @media (max-width: 575px) {
          overflow-x: scroll;
        }

        > table {
          @media (max-width: 575px) {
            width: 632px;
          }
        }
        &.fc-timeGridDay-view {
          > table {
            @media (max-width: 575px) {
              width: 100%;
            }

            > thead {
              @media (max-width: 575px) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
`;

export const AppointmentAddWrapper = styled.div.attrs(props => ({
  className: `appointment-add-wrapper ${props.className || ''}`,
}))`
  .appointment-add {
    &__content {
      position: relative;
      padding: 6px 0;

      &-title {
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        color: #8A9DA4;
        text-transform: uppercase;
        margin-bottom: 9px;

        @media (max-width: 575px) {
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.3px;
          margin-bottom: 11px;
        }
      }
      &-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 11px;

        @media (max-width: 575px) {
          margin-bottom: 13px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &-title {
          font-weight: 700;
          font-size: 15px;
          line-height: 18px;
          color: #1D1D1D;
          margin-bottom: 2px;

          @media (max-width: 575px) {
            display: none;
          }
        }
        &-content {
          position: relative;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #1D1D1D;

          @media (max-width: 575px) {
            font-size: 20px;
            line-height: 23px;
          }

          &-icon {
            position: absolute;
            top: 12px;
            right: 13px;
          }
        }
      }
      &-select {
        font-size: 15px;
        line-height: 18px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
        padding: 11px 14px 10px 14px;

        @media (max-width: 575px) {
          padding: 13px 14px 10px 14px;
        }
      }
    }
  }
`;

export const AppointmentEditWrapper = styled.div.attrs(props => ({
  className: `appointment-edit-wrapper ${props.className || ''}`,
}))`
  .appointment-edit {
    &__datetime {
      display: flex;
      justify-content: space-between;
      background-color: #F3F7FA;
      padding: 18px 25px 16px 25px;

      @media (max-width: 575px) {
        padding: 4px 18px 18px 18px;
        text-align: center;
      }

      &-body {
        flex: 1;
        padding-right: 10px;

        &-date {
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: #04B3C0;
          margin-bottom: 5px;
        }
        &-time {
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
          color: #667778;
        }
        &-datepicker {
          position: relative;

          &-icon {
            position: absolute;
            top: 12px;
            right: 13px;
          }
        }
      }
      &-action {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 33px;
        height: 33px;
        background: #FFFFFF;
        border: 1px solid #D1DADA;
        border-radius: 5px;
        cursor: pointer;

        &.save {
          background: #04B3C0;
          border: 1px solid #04B3C0;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }
    &__content {
      position: relative;
      padding: 19px 25px;

      @media (max-width: 575px) {
        padding: 17px 25px 0 25px;
      }

      &:not(:last-child) {
        border-bottom: 1px dashed #D1DADA;

        @media (max-width: 575px) {
          padding-top: 22px;
          border: none;
        }
      }

      &-title {
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        color: #1D1D1D;
        margin-bottom: 25px;

        @media (max-width: 575px) {
          font-size: 18px;
          line-height: 22px;
          margin-bottom: 18px;
        }
      }
      &-action {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 33px;
        height: 33px;
        position: absolute;
        top: 17px;
        right: 25px;
        background: #FFFFFF;
        border: 1px solid #D1DADA;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }
      &-item {
        display: flex;
        margin-bottom: 11px;

        @media (max-width: 575px) {
          margin-bottom: 13px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &-icon  {
          margin-right: 13px;

          svg {
            path {
              fill: #667778;
            }
          }

          @media (max-width: 575px) {
            margin-right: 16px;

            svg {
              width: 20px;
              height: 20px;
            }
          }
        }
        &-body {
          flex: 1;

          &-title {
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.3px;
            text-transform: uppercase;
            color: #8A9DA4;
            margin-bottom: 8px;

            @media (max-width: 575px) {
              font-size: 15px;
              line-height: 18px;
            }
          }
          &-content {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #1D1D1D;

            @media (max-width: 575px) {
              font-size: 20px;
              line-height: 23px;
            }
          }
        }
      }
      &-option {
        margin-bottom: 11px;
        padding-left: 30px;

        @media (max-width: 575px) {
          margin-bottom: 14px;
          padding-left: 0;
        }

        &-title {
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0.3px;
          text-transform: uppercase;
          color: #8A9DA4;
          margin-bottom: 5px;
        }
      }
      &-select {
        font-size: 15px;
        line-height: 18px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
        padding: 9px 14px 8px 14px;

        @media (max-width: 575px) {
          padding: 13px 14px 10px 14px;
        }
      }
      &-note {
        font-size: 15px;
        line-height: 18px;
        padding: 9px 14px 8px 14px;

        @media (max-width: 575px) {
          height: 42px;
          min-height: 42px;
        }
      }
    }
  }
`;

export const ViewWrapper = styled.div.attrs(props => ({
  className: `calendar-view-wrapper ${props.className || ''}`,
}))`
  text-align: center;
  padding: 32px;

  .calendar-view {
    &__title {
      font-weight: 700;
      font-size: 22px;
      line-height: 26px;
      color: #04B3C0;
      margin-bottom: 21px;
    }

    &__btn {
      font-weight: 500;
      font-size: 26px;
      line-height: 43px;
      color: #8A9DA4;
      cursor: pointer;

      &-active {
        font-weight: 700;
        color: #04B3C0;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

export const QuickMessageWrapper = styled.div.attrs(props => ({
  className: `quick-message-wrapper ${props.className || ''}`,
}))`
  margin: -15px -22px;

  .quick-message {
    &__header {
      display: flex;
      background: #ECF4F4;
      padding: 22px 19px;

      &-icon {
        width: 39px;
        height: 39px;
        background: #FFFFFF;
        border-radius: 13px;
        margin-right: 12px;
        overflow: hidden;

        svg {
          width: 39px;
          height: 39px;
        }
      }

      &-message {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #000000;
        background: #FFFFFF;
        padding: 10px 12px 6px;
        border-radius: 8px;
        border-bottom-left-radius: 0;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
      }
    }

    &__body {
      padding: 15px 15px 7px;
    }

    &__message {
      background: #04B3C0;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: #FFFFFF;
      padding: 5px 14px;
      border-radius: 50px;
      margin-right: 6px;
      margin-bottom: 10px;
      cursor: pointer;

      &.custom {
        background: #FFFFFF;
        font-size: 14px;
        line-height: 16px;
        color: #667778;
        border: 1px solid #8A9DA4;
      }
    }

    &__custom-message {
      padding: 14px 18px 16px;
      border-top: 1px solid #CADDDE;
    }
  }
`;
