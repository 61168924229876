import React, { useState } from 'react';
import { Form, Row, Col, Tabs, Tab } from 'react-bootstrap';
import TimezoneSelect from 'react-timezone-select';
import { useIsMobile } from 'components/hooks';
import useConnector from 'components/hooks/useConnector';
import DayForm from './dayForm';
import { BusinessHoursWrapper } from './style';
import { EQueryKeys } from 'interfaces';

const daysOfWeek = [
  'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
];

const BusinessHours: React.VFC<any> = ({ active = false }) => {
  const [selectedTimezone, setSelectedTimezone] = useState({});

  const {
    data: daysData,
    mutationController,
    authUser
  } = useConnector(EQueryKeys.BusinessHours, { active });
  const [day] = useState(daysOfWeek[0]);
  const isMobile = useIsMobile();

  const getDay = (dayKey: any) => {
    return daysData[dayKey];
  };

  const handleDayChange = (newData) => {
    if (newData.id) {
      mutationController.onUpdate(newData);
    } else {
      mutationController.onCreate(newData);
    }
  };
  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      color: '#667778',
    }),
    control: (provided, state) => ({
      ...provided,
      height: isMobile ? '45px' : '33px',
      border: `${state.isFocused ? ' 1px solid rgba(4, 179, 192, 0.75)' : '1px solid #DAE3E3'}`,
      boxShadow: 'none',

      '&:hover': {
        borderColor: 'rgba(4, 179, 192, 0.75)'
      }
    }),
  };

  return (
    <BusinessHoursWrapper>
      <Form className="business-hours__form">
        <Form.Group as={Row} className="mb-1">
          <Form.Label column xs="8" sm="4" md="4" lg="3" xl="2" className="business-hours__form-label">
            Enable business hours
          </Form.Label>
          <Col xs="4" sm="8" md="8" lg="4" xl="3" className="text-end">
            <Form.Switch
              id="business hours"
              className="business-hours__form-switch"
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="4" md="4" lg="3" xl="2" className="business-hours__form-label">
            Timezone
          </Form.Label>
          <Col sm="8" md="8" lg="4" xl="3">
            <TimezoneSelect
              className="business-hours__form-timezone"
              styles={customStyles}
              value={selectedTimezone}
              onChange={setSelectedTimezone}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-2">
          <Form.Label column sm="12" className="business-hours__form-text">
            Days Of The Week
          </Form.Label>
          <Col sm="12">
            {isMobile ? (
              <>
                <Form.Select className="business-hours__form-select">
                  {daysOfWeek.map((d, i) => (
                    <option key={i}>{d}</option>
                  ))}
                </Form.Select>
                <DayForm />
              </>
            ) : (
              <Tabs
                defaultActiveKey={day}
                className="business-hours__form-tabs"
              >
                {daysOfWeek.map((d, i) => (
                  <Tab eventKey={d} title={d} key={i}>
                    <DayForm
                      data={getDay(i)}
                      userId={authUser.id}
                      dayKey={i}
                      handleChange={handleDayChange} 
                    />
                  </Tab>
                ))}
              </Tabs>
            )}
          </Col>
        </Form.Group>
      </Form>
    </BusinessHoursWrapper>
  );
};

export default BusinessHours;
