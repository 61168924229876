import React from 'react';
import classnames from 'classnames';
import { numberWithCommas } from 'utils/helper';
import { Button } from 'components';
import { useIsMobile } from 'components/hooks';
import { JobDetailWrapper } from '../style';

type Props = {
  job: any,
  handleDeselect: () => void,
  handleInvoiceForm: () => void,
}
const JobDetail: React.FC<Props> = ({
  job, handleDeselect, handleInvoiceForm
}) => {
  const isMobile = useIsMobile();
  const { icon: Icon } = job;

  return (
    <JobDetailWrapper
      className={classnames('customer-job-detail', {
        'completed': job.completed,
      })}
    >
      <div className="customer-job-detail__close" onClick={handleDeselect}>
        <i className="fa fa-times" />
      </div>
      <div className="customer-job-detail__icon">
        <Icon />
        {job.completed && isMobile && (
          <span className="customer-job-detail__badge">
            <i className="fa fa-check" />
          </span>
        )}
      </div>
      <div className="customer-job-detail__text">
        <div className="customer-job-detail__text-title">
          {job.title}
        </div>
        <div className="customer-job-detail__text-description">
          {job.description}
        </div>
        {job.completed && !isMobile && (
          <span className="customer-job-detail__badge">
            <i className="fa fa-check" /> Completed
          </span>
        )}
      </div>
      <hr />
      <div className="customer-job-detail__list">
        <div className="customer-job-detail__list-item">
          <span>Job earning:</span>
          <span>${numberWithCommas(job.earning)}</span>
        </div>
        <div className="customer-job-detail__list-item">
          <span>Fee:</span>
          <span>${numberWithCommas(job.fee)}</span>
        </div>
        <div className="customer-job-detail__list-item total">
          <span>Total:</span>
          <span>${numberWithCommas(job.earning - job.fee)}</span>
        </div>
      </div>
      <hr />
      <div className="customer-job-detail__list">
        <div className="customer-job-detail__list-item">
          <span>Invoice No.</span>
          <span>INV02934845900</span>
        </div>
        <div className="customer-job-detail__list-item">
          <span>Date</span>
          <span>June 25, 2021</span>
        </div>
      </div>
      {job.completed && (
        <>
          <hr />
          <div className="customer-job-detail__action">
            <Button
              round
              block
              onClick={handleInvoiceForm}
            >
              Create Invoice
            </Button>
          </div>
        </>
      )}
    </JobDetailWrapper>
  );
};

export default JobDetail;
