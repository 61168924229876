import React from 'react';
import { Button } from 'components';
import HeaderImg from 'assets/image/pages/app/advanced-questions.png';
import PhoneIcon from 'assets/image/icons/app/telephone.svg';

const Step1 = ({ navigation = {} }) => {
  const { next } = navigation;

  return (
    <div className="advanced-questions__step text-center">
      <div className="advanced-questions__step-image">
        <img
          src={HeaderImg}
          alt="Step 1"
        />
      </div>
      <div className="advanced-questions__step-text-content">
        <h2 className="advanced-questions__step-title">Hello, Jason!</h2>
        <p className="advanced-questions__step-description">
          Voicio has already helped you focus on what’s important: getting
          things done. To serve you better, we have a couple of additional
          questions.
        </p>
        <Button
          large
          className="mb-4"
          onClick={next}
          sizeX={'182px'}
        >
          Continue
        </Button>
        <div className="advanced-questions__step-phone-action">
          <PhoneIcon /> Finish this over the phone
        </div>
      </div>
    </div>
  );
};

export default Step1;
