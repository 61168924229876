import React, { useState } from 'react';
import { navigate } from 'gatsby';

import { AppPageWrapper, Title, Button, FloatingButton, Modal } from 'components';
import { useIsMobile } from 'components/hooks';
import CustomersList from 'templates/app/customers';
import CustomerForm from 'templates/app/customers/form';
import CustomerDetail from 'templates/app/customers/detail';
import { customerForm, validate } from 'data/pages/app/customers';
import useConnector from 'components/hooks/useConnector';
import { EQueryKeys } from 'interfaces';
import PlusIcon from 'assets/image/icons/app/plus.svg';

const Customers = () => {
  const [isAdding, setIsAdding] = useState(false);
  const [showDeleteCustomer, updateDeleteConfirm] = useState(null);
  const { data, mutationController } = useConnector(EQueryKeys.CustomerClients,
    {logging: false });
  const isMobile = useIsMobile();

  const showAddNewCustomer = () => {
    setIsAdding(true);
  };

  const showEditCustomer = (customer) => {
    navigate(`/customers/${customer.id}`);
  };

  const showDeleteConfirm = (customer) => () => {
    updateDeleteConfirm(customer);
  };

  const deleteCustomer = () => {
    mutationController.onDelete(showDeleteCustomer);
    updateDeleteConfirm(null);
  };

  const onSaveCustomer = (customer) => {
    const validatedCustomer = validate(customer);
    mutationController.onCreate(validatedCustomer);
    setIsAdding(false);
  };

  return (
    <AppPageWrapper title="My Customers">
      <div className="main-content-container xs-p-0">
        {!isMobile ? (
          <Title
            title={isAdding ? 'New Customer' : 'My Customers'}
          >
            {!isAdding && (
              <Button round onClick={showAddNewCustomer}>
                <i className="fas fa-plus"></i>&nbsp;&nbsp;Add New
              </Button>
            )}
          </Title>
        ) : (
          <FloatingButton onClick={showAddNewCustomer}>
            <PlusIcon />
          </FloatingButton>
        )}
        <div
          className="main-content-body"
          style={{background: 'none', boxShadow: 'none'}}
        >
          <div className="main-content-wrapper">
            {(isAdding && !isMobile) ? (
              <CustomerDetail
                cancel={() => setIsAdding(false)}
                customer={customerForm}
                onSubmit={onSaveCustomer}
              />
            ) : (
              <CustomersList
                showEditCustomer={showEditCustomer}
                showDeleteCustomer={showDeleteConfirm}
                customers={data}
              />
            )}
          </div>
        </div>
      </div>
      {isAdding && isMobile && (
        <CustomerForm
          show={isAdding}
          closeModal={() => setIsAdding(false)}
          customer={customerForm}
          onSubmit={onSaveCustomer}
        />
      )}
      {showDeleteCustomer &&
        <Modal
          show={true}
          onHide={() => updateDeleteConfirm(null)}
          title={`Delete Customer ${showDeleteCustomer.name}?`}
          footer={
            <>
              <Button variant="primary" round onClick={deleteCustomer}>
                Delete
              </Button>
              <Button variant="link" onClick={() => updateDeleteConfirm(null)}>
                Close
              </Button>
            </>
          }
        />
      }
    </AppPageWrapper>
  );
};

export default Customers;
