import React, { useState } from 'react';
import { AppPageWrapper } from 'components';
import Calendar from 'templates/app/bookingCalendar';
import Header from 'templates/app/bookingCalendar/header';
import useConnector from 'components/hooks/useConnector';
import { EQueryKeys } from 'interfaces';

const BookingCalendar: React.VFC = () => {
  const [view, setView] = useState('timeGridWeek');
  const { data, mutationController } = useConnector(EQueryKeys.Calendar);
  const { data: customerList } = useConnector(EQueryKeys.CustomerClients);

  const onSaveEvent = (eventDetail) => {
    if (eventDetail.href) {
      mutationController.onUpdate(eventDetail);
    } else {
      mutationController.onCreate(eventDetail);
    }
  };

  const onDeleteEvent = (event) => {
    mutationController.onDelete(event);
  };

  return (
    <AppPageWrapper title="Booking Calendar" noSearchbar>
      <div className="main-content-container p-0">
        <Header view={view} setView={setView} />
        <Calendar
          view={view}
          eventsData={data}
          customerList={customerList}
          setView={setView}
          onSaveEvent={onSaveEvent}
          onDeleteEvent={onDeleteEvent}
        />
      </div>
    </AppPageWrapper>
  );
};

export default BookingCalendar;
