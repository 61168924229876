import React from 'react';
import { PageWrapper, BrandLogo } from 'components';
import { Item } from 'templates/onboarding';
import onboardingData from 'data/pages/onboarding';
import HandIcon from 'assets/image/icons/onboarding/hand.svg';

const header = {
  headerClasses: 'no-header',
};

const Onboarding: React.VFC = () => {
  return (
    <PageWrapper headerConfig={header}>
      <div className="onboarding">
        <BrandLogo className="onboarding__logo" />
        <h4 className="onboarding__title">
          Hello, John Doe{' '}
          <HandIcon />
        </h4>
        <p className="onboarding__description">
          <strong>Congratulation!</strong> Please access the features below and
          get your business started.
        </p>
        <div className="onboarding__items">
          {onboardingData.items.map((item, index) => (
            <Item key={index} {...item} />
          ))}
        </div>
      </div>
    </PageWrapper>
  );
};

export default Onboarding;
