import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { PageWrapper } from 'components';
import Error from 'templates/error';
import FooterSection from 'templates/footer';
import { Loader } from 'components';

const browser = typeof window !== 'undefined' && window;

const ErrorPage: React.FC<RouteComponentProps> = () => {
  return (
    browser ? (
      <PageWrapper>
        <Error />
        <FooterSection />
      </PageWrapper>
    ) : (
      <Loader fillContainer />
    )
  );
};

export default ErrorPage;