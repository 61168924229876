import styled from 'styled-components/macro';

export const HeaderWrapper = styled.div.attrs(props => ({
  className: `${props.className || ''}`,
}))`
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    width: 58px;
    height: auto;
    border-radius: 20px;
    margin-right: 14px;
  }

  h3 {
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;
    color: #1D1D1D;
    margin-bottom: 4px;

    span {
      font-weight: 500;
    }

    @media (max-width: 575px) {
      font-size: 32px;
      line-height: 37px;
    }
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #839393;
    margin: 0;

    @media (max-width: 575px) {
      font-size: 16px;
      line-height: 19px;
    }
  }
`;
