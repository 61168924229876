import React from 'react';
import { Button, Modal, Form } from 'components';
import { useIsMobile, useForm } from 'components/hooks';
import { BackButton } from 'templates/app/style';
import { ZipCodeWrapper } from './style';
import BackIcon from 'assets/image/icons/app/back.svg';

function validate (values) {
  const errors: any = {};
  if (!values.zip) {
    errors.zip = 'ZipCode is required';
  }

  return errors;
};

type Props = {
  show: boolean,
  closeModal: () => void,
  onSubmit: () => void,
  back: () => void,
}
const ZipCodeModal: React.FC<Props> = ({
  show, closeModal, onSubmit, back,
}) => {
  const isMobile = useIsMobile();
  const {
    values,
    errors,
    isValidated,
    handleChange,
    handleSubmit,
  } = useForm(onSubmit, validate);

  return (
    <Modal
      className="modal-xs"
      show={show}
      onHide={closeModal}
      backdrop="static"
    >
      <ZipCodeWrapper>
        {isMobile && (
          <div className="business-name__header">
            <BackButton onClick={back}>
              <BackIcon />{' '}Back
            </BackButton>
            <h2 className="business-name__header-title">Setting Up</h2>
          </div>
        )}
        <Form noValidate onSubmit={handleSubmit}>
          <div className="business-name__form">
            <h4 className="business-name__title">
              What ZIP does your business operate from?
            </h4>
            <p className="business-name__text">
              This helps us make sure we set the right timezone for your
              calendar, and accept jobs that you could actually get to.
            </p>
            <Form.Group className="ps-sm-5 pe-sm-5 mb-5">
              <Form.Input
                name="zip"
                placeholder="00000"
                className="text-center"
                value={values.zip || ''}
                isValid={isValidated && Boolean(!errors.zip)}
                isInValid={isValidated && Boolean(errors.zip)}
                error={errors.zip}
                onChange={handleChange}
                required
              />
            </Form.Group>
          </div>
          <Button
            className="business-name__action"
            variant="primary"
            round
            onClick={handleSubmit}
          >
            Next
          </Button>
        </Form>
      </ZipCodeWrapper>
    </Modal>
  );
};

export default ZipCodeModal;
