import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { Form, Button } from 'components';
import { useIsMobile } from 'components/hooks';
import { InvoiceFormWrapper, SectionTitle } from '../style';
import DateIcon from 'assets/image/icons/app/date.svg';

type Props = {
  job: any,
  customer: any,
}
const InvoiceForm: React.FC<Props> = ({ customer }) => {
  const [startDate, setStartDate] = useState(new Date());
  const isMobile = useIsMobile();

  return (
    <InvoiceFormWrapper>
      {!isMobile && (
        <div className="customer-invoice-form__number">#INV02934845900</div>
      )}
      <SectionTitle>Send to:</SectionTitle>
      <div className="customer-invoice-form__info">
        <div className="customer-invoice-form__info-label">Name</div>
        <div className="customer-invoice-form__info-text">{customer.name}</div>
      </div>
      <div className="customer-invoice-form__info">
        <div className="customer-invoice-form__info-label">Phone No.</div>
        <div className="customer-invoice-form__info-text">{customer.phone}</div>
      </div>
      <div className="customer-invoice-form__info">
        <div className="customer-invoice-form__info-label">Address</div>
        <div className="customer-invoice-form__info-text">
          {customer.address}
        </div>
      </div>
      <div className="customer-invoice-form__form">
        <Form.Group className="mb-2">
          <Form.Label>Email Address</Form.Label>
          <Form.Input
            className="customer-invoice-form__input"
            placeholder=""
            value={customer.email}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Message</Form.Label>
          <Form.Textarea placeholder="" />
        </Form.Group>
        <hr />
        <Form.Group className="mb-3">
          {!isMobile && (<Form.Label>Date</Form.Label>)}
          <div className="customer-invoice-form__form-datepicker">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
            <DateIcon className="customer-invoice-form__form-datepicker-icon" />
          </div>
        </Form.Group>
        <Form.Group className="mb-2">
          {!isMobile && (<Form.Label>Service</Form.Label>)}
          <Form.Select>
            <option>General Plumbing Services</option>
          </Form.Select>
        </Form.Group>
        <div className="customer-invoice-form__form-add">
          + Add Service
        </div>
      </div>
      <div className="customer-invoice-form__footer">
        <div className="customer-invoice-form__total">
          <span>Total Billed</span><span>$0.00</span>
        </div>
        <div className="mb-4">
          <Button round block>Send Invoice</Button>
        </div>
        <div className="customer-invoice-form__actions">
          <a href="#" className="customer-invoice-form__actions-item underlined">
            Save Draft
          </a>
          <a href="#" className="customer-invoice-form__actions-item">
            <i className="fa fa-download" /> Download
          </a>
          <a href="#" className="customer-invoice-form__actions-item">
            <i className="fa fa-print" /> Print
          </a>
        </div>
      </div>
    </InvoiceFormWrapper>
  );
};

export default InvoiceForm;
