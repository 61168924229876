import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { Searchbox } from 'components';
import { useIsMobile } from 'components/hooks';
import BoxItem from './boxItem';
import ListItem from './listItem';
import { CustomersWrapper } from './style';

const Customers = ({
  customers = [],
  showEditCustomer,
  ...props
}) => {
  const isMobile = useIsMobile();

  return (
    <CustomersWrapper {...props}>
      <div className="list__content">
        <Searchbox
          className="list__content-filter-input"
          placeholder="Search..."
        />
        {isMobile ? (
          <div className="list__content-body">
            <Scrollbars universal autoHide>
              {customers.map((data, index) => (
                <ListItem
                  key={index}
                  item={data}
                  showEditCustomer={showEditCustomer}
                />
              ))}
            </Scrollbars>
          </div>
        ) : (
          <Row className="pt-4">
            {customers.map((data, index) => (
              <Col xl={3} lg={4} md={6} key={index}>
                <BoxItem item={data} showEditCustomer={showEditCustomer} />
              </Col>
            ))}
          </Row>
        )}
      </div>
    </CustomersWrapper>
  );
};

export default Customers;
