import styled from 'styled-components/macro';

export const MenuWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  background-color: ${props => props.color};
  height: 190px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
  border-radius: 35px;
  padding: 31px 26px 25px 26px;
  margin: 0 -4px;

  .menu__item {
    &-title {
      font-weight: 700;
      font-size: 26px;
      line-height: 30px;
      text-align: center;
      color: #1D1D1D;
      margin: 0;
    }
    &-icon {
      margin-bottom: 18px;
    }
    &-description {
      position: absolute;
      bottom: 11px;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      color: #04B3C0;
      margin: 0 auto;
    }
  }
`;
