import React, { useState } from 'react';
import AvatarIcon from 'assets/image/icons/app/avatar.svg';
import ImageIcon from 'assets/image/icons/app/image.svg';
import SendIcon from 'assets/image/icons/app/send2.svg';

type Props = {
  channel: any,
  onSendMessage: (channelId: string, text: string) => void,
}
const SendMessage: React.FC<Props> = ({ channel, onSendMessage }) => {
  const [message, setMessage] = useState('');

  const send = () => {
    if (message) {
      onSendMessage(channel.id, message);
      setMessage('');
    }
  };

  const handleInput = e => {
    setMessage(e.target.value);
  };

  return (
    <div className="chat__messages-input">
      <div className="chat__messages-input-icons left">
        <div className="chat__messages-input-icon"><AvatarIcon /></div>
        <div className="chat__messages-input-icon"><ImageIcon /></div>
      </div>
      <input type="text" onChange={handleInput} value={message} />
      <div className="chat__messages-input-icons right">
        <div className="chat__messages-input-icon" onClick={send}>
          <SendIcon />
        </div>
      </div>
    </div>
  );
};

export default SendMessage;
