import React from 'react';
import { Button } from 'components';
import { useIsMobile } from 'components/hooks';
import { AvatarWrapper, UploadButtonWrapper } from '../style';
import AvatarIcon from 'assets/image/pages/app/avatar.png';
import PencilIcon from 'assets/image/icons/app/pencil2.svg';

const Avatar: React.VFC = () => {
  const isMobile = useIsMobile();

  return (
    <AvatarWrapper>
      <div className="avatar-setting__content">
        <div className="avatar-setting__avatar">
          <img src={AvatarIcon} alt="Avatar" />
        </div>
        {isMobile ? (
          <UploadButtonWrapper type="button">
            <PencilIcon />
          </UploadButtonWrapper>
        ) : (
          <>
            <div className="avatar-setting__text">
              <div className="avatar-setting__text-title">Change Picture</div>
              <div className="avatar-setting__text-description">
                Lorem Ipsum has been the industry`s standard dummy.
              </div>
            </div>
            <div className="navbar-setting__action">
              <Button
                type="button"
                backgroundColor={'#CADDDE'}
                round
              >
                Upload
              </Button>
            </div>
          </>
        )}
      </div>
    </AvatarWrapper>
  );
};

export default Avatar;
