import React, { useMemo } from 'react';
import { CustomAction } from 'templates/app/style';
import PlayIcon from 'assets/image/icons/app/play.svg';

export const useTableColumns = (): any => {
  return useMemo(() => {
    const columns = [
      {
        Header: 'NAME',
        accessor: 'name'
      },
      {
        Header: 'PREVIEW',
        Cell: () => {
          const handleClick = (): void => {};
          return (
            <CustomAction onClick={handleClick}><PlayIcon /></CustomAction>
          );
        },
        accessor: 'type',
      },
    ];
    return columns;
  }, []);
};
