import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Form, ConfirmModal } from 'components';
import { useIsMobile, useModal } from 'components/hooks';
import { Avatar, Personal, Company, Password } from './components';
import Tabs from './tabs';
import TabContent from './tabContent';
import { SettingWrapper, TextWrapper } from './style';

type TProps = {
  tab: string,
  setTab: (props: string) => void,
}
const AccountSetting: React.FC<TProps> = ({ tab, setTab }) => {
  const isMobile = useIsMobile();
  const {isModalOpen, showModal, hideModal} = useModal();

  const deactivateAccount = (): void => {
  };

  return (
    <>
      <SettingWrapper>
        {isMobile ? (
          <>
            {tab ? (
              <TabContent tab={tab} />
            ) : (
              <Tabs setTab={setTab} deactivateAccount={showModal} />
            )}
          </>
        ) : (
          <Form>
            <Form.Title>Avatar</Form.Title>
            <Avatar />
            <hr />
            <Personal />
            <hr />
            <Form.Title>Company Information</Form.Title>
            <Company />
            <hr />
            <Form.Title>Change Password</Form.Title>
            <Password />
            <hr />
            <Row className="g-2">
              <Form.Title>Deactivate your account</Form.Title>
              <Col xl={6}>
                <TextWrapper>
                  Keep in mind tha when you delete your <b>Voicio account</b>,
                  all data will be also deleted, including your customers. {' '}
                  <span onClick={showModal}>Deactivate Account</span>
                </TextWrapper>
              </Col>
            </Row>
          </Form>
        )}
      </SettingWrapper>

      <ConfirmModal
        show={isModalOpen}
        onHide={hideModal}
        onSubmit={deactivateAccount}
        title={'Deactivate Account'}
        message={'Are you sure to deactivate your account?'}
        successBtnLabel={'Deactivate'}
      />
    </>
  );
};

export default AccountSetting;
