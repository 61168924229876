import React from 'react';
import { CustomerListItemWrapper } from './style';
import AvatarIcon from 'assets/image/pages/app/avatar.png';

type Props = {
  item: any,
  showEditCustomer: (props: any) => void,
}
const CustomerItem: React.FC<Props> = ({ item }) => {
  const { name, phone } = item;

  return (
    <CustomerListItemWrapper
      className="d-flex align-items-center justify-content-between"
    >
      <div className="d-flex align-items-center">
        <div className="me-2 avatar-wrapper online">
          <img src={AvatarIcon} alt="A" />
        </div>
        <div>
          <h3>{name}</h3>
          <span>{phone}</span>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className="badge badge-primary">Paid</div>
        <i className="fa fa-chevron-right" />
      </div>
    </CustomerListItemWrapper>
  );
};

export default CustomerItem;
