import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { useParams } from '@reach/router';
import { Tab } from 'react-bootstrap';

import { EQueryKeys, Customer } from 'interfaces';
import { AppPageWrapper, Title, Tabs, Modal } from 'components';
import { useIsMobile, useConnector } from 'components/hooks';
import CustomerDetail from 'templates/app/customer';
import Profile from 'templates/app/customer/components/profileTab';
import Interactions from 'templates/app/customer/components/interactions';
import Bookings from 'templates/app/customer/components/bookings';
import ProfileForm from 'templates/app/customer/components/profileForm';
import JobDetail from 'templates/app/customer/components/jobDetail';
import Invoice from 'templates/app/customer/invoice';
import { BackButton, SaveButton } from 'templates/app/style';
import { CustomerWrapper } from 'templates/app/customer/style';
import {
  customerDetailData,
  interactionsData,
  bookingsData,
  emptyCustomer,
} from 'data/pages/app/customer';
import BackIcon from 'assets/image/icons/app/back.svg';
import PencilIcon from 'assets/image/icons/app/pencil.svg';

const CustomerDetailPage: React.VFC = () => {
  const params = useParams();
  const [isInvoiceForm, setInvoiceForm] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isEditing, updateEditing] = useState(false);
  const [tab, setTab] = useState('profile');
  const [shouldShowJobDetail, setShouldShowJobDetail] = useState(false);
  const isMobile = useIsMobile();
  const { data = [], mutationController } = useConnector(
    EQueryKeys.CustomerClients);

  const customerDetail: Customer.IShape =
    data.find((c: any) => c.id === parseInt(params.customer_id))
    || emptyCustomer;

  const handleBack = (): void => {
    if (isInvoiceForm) {
      setInvoiceForm(false);
    } else {
      navigate('/customers/');
    }
  };

  const selectJob = (job: any): void => {
    setSelectedJob(job);
  };

  const handleInvoiceForm = (): void => {
    setShouldShowJobDetail(false);
    setInvoiceForm(true);
  };

  const saveCustomer = (customer: any) => {
    const updatedCustomer = Object.entries(customer).reduce(
      (acc: any, [key, val]) => {
        if (val) {
          acc[key] = val;
        };
        return acc;
      }, {});
    mutationController.onUpdate(updatedCustomer);
    updateEditing(false);
  };

  return (
    <AppPageWrapper
      title={isMobile ? 'Detail' : (isInvoiceForm ? 'Create Invoice' : 'My Customer')}
    >
      <div className="main-content-container xs-p-0">
        {isMobile ? (
          <>
            <BackButton onClick={handleBack}>
              <BackIcon />{' '}Back
            </BackButton>
            {tab === 'profile' && (
              <SaveButton onClick={() => updateEditing(true)}>
                <PencilIcon />
              </SaveButton>
            )}
          </>
        ) : (
          <Title
            title={isInvoiceForm ? 'Create Invoice' : 'My Customer'}
            handleBack={handleBack}
          />
        )}
        {isMobile ? (
          <>
            {isInvoiceForm ? (
              <Invoice job={selectedJob} customer={customerDetailData} />
            ) : (
              <Tabs
                className="nav-full"
                defaultActiveKey="profile"
                onSelect={(k) => setTab(k)}
              >
                <Tab eventKey="profile" title="Profile">
                  <CustomerWrapper>
                    <Profile customer={customerDetail} />
                  </CustomerWrapper>
                </Tab>
                <Tab eventKey="interactions" title="Interactions">
                  <CustomerWrapper>
                    <Interactions data={interactionsData} />
                  </CustomerWrapper>
                </Tab>
                <Tab eventKey="booking" title="Bookings">
                  <CustomerWrapper>
                    <Bookings data={bookingsData} />
                  </CustomerWrapper>
                </Tab>
              </Tabs>
            )}
          </>
        ) : (
          <CustomerDetail
            isInvoiceForm={isInvoiceForm}
            handleInvoiceForm={handleInvoiceForm}
            customer={customerDetail}
            interactionsData={interactionsData}
            bookingsData={bookingsData}
            selectedJob={selectedJob}
            handleSelectJob={selectJob}
            isEditing={isEditing}
            updateEditing={updateEditing}
            onSubmit={saveCustomer}
          />
        )}
      </div>

      {selectedJob && isMobile && (
        <Modal
          show={shouldShowJobDetail}
          onHide={() => setShouldShowJobDetail(false)}
        >
          <JobDetail
            job={selectedJob}
            handleDeselect={() => selectJob(null)}
            handleInvoiceForm={handleInvoiceForm}
          />
        </Modal>
      )}
      {isEditing && isMobile && (
        <ProfileForm
          show={isEditing}
          closeModal={() => updateEditing(false)}
          customer={customerDetailData}
        />
      )}
    </AppPageWrapper>
  );
};

export default CustomerDetailPage;
