import React from 'react';
import { Personal, Company, Password } from './components';

type TProps = {
  tab: string,
}
const TabContent: React.FC<TProps> = ({ tab }) => {
  switch (tab) {
    case 'personal':
      return <Personal />;
    case 'company':
      return <Company />;
    case 'password':
      return <Password />;
    default:
      return <></>;
  }
};

export default TabContent;
