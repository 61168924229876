import React, { useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';
import { CustomToggle } from 'components';
import { DropdownWrapper } from 'templates/app/style';
import IncomingCallIcon from 'assets/image/icons/app/incoming-call.svg';
import EmailIcon from 'assets/image/icons/app/email.svg';
import TranscriptlIcon from 'assets/image/icons/app/transcript.svg';

export const useTableColumns = ({ getCustomerLog }) => {
  return useMemo(() => {
    const columns = [
      {
        Header: 'NAME',
        accessor: 'calleridName'
      },
      {
        Header: 'PHONE NO.',
        accessor: 'calleridNumber',
      },
      {
        Header: 'DATE',
        accessor: 'callDate',
      },
      {
        Header: 'TYPE',
        Cell: ({ row }) => {
          const { original } = row;
          switch (original.type) {
            case 'incoming-call':
              return <IncomingCallIcon />;

            case 'email':
              return <EmailIcon />;

            default:
              return null;
          }
        },
        accessor: 'type',
      },
      {
        Header: 'TRANSCRIPT',
        Cell: () => {
          return <TranscriptlIcon />;
        },
        accessor: 'transcript',
      },
      {
        Header: '',
        Cell: ({ data, row }) => {
          const { original } = row;
          return (
            <DropdownWrapper>
              <Dropdown.Toggle as={CustomToggle}>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {original.type === 'incoming-call' && (
                  <Dropdown.Item
                    href="#"
                    onClick={getCustomerLog(data[row.index])}
                  >
                    <i className="fa fa-phone fa-fw" /> Call
                  </Dropdown.Item>
                )}
                {original.type === 'email' && (
                  <Dropdown.Item
                    href="#"
                    onClick={getCustomerLog(data[row.index])}
                  >
                    <i className="fa fa-comment-dots fa-fw" /> Text
                  </Dropdown.Item>
                )}
                <Dropdown.Item href="#">
                  <i className="fa fa-trash fa-fw" /> Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </DropdownWrapper>
          );
        },
        accessor: 'action',
        disableSortBy: true,
      },
    ];
    return columns;
  }, [getCustomerLog]);
};
