import React from 'react';
import classnames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars';
import { useIsMobile } from 'components/hooks';
import { reviewsData } from 'data/pages/app/reviews';
import ReviewItem from './item';
import { ListWrapper } from 'templates/app/style';

const Reviews: React.VFC = ({ ...props }) => {
  const isMobile = useIsMobile();

  return (
    <ListWrapper
      {...props}
      className={classnames({ 'p-0': !isMobile })}
    >
      <div className="list__content">
        <div className={classnames('list__content-body', { 'h-100': isMobile })}>
          {isMobile ? (
            <Scrollbars universal autoHide>
              {reviewsData.map((data, index) => (
                <ReviewItem item={data} key={index} />
              ))}
            </Scrollbars>
          ) : (
            <>
              {reviewsData.map((data, index) => (
                <ReviewItem item={data} key={index} />
              ))}
            </>
          )}
        </div>
      </div>
    </ListWrapper>
  );
};

export default Reviews;
