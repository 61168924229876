import styled from 'styled-components/macro';

export const InteractionDetailWrapper = styled.div.attrs(props => ({
  className: `interaction-detail-wrapper ${props.className || ''}`,
}))`
  position: relative;
  background: #F3F7FA;
  height: 100%;
  padding: 24px 21px;

  .interaction-detail-item {
    &__date {
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.4px;
      text-transform: uppercase;
      text-align: center;
      color: #8A9DA4;
      margin-bottom: 20px;
    }

    &__hr {
      border-bottom: 1px solid rgba(0, 0, 0, 0.5);
      margin-top: 42px;
      margin-bottom: 44px;
    }

    &:last-child {
      .interaction-detail-item__hr {
        display: none;
      }
    }
  }

  .interaction-detail-summary {
    border-top: 1px solid rgba(0, 0, 0, 0.5);
    margin-top: 40px;
    padding-top: 40px;

    &__title {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.4px;
      color: #8A9DA4;
      margin-bottom: 18px;
    }

    &__body {
      background-color: #FFFFFF;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: justify;
      border-radius: 8px;
      color: #000000;
      padding: 13px 12px 12px 15px;
      margin-bottom: 10px;
    }

    &__feedback {
      font-weight: 500;
      font-size: 13px;
      line-height: 21px;
      color: #B5B5B5;
      cursor: pointer;
      text-align: center;
    }
  }
`;
